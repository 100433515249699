<template>
	<div id="home" :style="{backgroundColor:bgImgOfDesignPage? '' : bgColorOfDesignPage,backgroundImage:'url('+bgImgOfDesignPage+')'}">
		<!-- 顶部菜单 -->
		<div class="top-tabbar">
			<el-row>
				<el-col :span="4">
					<img class="mall-logo" v-if="mallLogo" :src="$utils.setImg(mallLogo)" />
				</el-col>
				<el-col :span="3">
					<div class="cate-tabbar flex-row" @mouseover="openGoodsCate" @mouseleave="closeGoodsCate">
						<i class="el-icon-menu"></i>
						<span class="cate-tab">商品分类</span>
						<goods-cate v-show="showGoodsCate" v-on:close-goods-cate="closeGoodsCate" v-on:select-cate="selectCategory"></goods-cate>
					</div>
				</el-col>
				<el-col :span="11" class="flex-wrap">
					<div v-for="(item,index) in goodsListTitle">
						<div class="title-text" @click="toGoodsList(item.goodsfrom,item.goodsids)">{{item.title}}</div>
					</div>
				</el-col>
				<el-col :span="6">
					<search @searchGoods="searchGoods" :showPlaceholder="false"></search>
					<!-- <el-input v-model="goodsSearchValue" @keyup.13.native="searchGoods">
						<el-button type="danger" slot="append" icon="el-icon-search" @click="searchGoods"></el-button>
					</el-input> -->
				</el-col>
			</el-row>
			<!-- 			<img class="mall-logo" v-if="mallLogo" :src="$utils.setImg(mallLogo)" />
			<div class="cate-tabbar flex-row" @mouseover="openGoodsCate" @mouseleave="closeGoodsCate">
				<i class="el-icon-menu"></i>
				<span class="cate-tab">商品分类</span>
				<goods-cate v-show="showGoodsCate" v-on:close-goods-cate="closeGoodsCate"
					v-on:select-cate="selectCategory"></goods-cate>
			</div>
			<div style="width: 900px;" class="flex-wrap">
				<div v-for="(item,index) in goodsListTitle" >
					<div class="title-text" @click="toGoodsList(item.goodsfrom,item.goodsids)">{{item.title}}</div>
				</div>
			</div>
			<div class="search-wrap">
				<el-input v-model="goodsSearchValue" @keyup.13.native="searchGoods">
					<el-button type="danger" slot="append" icon="el-icon-search" @click="searchGoods"></el-button>
				</el-input>
			</div> -->
		</div>

		<!-- 内容主体 -->
		<div class="home-container">

			<!-- 自定义样式 轮播图 -->
			<div id="styleList">
				<div v-for="(item,index) in styleList">
					<!-- 轮播图 -->
					<div v-if="item.type==1 && item.carouselInfoDtoList && item.carouselInfoDtoList.length == 1" class="carousel-img-wrap" :data-index="index" :data-subindex="0" @click="toCarouselLink" :interval="5000">
						<img class="carousel-img single" :src="$utils.setImg(item.carouselInfoDtoList[0].picUrl)" :data-index="0" />
					</div>
					<el-carousel v-if="item.type==1 && item.carouselInfoDtoList && item.carouselInfoDtoList.length>1" v-show="carouselImgHeight>0" :height="carouselImgHeight + 'px'" arrow="hover" :interval="5000">
						<el-carousel-item v-for="(item1,index1) in item.carouselInfoDtoList">
							<div class="carousel-img-wrap" :data-index="index" :data-subindex="index1" @click="toCarouselLink">
								<!-- <el-image class="carousel-img" :src="$utils.setImg(item1.picUrl)" fit="contain"></el-image> -->
								<img class="carousel-img" :src="$utils.setImg(item1.picUrl)" :data-index="index" @load="carouselImgLoad" />
								<div class="matte" />

							</div>
						</el-carousel-item>
					</el-carousel>

					<!-- 热区广告 -->
					<div v-if="item.type==2 && item.picUrl" :data-index="index" @click="toZoneLink">
						<img class="carousel-img single" :src="$utils.setImg(item.picUrl)" :data-index="0" />
					</div>
					<!-- 广告通知 -->
					<div v-if="item.type==3 && item.picUrl" :data-index="index">
						<img class="carousel-img single" :src="$utils.setImg(item.picUrl)" />
					</div>
					<!-- 商品列表 -->
					<div v-if="item.type == 4" :data-index="index">
						<div class="container" v-if="item.goodsList && item.goodsList.length > 0" :style="{backgroundColor: !item.bgImg  ? item.bgcolor : '', backgroundImage: item.bgImg ? `url(${item.bgImg})` : ''}">
							<div class="list-title-wrap">
								<span class="goods-list-title" :style="{color: item.titlecolor}">{{item.title}}</span>
								<div class="more-btn" @click="toGoodsList(item.goodsfrom,item.goodsIds)">更多</div>
							</div>
							<div><img style="width: 100%;" :src="item.picUrl"></div>
							<div class="goods-list flex-row">
								<div class="goods-item flex-col" v-for="(gitem,gindex) in item.goodsList" :data-goodsid="gitem.goodsId" @click="toGoodsDetail(gitem)">
									<div class="goods-img-wrap">
										<img class="goods-img" :src="$utils.set400Img(gitem.coverPic)" />
									</div>
									<div class="goods-name text-ellipsis" :title="gitem.goodsName" v-if="showGoodsName">
										<span class="presale-style" v-if="gitem.presaleId">预售</span><span class="presale-style activity-labele" v-if="gitem.activityType == '抢购'">抢购</span>{{gitem.goodsName}}
									</div>
									<div class="goods-name text-ellipsis" :title="gitem.goodsNo" v-else><span class="presale-style" v-if="gitem.presaleId">预售</span><span class="presale-style activity-label" v-if="gitem.activityType == '抢购'">抢购</span>{{gitem.goodsNo}}
									</div>
									<div class="goods-sub-title-home" v-show="!!gitem.stockTxt && !gitem.presaleId" style="margin-top: 3px;">
										{{gitem.stockTxt}}
									</div>
									<div class="goods-sub-title-home" v-show="goodsValidityDateData[gitem.goodsId]" style="margin-top: 3px;">
										{{goodsValidityDateData[gitem.goodsId] || ""}}
									</div>

									<img class='sold-out-img' src="../../assets/images/ico-soldout.svg" v-show="isShowOverStockTag && gitem.stock<=0&& !gitem.presaleId" />

									<div class="goods-price flex-row" v-if="isShowPrice">
										<span v-show="gitem.priceMember || !gitem.score || gitem.score == 0">
											<span v-show="gitem.priceMember || !gitem.score || gitem.score == 0">{{$currency.symbol}}{{$utils.filterTrailingZeros(gitem.priceMember)}}</span>
											<span v-show="gitem.priceMember && gitem.priceMember!=0 && gitem.score > 0" class="goods-score">+</span>
											<span v-show="gitem.score>0" class="goods-score">{{gitem.score}}积分</span>
											<span class='goods-price-detail' v-if="showPriceDetail&&gitem.priceDetail&&parseFloat(gitem.priceDetail)>parseFloat(gitem.priceMember)">{{$currency.symbol}}{{ $utils.filterTrailingZeros(gitem.priceDetail)}}</span>
										</span>

									</div>
									<div class="label-content">
										<span class="button-border" v-for="item in gitem.labelNameList">
											{{item}}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 抢购模块 -->
					<div v-if="item.type==5">
						<div class="container">
							<timed-list :key="timeListkey" :isFromIndex='true'></timed-list>
						</div>
					</div>
					<!-- 商品分类 -->
					<div v-if="item.type==6" :data-index="index">
						<!-- <div class="category-title">商品分类</div> -->
						<!-- <div><img style="width: 100%;" :src="item.picUrl"></div> -->
						<div class="  category-content  goods-list flex-row" :style="{backgroundColor: !item.bgImg ? item.bgcolor==''?'#ffffff':item.bgcolor : ' ', backgroundImage: item.bgImg ? `url(${item.bgImg})` : ''}">
							<div class="category-content_item" v-for="(gitem,gindex) in item.categoryRenderList" @click="selectCategory(gitem)">
								<div class="content-img goods-img-wrap">
									<img class="goods-img cate-img" :src="$utils.set400Img(gitem.picUrl)" />
								</div>

								<div class="category-text">
									{{gitem.categoryName}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<!-- 右下角浮动按钮 -->
		<float-btns></float-btns>
	</div>
</template>

<script>
	import GoodsCate from '../../components/goods/GoodsCate.vue';
	import FloatBtns from '../../components/common/FloatBtns.vue';
	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import validityDateUtils from '../../utils/api-utils/api-goods-validitydate.js'
	import timedList from '../../components/goods/TimedGoodsList.vue';
	import Search from '../../components/common/Search.vue';
	import {
		getParam,
		getParamByKeysFromCache
	} from '../../utils/api-utils/api-params.js';
	import {
		getCustomerInfo
	} from '../../utils/api-utils/api-customer.js';
	import {
		getSingleDesignStyle,
	} from '../../utils/api-utils/api-style.js';

	export default {
		name: 'home',
		components: {
			'float-btns': FloatBtns,
			'goods-cate': GoodsCate,
			'timed-list': timedList,
			'search': Search
		},
		data() {
			return {
				styleList: [], //自定义样式列表
				mallTitle: "", //商城名
				mallLogo: "", //商城Logo
				showGoodsCate: false, //是否显示商品分类
				hotGoodsList: [], //热销商品列表
				newGoodsList: [], //新品商品列表
				goodsSearchValue: "", //商品搜索内容
				styleLoaded: false, //轮播图加载完成
				copyright: "", // 版权
				ICPNNumber: "", // 备案号
				cybersecuritycNumber: "", //网安备案号
				isShowCybersecuritycNumber: true, // 是否显示公安备案
				customerId: 0,
				goodsValidityDateData: {}, // 保质期
				showPriceDetail: false, // 是否展示零售价
				showTimedSale: false,
				timeListkey: false,
				isOpenIndistinctStock: false,
				isOpenZeroStockBuy: false, // 0库存
				showGoodsName: !['795330'].includes(localStorage.getItem("JackyunUserName")),
				goodsListTitle: [],
				categoryList: [],
				categoryRenderList: [],
				isShowOverStockTag: true,
				isShowPrice: false,
				carouselImgHeight: 0,
				pcMallBodyContent: "",
				bgColorOfDesignPage: "",
				bgImgOfDesignPage: "",
			}
		},
		created: async function() {
			this.getMallTitleAndLoadParam(); //获取商城名称Logo
			await this.getCategoryList() // 获取分类
			this.loadStyle(); //获取自定义样式从
		},
		async mounted() {
			this.$eventBus.$on('setCustomerId', msg => {
				this.customerId = msg;
			})

			this.isShowPrice = await this.$utils.getIsShowPrice();
		},
		activated() {
			this.timeListkey = !this.timeListkey;
		},
		watch: {
			async customerId(newName, oldName) {
				let that = this;
				if (!!newName && newName != 0) {
					that.loadStyle();
					this.isShowPrice = await this.$utils.getIsShowPrice();
				}
			}
		},
		methods: {
			//获取商城名称及Logo
			async getMallTitleAndLoadParam() {
				let paramlist = await getParam("pcmall_title,pcmall_logo,isOpenShowDetailPrice,domainFiling,isOpenIndistinctStock,isOpenZeroStockBuy,isShowGoodsStock,isShowOverStockTag,isShowStockOuts");
				this.mallTitle = paramlist.pcmall_title || "JMALL商城";
				this.mallLogo = paramlist.pcmall_logo;
				this.showPriceDetail = paramlist.isOpenShowDetailPrice == "1";
				// this.showTimedSale = param.pcmall_showTimed == "1";

				this.isOpenIndistinctStock = paramlist.isOpenIndistinctStock == "1";
				this.isOpenZeroStockBuy = paramlist.isOpenZeroStockBuy == "1";
				this.isShowOverStockTag = paramlist.isShowOverStockTag != "0"; // 是否展示缺货标签
				this.isShowGoodsStock = paramlist.isShowGoodsStock == "1"; // 是否展示库存
				this.isShowStockOuts = paramlist.isShowStockOuts != "0"; // 是否展示缺货库存
			},
			//加载自定义首页样式
			async loadStyle(pageId) {
				let titleList = [];
				// 自定义页面id
				let designPageId = pageId || window.decodeURIComponent(window.atob(this.$route.query.designPageId));

				// 获取新装修数据
				let designPageInfo = await getSingleDesignStyle([202], [designPageId])
				let designContent = JSON.parse(designPageInfo.designContent || '{}')

				// 自定义页面被删除直接返回
				if (!designPageInfo.designContent) {
					return this.$router.go(-1);
				}

				// 装修数据
				let styleList = designContent.moduleStyleList;
				// 背景颜色 背景图片
				this.bgColorOfDesignPage = designContent.globalStyle.bgColor;
				this.bgImgOfDesignPage = designContent.globalStyle.bgImg;

				for (var i = 0; i < styleList.length; i++) {
					let item = styleList[i];
					if (item.type == 4) {
						let content = JSON.parse(item.content);
						item.titlecolor = content.titlecolor;
						item.goodscount = content.goodscount;
						item.goodsfrom = content.goodsfrom;
						item.goodsIds = content.goodsids == "0" ? "" : content.goodsids;
						item.goodsList = await goodsUtils.listGoods(content.goodsfrom, content.goodscount, content.goodsids, 1);
						item.bgcolor = content.bgcolor
						item.goodsList = await this.attchOtherInfo(item.goodsList);
						// this.getGoodsList(content.goodsfrom, content.goodscount,
						// 	content.goodsids, i);
						if (!!item.title) {
							titleList.push({
								title: item.title,
								goodsfrom: item.goodsfrom,
								goodsids: item.goodsIds
							});
						}
					}
					if (item.type == 1) {
						item.carouselInfoDtoList = item.carousel_list.map(carouselItem => {
							carouselItem.picUrl = carouselItem.pic_url;
							return carouselItem
						});
					}
					if (item.type == 6) {
						let tempContent = JSON.parse(styleList[i].content);

						// 是否展示全部分类
						let isShowAllCateAndFirstCateIsAll = tempContent.isshowallcate != 0 && this.categoryList[0].categoryName == "全部分类";
						let startIndex = isShowAllCateAndFirstCateIsAll ? 0 : 1;
						let endIndex = isShowAllCateAndFirstCateIsAll ? tempContent.catecount : tempContent.catecount / 1 + 1;

						styleList[i].bgcolor = tempContent.bgcolor
						styleList[i].categoryRenderList = this.categoryList.slice(startIndex, endIndex);
					}
					if (item.type == 3 || item.type == 2) {
						styleList[i].picUrl = styleList[i].pic_url;
					}
				}

				this.styleList = styleList;
				this.goodsListTitle = titleList;
			},
			//获取商品分类列表
			async getCategoryList() {
				let cateList = await this.getCategoryListFromOrigin();
				if (cateList[0].categoryName == "全部分类") {
					this.allCateImg = cateList[0].picUrl;
					cateList = cateList.splice(1, cateList.length - 1);
				}
				cateList.forEach(item => {
					if (item.categoryList && item.categoryList.length > 0) {
						item.isThreeCate = !!item.categoryList.find(f => f.categoryList.length >
							0);
					}
				})

				this.categoryList = cateList;
			},

			// 获取分类数据
			async getCategoryListFromOrigin() {
				// 获取缓存中信息
				let customerInfo = await getCustomerInfo();
				let viplevel = customerInfo && customerInfo.vipLevel ? customerInfo.vipLevel : "";
				return new Promise((resolve, reject) => {
					this.$request.jackyunPost({
						method: "jmall.goods.getgoodscategorylistv2",
						memberGrade: viplevel
						// channelId:localStorage.getItem("pc_channelId")
					}, (res) => {
						let result = [];
						if (res.code == 200) {
							result = res.result.data;
						}
						resolve(result)
					})
				});
			},

			// 添加其他数据
			async attchOtherInfo(goodslist) {
				let newGoodsList = []
				if (goodslist.length > 0) {
					let goodsIds = goodslist.map(item => {
						return item.goodsId
					});
					// 获取保质期
					this._getvaliditydate(goodsIds);

					// 补充完整的货品信息（价格、库存、营销活动信息等）
					newGoodsList = await goodsUtils.completeFullGoodsInfoForList(goodslist, "goodsList");
				}
				return newGoodsList
			},

			// 获取保质期
			async _getvaliditydate(goodsIds) {
				let that = this;
				//门店信息
				let validateList = await validityDateUtils.getValidityDateByMultiCondition(goodsIds);

				// 已有的保质期库存
				let goodsValidityDateData = that.goodsValidityDateData;
				for (let i = 0; i < validateList.length; i++) {
					let item = validateList[i]
					item.validityDate = await this.$utils.getFormatValidate(item, false);
					goodsValidityDateData[item.goodsId] = goodsValidityDateData[item.goodsId] || (!!item.validityDate ? item.validityDate : "");
				}

				that.goodsValidityDateData = goodsValidityDateData;
			},

			//搜索商品
			searchGoods(searchValue) {
				this.searchValue = searchValue
				this.$router.push({
					name: 'goodslist',
					params: {
						search_value: this.searchValue
					},
				});
			},
			//跳转商品列表
			toGoodsList(goodsfrom, goodsids) {
				if (goodsfrom == "2" || goodsfrom == "1") {
					this.$router.push({
						name: 'goodslist',
						params: {
							order_by: goodsfrom == "2" ? "sellCountTotal" : "createTime",
						},
					});
					return;
				}
				if (goodsfrom == "6") {
					this.$router.push({
						name: 'goodslist',
						params: {
							category_id: goodsids,
						}
					});
					return;
				}
				this.$router.push({
					name: 'goodslist',
					params: {
						goodsfrom: goodsfrom,
						goodsids: goodsids
					},
				});
			},
			//打开商品分类
			openGoodsCate() {
				this.showGoodsCate = true;
			},
			//关闭商品分类
			closeGoodsCate() {
				this.showGoodsCate = false;
			},
			//选择商品分类
			selectCategory(res) {
				this.showGoodsCate = false;
				var cateId = res.cateId || res.categoryId || '';
				var pCateId = res.pCateId || res.categoryId || '';
				var cateName = res.cateName || res.categoryName;
				this.$router.push({
					name: 'goodslist',
					params: {
						category_id: cateId + '',
						pcategory_id: pCateId + '',
						category_name: cateName + '',
					}
				});
			},
			//调整轮播图的高度
			carouselImgLoad(e) {
				var index = parseInt(e.currentTarget.dataset.index);
				var styleList = this.styleList;
				var wrapWidth = document.getElementById("styleList").offsetWidth; //轮播图外容器的宽度
				var imgwidth = e.currentTarget.naturalWidth, //图片原始宽度
					imgheight = e.currentTarget.naturalHeight, //图片原始高度
					ratio = imgwidth / imgheight; //宽高比  
				//计算当前的高度值  
				var viewHeight = wrapWidth / ratio;
				viewHeight = viewHeight > imgheight ? imgheight : viewHeight; //最高高度不能超过原图的高度
				// if (styleList[index].height) {
				// 	//取最高的图片高度
				// 	styleList[index].height = viewHeight > styleList[index].height ? viewHeight : styleList[index].height;
				// } else {
				// 	styleList[index].height = viewHeight;
				// }
				this.carouselImgHeight = viewHeight > this.carouselImgHeight ? viewHeight : this.carouselImgHeight;
			},
			//轮播图点击跳转
			toCarouselLink(e) {
				let index = parseInt(e.currentTarget.dataset.index);
				let subIndex = parseInt(e.currentTarget.dataset.subindex);
				let carousel = this.styleList[index].carouselInfoDtoList[subIndex];
				//判断链接类型，进行跳转
				if (carousel.type == "1" && carousel.goodsId) {
					//商品链接
					let goodsId = carousel.goodsId;
					let encode = window.btoa(window.encodeURIComponent(goodsId));
					this.$router.push({
						name: 'goodsdetail',
						query: {
							id: encode
						}
					});
				} else if (carousel.type == "0" && carousel.content.indexOf('https') >= 0) {
					//自定义网页链接
					window.open(carousel.content);
				} else if (carousel.type == "2") {
					//链接为分类
					this.$router.push({
						name: 'goodslist',
						params: {
							category_id: carousel.content,
							category_name: linkname,
						}
					});
				} else if (carousel.type == "3") {
					//链接为品牌

				} else if (carousel.type == "4") {
					//链接为分组
					this.$router.push({
						name: 'goodslist',
						params: {
							groupId: carousel.content,
						}
					});

				} else if (carousel.type == "5") {
					// 链接为自定义页面
					this.goToCustomPageById(carousel.content)
				} else if (carousel.type == "6") {
					// 链接为限时抢购
					this.$router.push({
						name: 'timedsalegoodslist',
					});
				}
			},
			//热区广告点击跳转
			async toZoneLink(e) {
				let index = parseInt(e.currentTarget.dataset.index);
				var content = this.styleList[index].content;
				if (content) {
					//获取点击点相对页面坐标
					var x = e.layerX;
					var y = e.layerY;
					//获取图片的宽高及位置
					//获取图片宽高
					var imgWidth = e.target.clientWidth;
					var imgHeight = e.target.clientHeight;
					var imgLeft = e.target.offsetLeft;
					var imgTop = e.target.offsetTop;
					x = x - imgLeft; // 获取相对图片的X坐标
					y = y - imgTop; // 获取相对图片的Y坐标

					var zoneList = JSON.parse(content).reverse(); //重复区域，已后添加的为主
					var type = "0";
					var linkurl = "";
					var linkname = "";
					for (var i = 0; i < zoneList.length; i++) {
						var zone = zoneList[i];
						//循环判断点击点的位置是否在区域内
						if (x > parseFloat(zone.left) * imgWidth && y > parseFloat(zone.top) * imgHeight &&
							x < (parseFloat(zone.left) + parseFloat(zone.width)) * imgWidth &&
							y < (parseFloat(zone.top) + parseFloat(zone.height)) * imgHeight) {
							type = zone.type;
							linkurl = zone.link;
							linkname = zone.linkname;
							break;
						}
					}

					if (type == "0" && linkurl.indexOf("https://") >= 0) {
						//链接为自定义链接
						window.open(linkurl);
					} else if (type == "1") {
						//链接为商品
						let encode = window.btoa(window.encodeURIComponent(linkurl));

						// 判断是否是限时抢购商品
						let actInfolist = await goodsUtils.getGoodsExtraInfo(linkurl.split(','), "1");
						// 如果存在活动信息前往限时抢购商品详情
						if (!!actInfolist.find(item => (item.activityType || '').includes('抢购'))) {
							this.$router.push({
								name: 'timedsalegoodsdetail',
								query: {
									saleId: actInfolist[0].activityId,
									goodsId: linkurl
								}
							});
							return
						}
						this.$router.push({
							name: 'goodsdetail',
							query: {
								id: encode
							}
						});
					} else if (type == "2") {
						//链接为分类
						this.$router.push({
							name: 'goodslist',
							params: {
								category_id: linkurl,
								category_name: linkname,
							}
						});
					} else if (type == "3") {
						//链接为品牌

					} else if (type == "4") {
						//链接为分组
						this.$router.push({
							name: 'goodslist',
							params: {
								groupId: linkurl,
							}
						});

					} else if (type == "5") {
						// 链接为自定义页面
						this.goToCustomPageById(linkurl)
					} else if (type == "6") {
						// 链接为限时抢购
						this.$router.push({
							name: 'timedsalegoodslist',
						});
					}
				}
			},
			//跳转商品详情
			toGoodsDetail(good) {
				let goodsId = good.goodsId;
				// 跳转抢购
				if (good.activityType == "抢购") {
					this.$router.push({
						name: 'timedsalegoodsdetail',
						query: {
							saleId: good.activityId,
							goodsId: good.goodsId
						}
					})
					return
				}
				let encode = window.btoa(window.encodeURIComponent(goodsId));
				this.$router.push({
					name: 'goodsdetail',
					query: {
						id: encode
					}
				});
			},
			/**
			 * 前往自定义页面
			 */
			async goToCustomPageById(pageId) {
				// 判断是否被删除
				let designPageInfo = await getSingleDesignStyle([202], [pageId]);
			
				// 自定义页面被删除直接返回
				if (!designPageInfo.designContent) {
					return;
				}
				
				// 链接为自定义页面
				this.$router.push({
					name: 'designpage',
					query: {
						designPageId: window.btoa(window.encodeURIComponent(pageId))
					}
				});
				this.loadStyle(pageId)
				
			},

		},
	}
</script>

<style scoped="scoped">
	#home {
		background-repeat: no-repeat;
		background-position: top center;
		background-size: 100%;
	}

	.top-tabbar {
		line-height: 70px;
		padding: 0 10%;
		align-items: center;
		position: relative;
	}

	.home-container {
		min-height: calc(100vh - 200px);
	}

	.mall-logo {
		height: 50px;
		margin-right: 30px;
		transform: translateY(13px);
	}

	.cate-tabbar {
		position: relative;
		margin: 0 30px;
		align-items: center;
		height: 70px;
	}

	.cate-tabbar .el-icon-menu {
		font-size: 22px;
		margin-right: 5px;
	}

	.cate-tab {
		display: block;
		cursor: pointer;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		min-width: 120px;
	}

	.goods-cate {
		left: -6px;
	}

	.top-tabbar>div {
		margin: 0 30px;
		cursor: pointer;
	}

	.search-wrap {
		position: absolute;
		right: 0;
		height: 50px;
		display: flex;
		align-items: center;
		right: 10%;
		width: 350px;
	}

	.search-wrap .el-input-group__append {
		background-color: #f56c6b;
		border-color: #f56c6b;
		color: #fff;
		font-size: 20px;
	}

	.el-carousel__container {
		height: 300px;
		text-align: center;
	}

	.carousel-img-wrap {
		width: 100%;
		height: 100%;
		cursor: pointer;
		text-align: center;
		/*display: flex;
		align-items: center;
		justify-content: center; */
	}

	.carousel-img {
		max-width: 100%;
		max-height: 100%;
		cursor: pointer;
	}

	.carousel-img.single {
		display: block;
		margin: 0 auto;
	}

	.list-title-wrap {
		max-width: 1200px;
		text-align: center;
		position: relative;
		height: 50px;
		margin: 0 auto;
	}

	.goods-list-title {
		font-weight: bold;
		line-height: 50px;
		font-size: 25px;
		letter-spacing: 5px;
	}

	.more-btn {
		position: absolute;
		right: 10px;
		top: 4px;
		border: solid 1px #333;
		padding: 10px 30px;
		cursor: pointer;
	}

	.goods-list {
		flex-wrap: wrap;
		padding: 10px 0;
		max-width: 1200px;
		margin: 0 auto;
	}

	.goods-item {
		width: calc(20% - 20px);
		padding: 30px 0;
		margin: 10px;
		background-color: #FFFFFF;
		text-align: center;
		align-items: center;
		cursor: pointer;
		position: relative;
	}

	.activity-labele {
		background: linear-gradient(92deg, #ECD6AF 0%, #F6E5C6 100%);
	}

	.presale-style {
		color: #FFFFFF;
		padding: 2px 8px;
		border-radius: 4px;
		margin-right: 4px;
		font-size: 12px;
		vertical-align: text-top;
	}

	@media screen and (max-width: 1000px) {
		.top-tabbar {
			padding: 0;
		}

		.search-wrap {
			width: 300px;
			right: 0;
		}

		.goods-item {
			width: calc(33.33% - 20px);
			position: relative;
		}
	}

	.goods-img-wrap {
		max-width: 200px;
		height: 200px;
		display: flex;
		align-items: center;
	}

	.goods-img {
		max-width: 100%;
		max-height: 100%;
		margin: 0 auto;
	}

	.goods-name {
		margin: 10px 0;
		width: calc(100% - 40px);
		padding: 0 20px;
		line-height: 20px;
		text-align: left;
		height: 38px
	}

	.goods-price {
		height: 25px;
		font-size: 19px;
		color: red;
		font-weight: bold;
	}

	.footer-txt {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		color: #333;
		font-size: 13px;
	}

	.button-border {
		border-radius: 3px;
		color: #f63;
		font-size: 14px;
		border: 1px solid #f63;
		height: 20px !important;
		margin-left: 10px;
		word-break: break-all;
		overflow: hidden;
		text-align: center;
		margin-top: 2px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.goods-stock {
		color: #999;
		font-size: 14px;
		font-weight: normal;
		text-align: right;
		width: 90px;
		line-height: 22px;
		height: 22px;
		overflow: hidden;
		word-break: break-all;
		padding-right: 10px;
	}

	.goods-sub-title-home {
		color: #999;
		font-size: 14px;
		width: 230px !important;
		white-space: pre;
		text-overflow: ellipsis;
		overflow: hidden;
		line-height: 22px;
		height: 22px;
		padding: 0 20px;
		box-sizing: border-box;
		align-self: self-start;
		text-align: left;
	}

	.sold-out-img {
		width: 60px;
		height: 60px;
		position: absolute;
		top: 221px;
		right: 25px;
		z-index: 9;
	}

	.width-240 {
		width: calc(20% - 20px);
	}

	.goods-price-detail {
		color: #999;
		font-weight: normal;
		margin-left: 5px;
		font-size: 15px;
		text-decoration: line-through;
	}

	/* .matte {
		width: 100%;
		height: 32px;
		background-color: #000;
		position: absolute;
		bottom: 0vh;
		opacity: 0.5;
	} */


	/deep/ .el-carousel__indicator--horizontal button {
		width: 40px;
		height: 6px;
		background: #333333;
		border: 1px solid #FFFFFF;
		border-radius: 10px;
		opacity: 0.5;
	}

	/deep/ .el-carousel__indicator--horizontal.is-active button {
		width: 40px;
		height: 6px;
		border: 1px solid #333333;
		background: #ffffff;
		opacity: 0.5;
		border-radius: 10px;
	}

	.title-text {
		padding: 0 20px;
	}

	.category-title {
		text-align: center;
	}

	.category-content {
		/* width: 100%; */
		box-sizing: border-box;
		margin-bottom: 20px;
		margin-top: 20px;
		background-size: 100%;
		flex-wrap: wrap;
		background-size: 100%;
	}

	.category-content_item {
		cursor: pointer;
		box-sizing: border-box;
		flex-grow: 0;
		flex-shrink: 0;
		width: calc(16% - 16px);
		margin: 12px;
		padding-bottom: 20px;
	}

	.content-img {
		text-align: center;
		height: unset !important;
		/* width: 300px; */
	}

	.cate-img {
		width: 140px;
		height: 140px;
	}

	.category-text {
		margin-top: 10px;
		/* width: 300px; */
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		/*设置对其模式 */
		-webkit-line-clamp: 2;
	}

	.label-content {
		display: flex;
		flex-wrap: wrap;
		margin-top: 4px;
	}

	.button-border {
		border-radius: 3px;
		color: #f63;
		font-size: 14px;
		border: 1px solid #f63;
		height: 20px !important;
		margin-left: 10px;
		word-break: break-all;
		overflow: hidden;
		text-align: center;
		margin-top: 2px;
		padding-left: 5px;
		padding-right: 5px;
	}
</style>