import requestUtil from '../request-utils.js'

/**
 *  获取装修列表 新 designIdList传指定id 获取单个指定自定义页面装修数据
 */
const getSingleDesignStyle = (designTypeList = [201], designIdList) => {
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.style.getdesignlist",
			jsonStr: JSON.stringify({
				designTypeList,
				designIdList
			})
		}, (res) => {
			let data = {};
			if (res.code == 200) {
				data = res.result.data && res.result.data[0] || {};
			}
			resolve(data);
		});
	});
}

/**
 *  获取装修列表 旧
 */
const getCustomerStyleListOld = () => {
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.style.getpcmallstyle"
		}, (res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data || [];
			}
			resolve(data);
		});
	});
}

//可以被外部引用的方法
export {
	getSingleDesignStyle,
	getCustomerStyleListOld
}