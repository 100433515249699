const request = require('../request-utils.js');
import commonUtils from '../common-utlis.js'
import {
	getChannelInfo
} from './api-channel.js';
import {
	isOpenConfig
} from './api-params.js';
import {
	callFeignV1
} from './api-callFeign.js';

/**
 * 获取货品价格列表
 * @param {*} goodsIdArr 
 * @param {*} isRefresh 
 * 
 */
const getGoodsPriceList = async (goodsIdArr) => {
	// 调用接口获取最新的货品价格
	let originPriceList = await _getPriceFromOrigin(goodsIdArr.join(','));

	// 返回最终价格列表
	return originPriceList;
}

/**
 * 获取货品最低价格列表
 * @param {*} goodsIdArr 
 * @param {*} isRefresh 
 */
const getGoodsMinPriceList = async (goodsIdArr) => {
	// 查询商品中不含普通商品直接返回
	if (goodsIdArr.length == 0) {
		return [];
	}
	return new Promise((resolve, reject) => {
		let customerId = localStorage.getItem("pc_customerId") || "";
		let channelId = localStorage.getItem("pc_channelId");
		let customerInfoStr = localStorage.getItem("pc_customerInfo");
		let customerInfo = JSON.parse(customerInfoStr);
		let json = {
			method: "jmall.goods.listmingoodsprice",
			goodsIds: goodsIdArr.join(','),
			customerId: customerId,
			channelId: channelId,
		}
		if (customerInfo && customerInfo.customerId) {
			json.vipLevelId = customerInfo.vipLevel ? customerInfo.vipLevel : "empty";
		}
		request.default.jackyunPost(
			json, (res) => {
				if (res.code == 200) {
					let minPriceList = res.result.data.map(t => {
						// 未登录时展示游客展示价
						if (!customerId) {
							t.minPrice = t.minPriceDetail;
						}
						return {
							goods_id: t.goodsId,
							price_member: t.minPrice,
							price_detail: t.lineShowPrice,
							score: t.score
						}
					});
					// 返回最终价格列表
					resolve(minPriceList);
					return;
				} else {
					resolve([]);
				}

			})

	})
}

/**
 * 获取货品最低价格列表(套餐商品)
 * @param {*} goodsIdArr 
 * @param {*} isRefresh 
 */
const getGoodsMinPriceListForPackage = async (goodsIdArr) => {
	// 查询商品中不含套餐商品直接返回
	if (goodsIdArr.length == 0) {
		return [];
	}
	return new Promise((resolve, reject) => {
		let customerId = localStorage.getItem("pc_customerId") || "";
		let channelId = localStorage.getItem("pc_channelId");
		let customerInfoStr = localStorage.getItem("pc_customerInfo");
		let customerInfo = JSON.parse(customerInfoStr);
		let json = {
			goodsIds: goodsIdArr.join(','),
			customerId: customerId,
			channelId: channelId,
			method: "jmall.goods.listmingoodspriceforpackage"
		}
		if (customerInfo && customerInfo.customerId) {
			json.vipLevelId = customerInfo.vipLevel ? customerInfo.vipLevel : "empty";
		}
		request.default.jackyunPost(
			json, (res) => {
				if (res.code == 200) {
					let minPriceList = res.result.data.map(t => {
						// 未登录时展示游客展示价
						if (!customerId) {
							t.minPrice = t.minPriceDetail;
						}
						return {
							goods_id: t.goodsId,
							price_member: t.minPrice,
							price_detail: t.lineShowPrice,
							score: t.score
						}
					});
					// 返回最终价格列表
					resolve(minPriceList);
					return;
				} else {
					resolve([]);
				}

			})

	})
}

/**
 * 获取SKU价格列表（弃用）
 * @param {*} skuArr 
 */
const getSkuPriceList = async (skuArr) => {
	// 入参格式要求：[{goods_id,sku_id,unit_name},{goods_id,sku_id,unit_name}...]
	// 获取商品ID集合（需要去重）
	let goodsIdList = [...new Set(skuArr.map(t => t.goods_id))];
	// 获取货品ID对应的所有货品价格明细
	let goodsPriceList = await getGoodsPriceList(goodsIdList);

	// 补充价格数据
	skuArr.forEach(sku => {
		try {
			// 获取出当前sku对应的价格数据，补充到返回结果
			let skuPrice = goodsPriceList.find(t => t.goods_id == sku.goods_id).sku_list.find(t => t
				.sku_id == sku.sku_id && t.unit_name == sku.unit_name);
			sku.price_member = commonUtils.floatKeepDigit(skuPrice.price_member || 0);
			sku.price_detail = commonUtils.floatKeepDigit(skuPrice.price_detail || 0);
			sku.score = skuPrice.score;
		} catch (e) {
			// 异常情况都置为0
			sku.price_member = sku.price_detail = sku.score = 0;
		}
	});

	// 返回最终价格列表
	return skuArr;
}

/**
 * 调用接口获取货品价格
 * @param {*} goodsIds 
 */
const _getPriceFromOrigin = (goodsIds) => {
	let customerId = localStorage.getItem("pc_customerId");
	let channelId = localStorage.getItem("pc_channelId");
	return new Promise((resolve, reject) => {
		// 缺少参数的情况不请求接口
		if (!channelId || !customerId || !goodsIds) {
			return resolve([]);
		}
		// 调用接口获取最新的货品价格
		request.default.jackyunPost({
			method: "jmall.goods.listgoodsprice",
			channelId: channelId,
			customerId: customerId,
			goodsIds: goodsIds
		}, (res) => {
			if (res.code != 200) {
				return resolve([]);
			}
			let goodsPriceList = res.result.data;
			// 统一处理价格数据
			let goodsPriceListFitApp = goodsPriceList.map(item => {
				return {
					goods_id: item.goodsId,
					sku_list: item.skuList.map(m => {
						return {
							sku_id: m.skuId,
							unit_name: m.unitName,
							price_member: m.priceMember,
							price_detail: m.priceDetail,
							score: m.score
						}
					})
				}
			})
			resolve(goodsPriceListFitApp)
		})
	});
}

/**
 * 获取sku价格列表中价格最低的数据
 * @param {*} skuPriceList 
 */
const _getMinSkuPriceInfo = (skuPriceList) => {
	// 首先将没有设置价格/积分的数据过滤掉
	let filterPriceList = skuPriceList.filter(t => t.price_member > 0 || t.price_detail || t.score > 0);
	// 如果没有有效价格，直接返回默认结果
	if (!filterPriceList || filterPriceList.length <= 0) {
		return {
			price_detail: 0,
			price_member: 0,
			score: 0
		};
	}

	// 根据价格排序
	filterPriceList.sort((a, b) => {
		if (a.price_member == b.price_member) {
			return a.score - b.score;
		} else {
			return a.price_member - b.price_member;
		}
	});

	return filterPriceList[0];
}

/**
 * 获取sku价格列表中价格最低的数据
 * @param {*} goodsList 
 */
const getMinPriceFromSpec = (goodsList) => {
	// 获取最小价格
	let minPriceList = goodsList.map(t => {
		// 获取价格最小的SKU数据
		let minSkuPrice = _getMinSkuPriceInfo(t.GoodsSpecList);
		return {
			goods_id: t.goods_id,
			price_member: minSkuPrice.price_member,
			price_detail: minSkuPrice.price_detail,
			score: minSkuPrice.score
		}
	});
	// 返回最终价格列表
	return minPriceList;
}

/**
 * 获取简略商品列表
 * @param {*} goodsIdList 
 */
const getSimpleGoodsList = (goodsIdList) => {
	return new Promise(async (resolve, reject) => {
		// 调用接口获取最新的货品价格
		request.default.jackyunPost({
			method: "jmall.goods.getsimplegoodslist",
			goodsIds: goodsIdList.join(",")
		}, (res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data || [];
			} else {
				console.log("获取简略商品列表失败：", res);
			}
			resolve(data);
		});
	});
}

/**
 * CRM促销策略匹配（http://192.168.88.47/web/#/14/22307）
 * @param {*} goodsList 
 */
const matchCrmPromotionPloy = (goodsList) => {
	return new Promise(async (resolve, reject) => {
		// 检测是否开启允许匹配crm促销策略，未开启则无需匹配
		if (!await isOpenConfig("isOpenCrmPromotionPloy") || !(goodsList.length > 0)) {
			return resolve(_suppCrmMatchPloyResult());
		}

		// 生成接口请求数据
		let requestData = await _createCrmMatchPromotionPloyRequestData(goodsList);
		if (!requestData) {
			return resolve(_suppCrmMatchPloyResult());
		}

		// 请求接口
		callFeignV1("crm.goods.matchpromotionploy", {
			jsonStr: JSON.stringify({
				jsonStr: requestData
			})
		}).then((res) => {
			// 测试代码
			console.log("CRM促销策略匹配结果", res);
			let data = {};
			if (res.code == 200) {
				data = res.result.data || {};
			} else {
				console.log("CRM促销策略匹配失败", res);
			}
			resolve(_suppCrmMatchPloyResult(data, requestData.matchParam.payment));
		});
	});
}

/**
 * 生成CRM促销策略匹配请求数据
 */
const _createCrmMatchPromotionPloyRequestData = async (goodsList) => {
	try {
		// 获取用户Id
		let customerId = localStorage.getItem("pc_customerId");

		// 获取当前渠道信息
		let channelInfo = await getChannelInfo();

		// 获取仓库Id（1. 仓库Id需要和订单保持一致，取的是erp渠道默认仓库 2. 若开启“订单填充默认仓库”，则需要传仓库Id，否则不需要传）
		let warehouseId = await isOpenConfig("isFillWarehouseInfo") ? channelInfo.warehouseId : 0;

		// 获取商品简略信息
		let goodsIdList = commonUtils.distinctArr(goodsList.map(t => t.goodsId));
		let simpleGoodsList = await getSimpleGoodsList(goodsIdList);

		// 生成待匹配商品信息
		let needMatchGoodsList = [];
		let totalAmount = 0;
		goodsList.forEach((item, index) => {
			// 兼容处理相关字段
			item.unitName = item.unitName || item.unit || "";
			item.sellCount = item.goodsCount || item.sellCount || 0;
			item.sellPrice = item.executionPrice || item.sellPrice || item.goodsPrice || 0;
			item.sellTotal = commonUtils.floatKeepDigit(item.sellCount * item.sellPrice);

			// 累加金额
			totalAmount += item.sellTotal;

			// 匹配商品简略信息（获取ERP分类、标签、品牌数据）
			let simpleGoods = simpleGoodsList.find(t => t.goodsId == item.goodsId) || {};

			// 生成数据
			needMatchGoodsList.push({
				rowNo: index + 1,
				goodsId: item.goodsId,
				goodsName: item.goodsName,
				specId: item.specId,
				specName: item.specName,
				unit: item.unitName,
				sellCount: item.sellCount,
				sellPrice: item.sellPrice,
				sellTotal: item.sellTotal,
				isFit: item.isPackage,
				cateId: simpleGoods.jackyunCateId || "",
				brandId: simpleGoods.jackyunBrandId || "",
				goodsTags: simpleGoods.jackyunFlagData || "",
				ignoreGoodsFlag: 0, // 忽略商品标记
				bargainGoodsFlag: item.isBargainGoods ? 1 : 0, // 特价商品标记（传1表示会排除特价商品）
			});
		});

		// 生成最终请求数据
		let requestData = {
			matchParam: {
				customerId, // 客户Id
				shopId: channelInfo.channelId, // 渠道Id
				warehouseId, // 仓库Id
				payment: commonUtils.floatKeepDigit(totalAmount), // 应收合计
				tradeFrom: 4, // 订单来源，不传则默认为门店（吉商城：4）
				goodsArr: needMatchGoodsList, // 货品信息
			},
			matchDiscount: 1, // 是否计算金额优惠，优惠措施为“满减优惠”时使用 0:不计算金额优惠 1:计算金额优惠
			executeTime: 6, // 执行时机 0:新增销售单 1:销售单完成 2:新建意向单 3:转出预售 4:发货完成(销售返利) 5:门店收银 6:吉商城下单
		};
		return requestData;
	} catch (e) {
		console.log("CRM促销策略匹配请求数据生成失败", e);
		return null;
	}
}

/**
 * 补充促销策略匹配结果补充
 * @param {*} res 
 */
const _suppCrmMatchPloyResult = (res, payment = 0) => {
	// 默认数据补充
	res = res || {};
	res.discount = res.discount || 0;
	res.promotionResult = res.promotionResult || [];
	res.promotionRemindDtoList = res.promotionRemindDtoList || [];
	res.amountPromotionAggregate = res.amountPromotionAggregate || [];
	res.satisfyGoodsSkuArr = res.satisfyGoodsSkuArr || [];

	// 结果金额校验和处理（抵扣上限控制、负值控制、总抵扣金额计算等）
	__crmMatchPloyResultAlign(res, payment);

	// 异常数据兼容处理
	res.promotionResult.forEach(promotion => {
		// 匹配到的商品数据（有时候非货品级匹配，也会返回goodsMultipleArr，但是satisfyGoodsArr没有值，会造成后续匹配异常）
		if (promotion.goodsMultipleArr && promotion.goodsMultipleArr.length > 0) {
			promotion.goodsMultipleArr = promotion.goodsMultipleArr.filter(t => t.satisfyGoodsArr && t.satisfyGoodsArr.length > 0);
		}
	});

	return res;
}

/**
 * 结果金额校验和处理（抵扣上限控制、负值控制、总抵扣金额计算等）
 * @param {*} matchResult 
 * @param {*} payment 
 */
const __crmMatchPloyResultAlign = (matchResult, payment) => {
	// 仅保留有效抵扣金额
	matchResult.promotionResult = matchResult.promotionResult.filter(t => t.discount > 0);

	// 无效的提示数据清除（过滤掉负值策略后响应的提示文字也要去除）
	let newPromotionRemindDtoList = [];
	matchResult.promotionRemindDtoList.forEach(remind => {
		if (remind.promotionStatus == "已参加") {
			// 获取命中的策略，策略名称不会重复
			let hitPromotion = matchResult.promotionResult.find(t => t.ployName == remind.promotionName);
			if (hitPromotion) {
				newPromotionRemindDtoList.push(remind);
			}
		} else {
			newPromotionRemindDtoList.push(remind);
		}
	});
	matchResult.promotionRemindDtoList = newPromotionRemindDtoList;

	// 重新计算总抵扣金额，matchResult.discount只展示了订单级抵扣
	matchResult.discount = commonUtils.sumArr(matchResult.promotionResult, "discount");

	// 检测是否超过抵扣上限，未超过则无需处理
	if (!(matchResult.discount > payment)) {
		return;
	}

	// 重新赋值抵扣上限
	matchResult.discount = payment;

	// 遍历抵扣明细结果，逐个扣减
	matchResult.promotionResult.forEach(t => {
		if (t.discount > payment) {
			t.discount = payment;
			payment = 0;
		} else {
			payment -= t.discount;
			payment = payment < 0 ? 0 : payment;
		}
	});
}

export default {
	getGoodsPriceList,
	getGoodsMinPriceList,
	getGoodsMinPriceListForPackage,
	matchCrmPromotionPloy
}

export {
	getGoodsPriceList,
	getGoodsMinPriceList,
	getGoodsMinPriceListForPackage,
	matchCrmPromotionPloy
}