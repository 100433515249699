const request = require('../request-utils.js');
const price = require('./api-goods-price.js');
import commonUtils from '../common-utlis.js'

import Vue from 'vue'
import {
	getCustomerInfo
} from './api-customer.js'
import {
	getParam,
	getParamByKeysFromCache
} from './api-params.js'
import {
	callFeignV1,
	callFeign
} from './api-callFeign.js';
import {
	constant
} from '../../config.js';
import {
	getTimelinesData,
	setTimelinesData
} from './data-refresh.js'

/**
 * 获取商品库存
 */
const getGoodsStock = async (goodsId, executeByConfig = true) => {
	return new Promise(async (resolve, reject) => {
		// 检测是否开启商品库存展示
		if (executeByConfig && !(await __isShowGoodsStock() || await __isOpenIndistinctStock())) {
			return "";
		}

		try {
			// 获取库存列表
			let stockList = await __getGoodsStockListFromOrigin(goodsId);
			let stockInfo = stockList.find(t => t.goodsId == goodsId);
			// 返回结果
			resolve(stockInfo ? stockInfo.stock : 0)
		} catch (e) {
			resolve(0);
		}
	});
}

/**
 * 获取商品列表库存
 * @param {*} goodsIds 
 * @param {*} executeByConfig 
 */
const getGoodsStockList = async (goodsIds, executeByConfig = true) => {
	return new Promise(async (resolve, reject) => {
		let result = [];
		// 检测是否开启商品库存展示
		if (executeByConfig && !(await __isShowGoodsStock() || await __isOpenIndistinctStock())) {
			resolve([]);
			return;
		}

		// 获取库存列表
		let stockList = await __getGoodsStockListFromOrigin(goodsIds);

		// 确保每个商品都返回库存
		goodsIds.forEach(goodsId => {
			let stockInfo = stockList.find(t => t.goodsId == goodsId);
			// 获取当前商品库存
			let item = {
				goodsId,
				stock: stockInfo ? stockInfo.stock : 0
			};
			result.push(item);
		});

		// 返回结果
		resolve(result);
	});
}

/**
 * 获取规格列表库存
 * @param {*} skuIds 
 * @param {*} executeByConfig 
 */
const getSkuStockList = async (skuIds) => {
	return new Promise(async (resolve, reject) => {
		// 获取库存列表
		let stockList = await __getSkuStockListFromOrigin(skuIds);
		// 返回结果
		resolve(stockList);
	});
}

/**
 * 是否开启库存展示
 */
const __isShowGoodsStock = async () => {
	let paramList = await getParam("isShowGoodsStock");
	return !!paramList && paramList.isShowGoodsStock == "1";
}

/**
 * 是否开启模糊库存
 */
const __isOpenIndistinctStock = async () => {
	let paramList = await getParam("isOpenIndistinctStock");
	return !!paramList && paramList.isOpenIndistinctStock == "1";
}

/**
 * 获取商品库存列表
 */
const __getGoodsStockListFromOrigin = (goodsIds) => {
	return new Promise(async (resolve, reject) => {
		let warehouseIds = await __getWarehouseId__();
		request.default.jackyunPost({
			method: "jmall.goods.getgoodssumstocklist",
			goodsIds: goodsIds.join(','),
			warehouseIds: warehouseIds,
			channelId: localStorage.getItem("pc_channelId")
		}, (res) => {
			if (res.code == 200) {
				return resolve(res.result.data);
			}
			resolve([]);
		});
	});
}

/**
 * 获取规格库存列表
 */
const __getSkuStockListFromOrigin = (skuIds) => {
	return new Promise(async (resolve, reject) => {
		let warehouseIds = await __getWarehouseId__();
		request.default.jackyunPost({
			method: "jmall.goods.getskustocklist",
			skuIds: skuIds.join(','),
			warehouseIds: warehouseIds,
			channelId: localStorage.getItem("pc_channelId"),
		}, (res) => {
			if (res.code == 200) {
				return resolve(res.result.data);
			}
			resolve([]);
		});
	});
}

/**
 * 订单确认页面校验预售商品
 */
const checkPresaleGoodsCountNoTradeId = (presaleId, goodsId, specId, unit, goodsCount) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.activity.getpresalegoodscount",
			presaleId: presaleId,
			goodsId: goodsId,
			specId: specId,
			unit: unit,
			customerId: localStorage.getItem("pc_customerId")
		}, (res) => {
			if (res.code == 200 && !!res.result.data) {
				return resolve(res.result.data.goodsCount >= goodsCount);
			}
			resolve(false);
		});
	})

}

/**
 * 订单确认页面校验货品是否上架
 */
const checkBlockUpGoods = (goodsInfoList) => {
	let channelId = "0";
	let reqData = {
		channelId: channelId,
		goodsInfoList: goodsInfoList,
		bCheckChannelGoods: false
	}
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.checkisonsales",
			jsonStr: JSON.stringify(reqData)
		}, (res) => {
			if (res.code == 200 && !!res.result.data) {
				return resolve(res.result.data);

				//return resolve([{goodsId:"276004812381238123", imgUrl: "https://jkyun--jmall.oss-cn-hangzhou.aliyuncs.com/jmc/jmallFile/jackyun_dev/goods/202310161544509600.jpg",goodsName: "包邮日本 肌美精5枚入维生素C胶原蛋白保湿浸透深层美白面膜20ml",specId:"1797191146535945728",specName:"1797191146535945728"}]);
			}
			resolve([]);
		});
	})

}
/**
 * 订单确认页面校验货品是否是会员等级限制商品
 */
const checkMemberBlockGoods = async (goodsIdList, isRefreshCustomerInfo = false) => {
	// 获取客户信息
	let customerInfo = await getCustomerInfo(isRefreshCustomerInfo);
	let reqData = {};
	reqData.memberGrade = customerInfo.vipLevel;
	reqData.goodsIdList = goodsIdList;
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.policy.getmemberlevelblockgoodslist",
			jsonStr: JSON.stringify(reqData)
		}, (res) => {
			if (res.code == 200 && !!res.result.data) {
				return resolve(res.result.data);

				//return resolve([{goodsId:"276004812381238123", imgUrl: "https://jkyun--jmall.oss-cn-hangzhou.aliyuncs.com/jmc/jmallFile/jackyun_dev/goods/202310161544509600.jpg",goodsName: "包邮日本 肌美精5枚入维生素C胶原蛋白保湿浸透深层美白面膜20ml",specId:"1797191146535945728",specName:"1797191146535945728"}]);
			}
			resolve([]);
		});
	})

}

/**
 * 获取仓库
 */
const __getWarehouseId__ = async () => {
	return new Promise((resolve, reject) => {
		let warehouseId = localStorage.getItem("pc_warehouseId");
		if (!!warehouseId) {
			return resolve(warehouseId);
		}

		//门店信息
		request.default.getParam('bindWarehouseId',
			paramlist => {
				if (!!paramlist && !!paramlist.bindWarehouseId) {
					resolve(paramlist.bindWarehouseId);
				} else {
					resolve("");
				}
			});
	});
}

/**
 * 检测下单库存
 */
const checkOrderGoodsStock = async (orderGoodsList, tradeId) => {
	return new Promise((resolve, reject) => {
		// 调用下单库存检测接口
		request.default.jackyunPost({
			method: "jmall.goods.checkgoodsstock",
			jsonStr: JSON.stringify({
				orderGoodsList,
				warehouseIds: localStorage.getItem("pc_warehouseId").split(','),
				isSuppGoodsDetail: true,
				customerId: localStorage.getItem("pc_customerId"),
				tradeId: tradeId,
				channelId: "0",
				isIgnoreZeroStockBuy: false,
			})
		}, (res) => {
			// 检测限购结果，返回数据为限购数据
			if (res.code == 200) {
				// 返回检测结果
				resolve(res.result.data);
			} else {
				// 返回异常结果（不拦截下单）
				resolve({
					canOrderGoodsList: orderGoodsList, // 可购列表
					cannotOrderGoodsList: [], // 不可购列表
					baseUnitOverOrderGoodsList: [], // 库存超卖列表
				});
			}
		});
	});
}

/**
 * 检测下单erp库存
 */
const checkGoodsErpStock = async (orderGoodsList, tradeId) => {
	return new Promise((resolve, reject) => {
		// 调用下单库存检测接口
		request.default.jackyunPost({
			method: "jmall.goods.checkgoodserpstock",
			jsonStr: JSON.stringify({
				orderGoodsList,
				warehouseIds: localStorage.getItem("pc_warehouseId").split(','),
				isSuppGoodsDetail: true,
				customerId: localStorage.getItem("pc_customerId"),
				tradeId: tradeId,
				channelId: "0",
				isIgnoreZeroStockBuy: false,
			})
		}, (res) => {
			// 检测限购结果，返回数据为限购数据
			if (res.code == 200) {
				// 返回检测结果
				resolve(res.result.data);
			} else {
				// 返回异常结果（不拦截下单）
				resolve({
					canOrderGoodsList: orderGoodsList, // 可购列表
					cannotOrderGoodsList: [], // 不可购列表
					baseUnitOverOrderGoodsList: [], // 库存超卖列表
				});
			}
		});
	});
}

/**
 * 检测下单活动库存
 */
const checkGoodsActivityStock = async (orderGoodsList, tradeId) => {
	return new Promise((resolve, reject) => {
		// 调用下单库存检测接口
		request.default.jackyunPost({
			method: "jmall.goods.checkgoodsactivitystock",
			jsonStr: JSON.stringify({
				saleItemList: orderGoodsList,
				customerId: localStorage.getItem("pc_customerId"),
				tradeId: tradeId,
			})
		}, (res) => {
			// 检测限购结果，返回数据为限购数据
			if (res.code == 200) {
				// 返回检测结果
				resolve(res.result.data || []);
			} else {
				// 返回异常结果（不拦截下单）
				resolve([]);
			}
		});
	});
}

/**
 * 检测预售商品数量
 * @param {*} orderGoodsList 
 * @param {*} tradeId 
 */
const checkPresaleGoodsCount = async (orderGoods, tradeId) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.activiy.updatepresalegoodscount",
			jsonStr: JSON.stringify({
				presaleId: orderGoods.actId,
				goodsId: orderGoods.goodsId,
				goodsName: orderGoods.goodsName,
				specId: orderGoods.specId,
				unit: orderGoods.unitName,
				goodsCount: orderGoods.goodsCount,
				tradeId: tradeId,
				customerId: localStorage.getItem("pc_customerId")
			})
		}, (res) => {
			// 检测限购结果，返回数据为限购数据
			if (res.code == 200) {
				// 返回检测结果
				resolve(res.result.data);
			} else {
				// 返回异常结果（不拦截下单）
				resolve(false);
			}
		});
	})
}

/**
 * 商品列表
 */
const listGoods = async (goodsfrom, goodscount, goodsids, pageIndex) => {
	let orderby = "";
	let goods_ids = "";
	let category_id = "";
	let is_distributorgoods = false;
	let group_id = "";

	if (goodsfrom == "1") {
		orderby = "createTime"; //最新商品
	} else if (goodsfrom == "2") {
		orderby = "sellCountTotal"; //热门商品
	} else if (goodsfrom == "3") {
		is_distributorgoods = true; // 分销商品
	} else if (goodsfrom == "4") {
		goods_ids = goodsids; // 自选商品
		orderby = "optionalGoods"; //自选商品
	} else if (goodsfrom == "5") {
		group_id = goodsids; // 商品分组
		orderby = 'goods_id';
	} else if (goodsfrom == "6") {
		category_id = goodsids; // 商品分类
	}

	let orConditionsJsonStr = {

	};

	let channelId = "";
	let andConditionJsonStr = {
		categoryIds: category_id,
		groupIds: group_id,
		goodsIds: goodsfrom != "4" || goodsids == "0" ? '' : goodsids,
		isDistributorGoods: is_distributorgoods,
		channelId: channelId,
		isBlockUp: "0"
	};
	let pageSize = goodscount;
	let goodsList = await getGoodsRequest(orderby, pageIndex, pageSize, andConditionJsonStr,
		orConditionsJsonStr);
	return goodsList;
}

/**
 * 获取商品数量
 */
const getGoodsCount = (andConditionJsonStr, orConditionsJsonStr) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.getlistgoodsnewcount",
			andConditionsJsonStr: JSON.stringify(andConditionJsonStr),
			orConditionsJsonStr: JSON.stringify(orConditionsJsonStr)
		}, (res) => {
			if (res.code == 200) {
				// 返回检测结果
				resolve(parseInt(res.result.data));
			} else {
				resolve(0);
			}
		});
	})
}

/**
 * 獲取商品列表請求
 */
const getGoodsRequest = (orderby, pageIndex, pageSize, andConditionJsonStr, orConditionsJsonStr, sortOrder) => {
	return new Promise((resolve, reject) => {
		// 获取客户信息
		let customerInfo = JSON.parse(localStorage.getItem("pc_customerInfo"));
		if (customerInfo && customerInfo.vipLevel) {
			andConditionJsonStr.memberGrade = customerInfo.vipLevel;
		};
		request.default.jackyunPost({
			method: "jmall.goods.getgoodslist",
			sortField: orderby,
			sortOrder: sortOrder || "DESC",
			pageIndex: pageIndex,
			pageSize: pageSize,
			andConditionsJsonStr: JSON.stringify(andConditionJsonStr),
			orConditionsJsonStr: JSON.stringify(orConditionsJsonStr)
		}, (res) => {
			if (res.code == 200) {
				// 返回检测结果
				resolve(res.result.data);
			} else {
				if (res.message.includes('版本') >= 0) {
					Vue.prototype.$message({
						message: "当前客户端版本过旧，请刷新重试",
						type: 'warning'
					});
				}
				resolve([]);
			}
		});
	})
}
/**
 * 获取扩展信息
 */
const getGoodsExtraInfo = (goodsIdArray, activityTypes = "1,2,3") => {
	let goodsIds = goodsIdArray.join(",");
	// 读取客户信息
	let customer = !!localStorage.getItem('pc_customerInfo') ? JSON.parse(localStorage.getItem('pc_customerInfo')) : "";
	let memberGrade = ((customer && customer.customerId) && (customer.vipLevel || "no")) || "all";
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.getgoodsextrainfo",
			goodsIds: goodsIds,
			activityTypes: activityTypes,
			memberGrade: memberGrade,
		}, (res) => {
			if (res.code == "200") {
				resolve(res.result.data);
			} else {
				resolve([]);
			}
		})
	})
}

const _checkMarketingMemberLevel = (goodsList) => {
	goodsList.forEach(item => {
		if (!!item.label_name) {
			item.satisfyMarketing = true;
			if (customer && (!!item.limit_member && item.limit_member !=
					'all' && item.limit_member != 'newmember')) {
				var supportLevel = item.limit_member.split(',');
				customer.vipLevel = !!customer.vipLevel ? customer.vipLevel : "empty";
				if (!supportLevel.includes(customer.vipLevel)) {
					item.satisfyMarketing = false;
				}
			}
		}
	})
}

/**
 * 补充完整的货品信息（价格、库存、营销活动信息等）
 */
const completeFullGoodsInfoForList = async (goodslist, fromPage) => {
	if (goodslist.length == 0) {
		return [];
	}

	// 标签展示配置
	let paramList = await getParam('labelsInExtraPage');
	let labelListInExtraPage = (paramList.labelsInExtraPage || '').split(",");

	// 遍历商品列表，生成前置对象以及补充部分数据
	let allGoodsIdList = [];
	let normalGoodsIdList = [];
	let packageGoodsIdList = [];
	goodslist.forEach(t => {
		// 补充默认库存数据
		t.stock = 999;
		t.stockTxt = "";

		// 生成所需的商品Id集合
		allGoodsIdList.push(t.goodsId);
		if (t.goodsType == 0) {
			normalGoodsIdList.push(t.goodsId);
		} else if (t.goodsType == 1) {
			packageGoodsIdList.push(t.goodsId);
		}
	});

	await Promise.all([
		price.default.getGoodsMinPriceList(normalGoodsIdList),
		getGoodsExtraInfo(allGoodsIdList),
		getGoodsStockList(allGoodsIdList, true),
		price.default.getGoodsMinPriceListForPackage(packageGoodsIdList)
	]).then((values) => {
		let priceList = values[0];
		let extraInfoList = values[1];
		let stockList = values[2];
		let packagePriceList = values[3];

		// 读取客户信息
		let customer = !!localStorage.getItem('pc_customerInfo') ? JSON.parse(localStorage.getItem('pc_customerInfo')) : "";
		// 获取商品价格
		goodslist.forEach((goods) => {
			// 标签展示数据
			let labelNameList = goods.labels && goods.labels.split(',').filter(item => item && labelListInExtraPage.includes(item)) || []

			let extraInfo = extraInfoList.find(t => t.goodsId == goods.goodsId);
			if (extraInfo) {
				if (extraInfo.activityType == "预售") {
					goods.presaleId = extraInfo.activityId;
				}
				if (!!extraInfo.activityType && extraInfo.activityType == '抢购') {
					goods.timeSaleActivityPrice = extraInfo.activityPrice;
					goods.activityId = extraInfo.activityId;
					goods.activityType = '抢购';
				}
				extraInfo.labelName && labelNameList.unshift(extraInfo.labelName);
				goods.labelName = extraInfo.labelName;

				// 默认不适用
				goods.satisfyMarketing = false;
				if (!!extraInfo.labelName) {
					// 未设置限制会员则适用促销活动
					if (extraInfo.limitMember == "" || extraInfo.limitMember == "all") {
						goods.satisfyMarketing = true;
					} else {
						// 存在会员但是不满足会员等级置为
						if (customer && customer.customerId) {
							var supportLevel = extraInfo.limitMember.split(',');
							customer.vipLevel = !!customer.vipLevel ? customer.vipLevel :
								"empty";
							if (supportLevel.includes(customer.vipLevel)) {
								goods.satisfyMarketing = true;
							}
						}
					}
				}
			}

			// 补充标签数据
			goods.labelNameList = labelNameList;

			let price
			if (goods.goodsType == 0) {
				price = priceList.find(t => t.goods_id == goods.goodsId);
			} else {
				price = packagePriceList.find(t => t.goods_id == goods.goodsId);
			}

			if (price) {
				goods.priceLoad = true;
				goods.priceDetail = parseFloat(price.price_detail);
				goods.priceMember = parseFloat(goods.timeSaleActivityPrice || price.price_member);
			} else {
				goods.priceLoad = true;
				goods.priceDetail = '0.00';
				goods.priceMember = '0.00';
			}

			let stockInfo = stockList.find(t => t.goodsId == goods.goodsId);
			if (!!stockInfo) {
				goods.stock = stockInfo.stock;
				goods.stockTxt = getStockDesc(stockInfo.stock, fromPage);
			}
		});
	});

	return goodslist;
}

/**
 * 获取吉客云商品扩展信息
 */
const getJackyunGoodsExtendedInfo = (goodsIdList) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.getjackyungoodsextendedinfo",
			goodsIdList: goodsIdList.join(',')
		}, (res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data;
			}
			resolve(data);
		})
	})
}

/**
 * 将当前单位转为基础单位数量
 */
const convertToBaseUnitCount = (unitInfo, unitName, count) => {
	try {
		// 反序列化单位信息
		let unitList = JSON.parse(unitInfo);

		// 获取基础单信息
		let currUnit = unitList.find(t => t.unitName == unitName);
		if (!currUnit) {
			return count;
		}

		// 数量乘以基础单位比例
		return count * currUnit.countRate;
	} catch (e) {
		return count;
	}
}

/**
 * 获取SKU价格信息
 */
const getSkuPriceList = async (skuIdList) => {
	// 获取渠道和用户Id
	let channelId = localStorage.getItem('pc_channelId');
	let customerInfo = await getCustomerInfo();
	let customerId = customerInfo.customerId;
	let decimalKeepPlaces = localStorage.getItem('decimalKeepPlaces');
	// 缺少参数的情况不请求接口
	if (!channelId || !skuIdList || skuIdList.length <= 0) {
		return [];
	}

	// 获取SKU价格列表
	let priceList = await _getSkuPriceListFromOrigin(channelId, customerId, skuIdList);
	// 处理价格格式
	priceList = priceList.map(t => {
		// 未登录时展示游客展示价
		if (!customerId) {
			t.priceMember = t.priceDetail;
		}
		return {
			skuId: t.skuId,
			unitName: t.unitName,
			score: t.score || 0, // 积分
			memberPrice: commonUtils.floatKeepDigit(t.priceMember || 0, decimalKeepPlaces), // 会员价
			referencePrice: commonUtils.floatKeepDigit(t.lineShowPrice || 0, decimalKeepPlaces), // 参考价（综合零售价和付费会员执行价）
			executionPrice: commonUtils.floatKeepDigit(t.priceMember || 0, decimalKeepPlaces), // 最终执行价（营销活动会根据数量等调整）
			isBargainGoods: !!t.isBargainGoods, // 是否为特价商品
		}
	});

	return priceList;
}

/**
 * 调用sku价格获取接口
 */
const _getSkuPriceListFromOrigin = (channelId, customerId, skuIdList) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.getskupricelist",
			channelId,
			customerId,
			specIds: skuIdList.join(',')
		}, (res) => {
			let priceList = [];
			if (res.code == 200 && res.result.data && res.result.data.length >= 0) {
				priceList = res.result.data;
			}
			resolve(priceList);
		})
	});
}

/**
 * 获取推荐商品
 */
const getRecommendGoodsInfo = (goodsId) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.getgoodsrecommendinfo",
			jsonStr: JSON.stringify({
				goodsId
			})
		}, (res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data || [];
			}
			resolve(data);
		})
	});
}

/**
 * 获取商品服务列表
 */
const getGoodsList = (andConditions, orConditions, sortField, sortOrder, pageIndex, pageSize) => {
	return new Promise(async (resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.getgoodslist",
			andConditionsJsonStr: JSON.stringify(andConditions),
			orConditionsJsonStr: JSON.stringify(orConditions),
			sortField,
			sortOrder,
			pageIndex,
			pageSize
		}, (res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data || [];
			}
			resolve(data);
		})
	});
}

/**
 * 商品加购
 * @param {*} cartGoodsInfo (cartType 普通购物车=0，临时购物车=-1)
 * @param {*} cartGoodsInfo (actType actId goodsId specId goodsCount unitName unitCountRate)
 */
const addShopCart = (cartGoodsInfo) => {
	// 获取渠道Id和客户Id
	let channelId = localStorage.getItem("pc_channelId");
	let customerId = localStorage.getItem("pc_customerId");
	return new Promise(async (resolve, reject) => {
		// 检测用户信息和渠道
		if (!channelId || !customerId) {
			resolve(0);
		}
		// 补充购物车信息
		cartGoodsInfo.cartType = cartGoodsInfo.cartType || 0;
		cartGoodsInfo.channelId = "0";
		cartGoodsInfo.customerId = customerId;
		cartGoodsInfo.unit = cartGoodsInfo.unit || cartGoodsInfo.unitName;

		request.default.jackyunPost({
			method: "jmall.goods.addshopcart",
			jsonStr: JSON.stringify(cartGoodsInfo)
		}, (res) => {
			let cartId = 0;
			if (res.code == 200) {
				cartId = res.result.data;
			} else {
				//this.$message.error("添加失败" + data.error_info);
			}
			resolve(cartId);
		})
	});
}

/**
 * 加入临时购物车（批量）
 * @param {*} cartGoodsList 
 */
const addTempShoppingCartBatch = async (cartGoodsList) => {
	// 获取渠道Id和客户Id
	let channelId = localStorage.getItem("pc_channelId");
	let customerId = localStorage.getItem("pc_customerId");

	return new Promise((resolve, reject) => {
		// 检测用户信息和渠道
		if (!channelId || !customerId) {
			resolve(0);
		}

		// 补充货品信息
		cartGoodsList.forEach(t => {
			t.cartType = t.cartType === 0 ? t.cartType : -1;
			t.customerId = customerId;
			t.channelId = "0";
			t.unit = t.unit || t.unitName;
			t.unitCountRate = t.unitCountRate || 1;
		});

		// 添加购物车数据
		request.default.jackyunPost({
			method: "jmall.goods.batchaddshopcart",
			jsonStr: JSON.stringify(cartGoodsList)
		}, (res) => {
			let cartIds = "0";
			// 检测获取结果是否正确
			if (res.code == 200 && res.result.data) {
				cartIds = res.result.data.join(',');
			}
			// 返回结果数据
			resolve(cartIds);
		})
	});
}

/**
 * 获取商品主体详情
 * @param {*} goodsId 二选一
 * @param {*} goodsNo 二选一
 */
const getGoodsDetail = (goodsId, goodsNo) => {
	// 获取渠道Id
	let channelId = localStorage.getItem("pc_channelId");
	// 检测传参是否存在，不存在则不执行
	if (!goodsId && !goodsNo) {
		return {};
	}
	return new Promise(async (resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.getgoodsdetail",
			goodsId,
			goodsNo,
			// channelId
		}, (res) => {
			let data = {};
			if (res.code == 200 && !!res.result.data) {
				data = res.result.data;
			} else {
				console.log(`商品详情获取失败（${goodsId}）:`, res);
			}
			resolve(data);
		})
	});
}

/**
 * 获取商品规格列表
 * @param {*} goodsId 
 */
const getGoodsSkuList = (goodsId) => {
	return new Promise(async (resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.getskulist",
			goodsId,
			pageIndex: 0,
			pageSize: 1000,
			show: 1,
			orderByClause: 'spec_id'
		}, (res) => {
			let data = [];
			if (res.code == 200 && !!res.result.data && res.result.data.length >= 0) {
				data = res.result.data;
			}
			resolve(data);
		})
	});
}

/**
 * 删除购物车数据
 * @param {*} cartIdList 
 */
const deleteShoppingCart = async (cartIdList) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.deleteshoppingcart",
			cartIdList: cartIdList.join(',')
		}, (res) => {
			// 返回结果数据
			resolve(res.code == 200);
		});
	});
}

/**
 * 库存展示文字处理
 * @param {*} stock 
 */
const getStockDesc = (stock, fromPage) => {
	// 需要获取的配置名
	let paramKeys = "isShowGoodsStock,isOpenIndistinctStock,isShowStockOuts,isOpenZeroStockBuy,indistinctStockExceptionalLowerLimit,indistinctStockExceptionalUpperLimit,stockShowPageSelect";
	// 获取配置信息
	let paramList = getParamByKeysFromCache(paramKeys);

	// 模糊库存节点
	let indistinctNode1 = 0;
	let indistinctNode2 = 5;
	// 是否展示库存
	let isShowGoodsStock = paramList.isShowGoodsStock == "1";
	// 是否开启模糊库存
	let isOpenIndistinctStock = paramList.isOpenIndistinctStock == "1";
	// 是否显示库存不足的库存
	let isShowStockOuts = paramList.isShowStockOuts != "0";
	// 是否开启0库存下单
	let isOpenZeroStockBuy = paramList.isOpenZeroStockBuy == "1";
	// 是否开启模糊库存例外规则（有设置值，则启用，命中例外规则需要展示精确库存）
	let isOpenIndistinctStockExceptionalRule = !!paramList.indistinctStockExceptionalLowerLimit && !!paramList.indistinctStockExceptionalUpperLimit;
	// 例外规则上下限的值
	let exceptionalLowerLimit = parseFloat(paramList.indistinctStockExceptionalLowerLimit);
	let exceptionalUpperLimit = parseFloat(paramList.indistinctStockExceptionalUpperLimit);
	// 展示库存的页面
	let stockShowPageSelect = paramList.stockShowPageSelect || "goodsList,goodsDetail,shopcart";

	// 不显示库存
	if (!isShowGoodsStock) {
		return "";
	}

	// 不显示库存的页面
	if (fromPage && !stockShowPageSelect.includes(fromPage)) {
		return "";
	}

	// 不显示库存不足的商品
	if (!isShowStockOuts && stock <= 0) {
		return "";
	}

	// 未开启模糊库存，则返回精确库存
	if (!isOpenIndistinctStock) {
		return `库存：${stock}`;
	}

	// 是否启用例外规则
	if (isOpenIndistinctStockExceptionalRule) {
		if (exceptionalLowerLimit <= stock && stock <= exceptionalUpperLimit) {
			return `库存：${stock}`;
		}
	}

	// 按照模糊库存节点判断
	if (stock <= indistinctNode1) {
		// return !isOpenZeroStockBuy ? "缺货" : "";
		return "缺货";
	}
	if (stock <= indistinctNode2) {
		return "库存紧张";
	}
	return "库存充足";
}

/**
 * 多条件查询货品的列表（http://192.168.88.47/web/#/3?page_id=2660）
 */
const getErpGoodsList = (jsonStr) => {
	return new Promise((resolve, reject) => {
		callFeignV1("erp.goods.getgoodslist", {
			jsonStr: JSON.stringify(jsonStr)
		}).then((res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data || [];
			} else {
				console.log("获取erp货品列表失败：", res);
			}
			resolve(data);
		});
	})
}

/**
 * 检测是否包含组合装
 * @param {*} goodsId 
 */
const checkIsIncludesPackageGoods = async (goodsIds) => {
	// 根据商品ID获取ERP商品列表（只查组合装）
	let goodsList = await getErpGoodsList({
		goodsIds,
		packageGood: 1
	});

	return goodsList && goodsList.length > 0;
}


/**
 * 获取erp货品详情
 */
const getGoodsInfoFromErp = async (goodsId) => {
	let res = await callFeign("erp.goods.getgoodsbyid", {
		goodsId
	});
	return res.code == 200 ? res.result.data : {};
}

/**
 * 获取货品自定义字段映射关系
 */
const getGoodsCustomFieldMap = async (isRefresh) => {
	let goodsCustomFieldMapInfo;
	// 如果需要获取最新数据，则不读取缓存
	if (!isRefresh) {
		// 优先获取已有数据
		goodsCustomFieldMapInfo = getTimelinesData(constant.DATA_KEY_CUSTOMFIELD);
		// 如果数据存在则直接返回，否则调用接口获取最新数据
		if (goodsCustomFieldMapInfo) {
			return goodsCustomFieldMapInfo;
		}
		goodsCustomFieldMapInfo = await _getGoodsCustomFieldMapFromOrigin();

		// 获取正确则进行保存
		if (!!goodsCustomFieldMapInfo) {
			setTimelinesData(constant.DATA_KEY_CUSTOMFIELD, goodsCustomFieldMapInfo);
		}

		// 返回信息
		return goodsCustomFieldMapInfo;
	}
}

/**
 * 获取货品自定义字段映射关系
 */
const _getGoodsCustomFieldMapFromOrigin = async () => {
	let res = await callFeignV1("erp.open.getcustomfield", {});
	return res.code == 200 ? res.result.data || [] : [];
}

/**
 * 获取自由容器所需商品列表
 */
const getGoodsListForFreeBox = async (goodsFrom, goodsData, showCount) => {
	// 搜索条件
	let orderBy = "";
	let categoryIds = "";
	let groupIds = "";
	let goodsIds = "";
	let isDistributorGoods = false;
	// 来源类型：1 分组，2 分类，3 自选
	if (goodsFrom == "1") {
		// 最新商品
		if (goodsData == "new") {
			orderBy = "createTime";
		} else if (goodsData == "hot") {
			orderBy = "sellCountTotal";
		} else if (goodsData == "dist") {
			isDistributorGoods = true;
		} else {
			groupIds = goodsData;
		}
	} else if (goodsFrom == "2") {
		categoryIds = goodsData;
	} else if (goodsFrom == "3") {
		goodsIds = goodsData;
	}

	let andConditionJsonStr = {
		categoryIds,
		groupIds,
		goodsIds,
		isDistributorGoods,
		channelId: "",
		isBlockUp: "0"
	};

	return await getGoodsRequest(orderBy, 1, showCount, andConditionJsonStr, {});
}

export default {
	getGoodsStock,
	getGoodsStockList,
	getSkuStockList,
	checkOrderGoodsStock,
	checkGoodsActivityStock,
	checkGoodsErpStock,
	checkPresaleGoodsCount,
	checkPresaleGoodsCountNoTradeId,
	checkBlockUpGoods,
	checkMemberBlockGoods,
	listGoods,
	getGoodsExtraInfo,
	completeFullGoodsInfoForList,
	getGoodsRequest,
	getGoodsCount,
	getJackyunGoodsExtendedInfo,
	convertToBaseUnitCount,
	getSkuPriceList,
	getRecommendGoodsInfo,
	getGoodsList,
	getGoodsDetail,
	getGoodsSkuList,
	addTempShoppingCartBatch,
	addShopCart,
	deleteShoppingCart,
	getStockDesc,
	getErpGoodsList,
	checkIsIncludesPackageGoods,
	getGoodsInfoFromErp,
	getGoodsCustomFieldMap,
	getGoodsListForFreeBox
}