<template>
	<personal-layout cur-menu="tradelist">
		<div class="right-box">
			<div class="flex-row back-box">
				<i class="el-icon-arrow-left back-btn" @click="goBack"></i>
				<div class="title">订单明细</div>
			</div>
			<div class="trade-status">{{statusText[tradeDetail.tradeStatus]}}</div>
			<div class="info-box" v-if="tradeDetail.logisticType == '1' ||tradeDetail.logisticType == '3' ||tradeDetail.logisticType == '0'">
				<div class="small-title">收件人信息</div>
				<div class="base-info">
					<div><span class="base-item">姓名：</span><span>{{tradeDetail.sendTo}}</span></div>
					<div><span class="base-item">电话：</span><span>{{$utils.regTel(tradeDetail.tel)}}</span></div>
					<div><span class="base-item">地址：</span><span v-if="tradeDetail.province">{{tradeDetail.province}}&nbsp;</span><span v-if="tradeDetail.city">{{tradeDetail.city}}&nbsp;</span><span v-if="tradeDetail.town">{{tradeDetail.town}}&nbsp;</span><span v-if="tradeDetail.district">{{tradeDetail.district}}&nbsp;</span><span v-if="tradeDetail.country != '中国'">{{tradeDetail.country}}&nbsp;</span><span v-if="tradeDetail.address">{{tradeDetail.address}}</span></span>
					</div>
				</div>
				<div class="address-editbtn" @click="openAddressEdit" v-if="isAllowEditTradeAddress && tradeDetail.tradeStatus != 0">修改地址</div>
			</div>
			<div class="info-box" v-else-if="tradeDetail.logisticType == '2'">
				<div class="small-title">自提信息</div>
				<div class="base-info">
					<div><span class="base-item">自提点：</span><span>{{(channelAnotherNameList && channelAnotherNameList.find((anotherNameItem) => saleChannel.channelId == anotherNameItem.channelId)&&channelAnotherNameList.find((anotherNameItem) => tradeDetail.shopId == anotherNameItem.channelId).channelAnotherName) || saleChannel.channelName}}</span></div>
					<div><span class="base-item">地 点：</span><span>{{saleChannel.fullAddress}}</span></div>
					<div><span class="base-item">自提人：</span><span>{{tradeDetail.sendTo}} &nbsp;
							{{$utils.regTel(tradeDetail.tel)}}</span></div>
				</div>
			</div>
			<div class="info-box">
				<div class="small-title">订单信息</div>
				<div class="base-info">
					<el-row :gutter="20">
						<el-col :span="tradeDetail.logisticType != '2' ? 12 : 24">
							<div><span class="base-item">交易时间：</span><span>{{tradeDetail.tradeTime}}</span></div>
							<div><span class="base-item">订单编号：</span><span>{{tradeDetail.tradeNo}}</span><span v-show="tradeDetail.tradeNo" class="copy-btn" v-clipboard:copy="tradeDetail.tradeNo" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">复制</span></div>
							<div class="flex-row">
								<div class="base-item">备注：</div>
								<div>{{tradeDetail.customerRemark}}</div>
							</div>
						</el-col>
						<el-col :span="12" v-show="tradeDetail.logisticType != '2'">
							<div><span class="base-item">物流公司：</span><span>{{tradeDetail.logisticName}}</span>
							</div>
							<div><span class="base-item">物流单号：</span><span>{{tradeDetail.logisticNumber||""}}</span><span v-show="tradeDetail.logisticNumber" class="copy-btn" v-clipboard:copy="tradeDetail.logisticNumber" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">复制</span></div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="info-box">
				<div class="small-title">商品清单</div>
				<goods-list :isShowSingleSpec="isShowSingleSpec" :goodslist="tradeDetail.tradeGoodsList"></goods-list>
			</div>
			<div class="info-box total-box">
				<el-form label-position="right" label-width="100px" class="flex-col">
					<el-form-item label="商品总数 :">
						<div class="money-text">x {{$utils.amountFixed(goodsTotalSellCount, 0)}}</div>
					</el-form-item>
					<el-form-item label="优　　惠 :">
						<div class="money-text">{{$currency.symbol}}{{$utils.amountFixed(tradeDetail.crmPloyTotalGoods + tradeDetail.crmPloyTotalTrade + tradeDetail.favourableTotal + tradeDetail.scoreDeductionTotal + tradeDetail.discountTotal + tradeDetail.couponTotal, 2)}}</div>
					</el-form-item>
					<el-form-item label="运    费 :">
						<div class="money-text">{{$currency.symbol}}{{$utils.amountFixed(tradeDetail.postageTotal, 2)}}
						</div>
					</el-form-item>
					<el-form-item label="订单应付 :">
						<div class="money-text total">
							<span v-show="tradeDetail.rcvTotal > 0 || tradeDetail.scoreTotal == 0">{{$currency.symbol}}{{$utils.amountFixed(tradeDetail.rcvTotal, 2)}}</span>
							<span v-show="tradeDetail.rcvTotal != 0 && tradeDetail.scoreTotal > 0"> + </span>
							<span v-show="tradeDetail.scoreTotal > 0">{{tradeDetail.scoreTotal}}</span>
							<span v-show="tradeDetail.scoreTotal > 0" class="score-text">积分</span>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<div class="handle-box flex-row" v-show="tradeDetail.tradeStatus == 1">
				<el-button type="info" class="handle-btn cancel-btn" @click="cancel">取消</el-button>
				<el-button type="danger" class="handle-btn" @click="repay">继续支付</el-button>
			</div>
			<div class="handle-box flex-col" v-show="tradeDetail.tradeStatus == 16">
				<div class="pay-begin-time">支付尾款时间：{{tradeDetail.tradeFinalPayTime}} 起</div>
				<div class="handle-box">
					<div class="handle-box flew-row" v-if="Date.parse(tradeDetail.tradeFinalPayTime) < new Date()">
						<el-button type="danger" class="handle-btn" @click="repay">支付尾款</el-button>
					</div>
					<div v-else class="handle-box flew-row">
						<el-button type="info" class="handle-btn size14" size="small">支付尾款</el-button>
					</div>
				</div>
			</div>
			<div class="handle-box flex-row" v-show="tradeDetail.tradeStatus == 18 || tradeDetail.tradeStatus == 0">
				<el-button class="size14" size="small" @click.stop="_tradeAgain">
					再来一单</el-button>
			</div>
		</div>
		<address-edit v-if="showEditDialog" :addressinfo="currentAddr" :isFromTradeDetail="true" title="修改地址" :isCreate="createEditDialog" :isShow="showEditDialog" @close="closeEditDialog" @success="editSuccess"></address-edit>
	</personal-layout>
</template>

<script>
	import personalLayout from '../../components/mine/PersonalLayout.vue'
	import goodsList from '../../components/trade/GoodsList.vue'
	import tradeUtils from '../../utils/api-utils/api-trade.js';
	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import {
		getParam
	} from '../../utils/api-utils/api-params.js';
	import {
		getPresaleInfoByGoodsId
	} from '../../utils/api-utils/api-activity.js';
	import addressEdit from '../../components/trade/AddressEdit.vue'
	export default {
		name: 'tradedetail',
		components: {
			personalLayout,
			goodsList,
			addressEdit
		},
		data() {
			return {
				isShowSingleSpec: true, // 是否展示单规格
				tradeNo: "", // 订单编号
				goodsTotalSellCount: 0,
				tradeDetail: {},
				saleChannel: {},
				statusText: ['已取消', '待结算', '待审核', '待发货', '已发货', '已完成', '', '', '', '退款中', '退款完成', '', '', '', '退货中',
					'退货完成', '', '', '', '待商家确认收款'
				],
				currentAddr: {},
				createEditDialog: false,
				showEditDialog: false,
			}
		},
		mounted() {
			// 获取配置参数
			this._getParams();

			// 获取订单详情
			this._getTradeDetail();
		},
		methods: {
			/**
			 * 获取订单详情
			 */
			async _getTradeDetail() {
				let tradeDetail = await tradeUtils.getJmallTradeDetail(this.tradeNo);

				let goodsList = tradeDetail.tradeGoodsList;
				tradeDetail.tradeStatus = parseInt(tradeDetail.tradeStatus);

				let sellCountTotal = 0;
				for (let i = 0; i < goodsList.length; i++) {
					goodsList[i].goodsSpec = goodsList[i].specName;
					goodsList[i].priceDetail = goodsList[i].sellPrice;
					goodsList[i].score = goodsList[i].sellScore;
					goodsList[i].coverPic = goodsList[i].picUrl;
					sellCountTotal += goodsList[i].sellCount / 1;
				}
				if (tradeDetail.logisticType == "2") {
					//获取店铺地址信息
					this._getSaleChannel(tradeDetail.shopId);
				}

				// 地址信息
				this.currentAddr = {
					name: tradeDetail.sendTo,
					tel: tradeDetail.tel,
					province: tradeDetail.province,
					city: tradeDetail.city,
					district: tradeDetail.district,
					town: tradeDetail.town,
					address: tradeDetail.address,
					country: tradeDetail.country,
				}

				// 计算总消耗积分
				tradeDetail.scoreTotal = tradeDetail.scoreTotal + tradeDetail.deductionScore;

				// 商品总数
				this.goodsTotalSellCount = sellCountTotal;
				this.tradeDetail = tradeDetail;
			},

			/**
			 * 获取配置
			 */
			async _getParams() {
				// 路由传参
				this.$route.meta.isEdit = false;
				this.tradeNo = this.$route.query.no;

				// 配置参数
				let paramList = await getParam("channelAnotherNameList,isShowSingleSpec,isAllowEditTradeAddress");
				this.channelAnotherNameList = paramList.channelAnotherNameList && JSON.parse(paramList.channelAnotherNameList) || [];
				this.isShowSingleSpec = paramList.isShowSingleSpec == "1";
				this.isAllowEditTradeAddress = paramList.isAllowEditTradeAddress == "1";
			},

			/**
			 * 再来一单
			 */
			async _tradeAgain() {
				//展示加载遮罩层
				let loadingLayer = this.$loading({
					lock: true,
					background: 'rgba(255, 255, 255, 0.2)'
				});

				// 前置数据
				let preList = this.tradeDetail.tradeGoodsList.map(t => {
					return {
						goodsId: t.goodsId,
						specId: t.specId,
						unit: t.unit,
						actId: t.actId,
						actType: t.actType,
						goodsCount: t.sellCount,
						customerId: this.customerId,
						packageGoodsId: t.packageGoodsId,
						packageSpecId: t.packageSpecId
					}
				});

				// 获取预售信息
				let presaleSkuInfoList = await getPresaleInfoByGoodsId(preList);
				if (presaleSkuInfoList.length > 0 && presaleSkuInfoList[0].endTime > Date.now()) {
					let goodsId = preList[0].goodsId;
					let encode = window.btoa(window.encodeURIComponent(goodsId));
					loadingLayer.close(); //关闭加载遮罩层
					this.$router.push({
						name: 'goodsdetail',
						query: {
							id: encode
						}
					});
					return;
				}

				// 加入购物车
				let cartIds = await goodsUtils.addTempShoppingCartBatch(preList);

				//关闭加载遮罩层
				loadingLayer.close();

				if (cartIds == 0) {
					this.$message.error("加购失败");
					return;
				}

				this.$router.push({
					name: 'shopcart',
					params: {
						cartFrom: '再来一单'
					}
				});
			},

			/**
			 * 获取门店信息
			 */
			async _getSaleChannel(channelId) {
				if (!channelId) {
					return;
				}

				this.$request.jackyunPost({
					method: "salechannel.detail",
					salechannelId: channelId
				}, data => {
					if (data.code === 200) {
						let channelInfo = data.result.data;
						let provinceName = channelInfo.provinceName ? channelInfo.provinceName : "";
						let cityName = channelInfo.cityName ? channelInfo.cityName : "";
						let townName = channelInfo.townName ? channelInfo.townName : "";
						let streetName = channelInfo.streetName ? channelInfo.streetName : "";
						let officeAddress = channelInfo.officeAddress ? channelInfo.officeAddress : "";
						let address = provinceName + cityName + townName + streetName + officeAddress;
						channelInfo.fullAddress = address;
						this.saleChannel = channelInfo;
					}
				});
			},

			onCopySuccess() {
				this.$message({
					message: "复制成功",
					type: 'success'
				});
			},

			onCopyError(e) {
				this.$message.error({
					message: "复制失败"
				});
			},

			/**
			 * 取消订单
			 */
			cancel() {
				let that = this;
				that.$confirm("确定取消订单吗？", {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(async () => {
					let result = await tradeUtils.cancelTrade(that.tradeDetail.tradeId);
					if (result) {
						that.tradeDetail.tradeStatus = 0;
						that.$message({
							type: 'success',
							message: '取消成功'
						});
						that.$route.meta.isEdit = true;
						that.$router.back(-1);
					}
				});
			},

			/**
			 * 继续支付
			 */
			async repay() {
				// 继续支付订单号检测，如果和PC冲突则会重新生成订单编号
				let tradeNo = await tradeUtils.getContinuePayTradeNo(this.tradeDetail.tradeNo);

				this.$router.push({
					name: 'pay',
					params: {
						tradeNo: tradeNo
					}
				});
			},

			goBack() {
				this.$router.back(-1);
			},

			closeEditDialog() {
				this.createEditDialog = false;
				this.showEditDialog = false;
			},

			async editSuccess(addressInfo) {
				// 获取规格
				let specIds = this.tradeDetail.tradeGoodsList.map(item => item.specId);

				// 调用限购检测接口，获取检测结果（限购商品+有效商品）
				let res = addressInfo && await tradeUtils.checkRestrictedGoods(addressInfo.province, addressInfo.city, addressInfo.district, specIds);

				// 如果校验不通过进行提示
				if (addressInfo && res.restrictedInfoList && res.restrictedInfoList.length > 0) {
					return this.$message.error(res.restrictedInfoList[0].limitSalesReminder);
				}
				
				// 更新订单详情数据
				this.currentAddr = {
					name: addressInfo.sendTo,
					tel: addressInfo.tel,
					province: addressInfo.province,
					city: addressInfo.city,
					district: addressInfo.district,
					town: addressInfo.town,
					address: addressInfo.address,
					country: addressInfo.country,
				}

				// 修改订单
				let result = await tradeUtils.updateTradeAddressInfo(this.tradeDetail.tradeId, addressInfo);

				// 添加日志
				this.addTradeAddressLog(addressInfo, this.currentAddr);

				// 更新订单详情数据
				this.tradeDetail.sendTo = addressInfo.sendTo;
				this.tradeDetail.tel = addressInfo.tel;
				this.tradeDetail.province = addressInfo.province;
				this.tradeDetail.city = addressInfo.city;
				this.tradeDetail.district = addressInfo.district;
				this.tradeDetail.address = addressInfo.address;
				this.tradeDetail.country = addressInfo.country;

				this.$message.success("修改成功")
				this.closeEditDialog();
			},

			/**
			 * 展示地址修改弹窗
			 */
			openAddressEdit() {
				this.showEditDialog = true;
				this.createEditDialog = true;
			},

			/**
			 * 添加订单变更日志
			 * */
			addTradeAddressLog(nowAddressInfo, oldAddressInfo) {
				// 日志内容
				let goodsEditLogInfoList = [];

				// 收件人变更日志
				if (nowAddressInfo.sendTo != oldAddressInfo.sendTo) {
					goodsEditLogInfoList.push(`收件人由“${oldAddressInfo.sendTo}”修改“${nowAddressInfo.sendTo}”`);
				}

				// 收件人手机号变更日志
				if (nowAddressInfo.tel != oldAddressInfo.tel) {
					goodsEditLogInfoList.push(`手机由“${oldAddressInfo.tel}”修改“${nowAddressInfo.tel}”`);
				}

				// 收件地址变更日志
				let nowAddress = nowAddressInfo.province + nowAddressInfo.city + nowAddressInfo.district + nowAddressInfo.address;
				let originalAddress = oldAddressInfo.province + oldAddressInfo.city + oldAddressInfo.district + oldAddressInfo.address;
				if (nowAddress != originalAddress) {
					goodsEditLogInfoList.push(`收货地址由“${originalAddress}”修改“${nowAddress}”`);
				}

				// 没有变更则不需要执行
				if (goodsEditLogInfoList.length <= 0) {
					return;
				}

				tradeUtils.addTradeLog(this.tradeDetail.tradeId, '【买家自助修改地址】' + goodsEditLogInfoList.join('；'))
			}

		}
	}
</script>

<style scoped="scoped">
	.right-box {
		background-color: #fff;
		padding: 10px 30px 30px 30px !important;
		font-size: 18px;
	}

	.back-box {
		align-items: center;
		margin-left: -20px;
		height: 80px;
	}

	.back-btn {
		font-size: 42px;
		margin-right: 15px;
	}

	.trade-status {
		height: 40px;
		background-color: #000;
		color: #fff;
		font-weight: bold;
		font-size: 22px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 24px 40px;
	}

	.info-box {
		margin: 20px;
		border-bottom: 1px solid #dedede;
		font-size: 16px;
		min-height: 130px;
		position: relative;
	}

	.address-editbtn {
		position: absolute;
		right: 10px;
		bottom: 18px;
		text-align: center;
		font-size: 14px;
		border-radius: 3px;
		width: 60px;
		height: 25px;
		line-height: 25px;
		background-color: black;
		color: white;
		padding: 4px 10px;
		cursor: pointer;
	}

	.small-title {
		font-size: 16px;
		font-weight: 800;
	}

	.base-info {
		margin: 10px;
		line-height: 30px;
	}

	.base-item {
		color: #999;
		width: 80px;
		margin-right: 10px;
		display: inline-block;
		text-align: right;
		flex-shrink: 0;
	}

	.copy-btn {
		padding: 2px 10px;
		font-size: 13px;
		background-color: #F2F2F2;
		margin-left: 10px;
		color: #666;
	}

	.total-box {
		display: flex;
		justify-content: flex-end;
		border: none !important;
	}

	.total-box /deep/.el-form-item__label,
	.total-box /deep/.el-form-item__content {
		font-size: 17px !important;
		color: #999 !important;
	}

	.total-box /deep/.el-form-item {
		margin-bottom: 0px !important;
	}

	.total {
		color: #000;
		font-size: 18px;
		font-weight: bold;
	}

	.money-text {
		color: #333;
		text-align: right;
		margin-left: 50px;
	}

	.score-text {
		position: relative;
		bottom: 1px;
		left: 5px;
		font-size: 15px;
	}

	.handle-box {
		margin-bottom: 60px;
		display: flex;
		justify-content: flex-end;
	}

	.cancel-btn {
		color: #333;
		background-color: #F2F2F2;
	}

	.handle-btn {
		width: 140px;
		height: 60px;
		font-size: 16px;
		border: none;
		margin-left: 25px !important;
	}

	.pay-begin-time {
		color: #F37A45;
		margin-bottom: 20rpx;
		text-align: right;
	}
</style>