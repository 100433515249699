<template>
	<div class="container trade-container">
		<div id='mainContent' class="content-box">
			<div class="info-box">
				<div class="flex-row back-box">
					<i class="el-icon-arrow-left back-btn" @click="goBack"></i>
					<div class="flex-between" style="width: 100%;">
						<div class="title">配送地址</div>
						<div class="title" style="cursor: pointer;" @click="openAddress"><i class="el-icon-location" />使用其它地址</div>
					</div>

				</div>
				<address-list :addresslist="addressShowList" :selectid="addrSelectedId" @success="editAddrSuccess" @change="AddressChange" :isFromTradeConfirmWithBondGoods='tradeIsHaveBondedGoods'></address-list>
			</div>
			<div class="info-box">
				<div class="title">商品信息</div>
				<goods-list :isShowSingleSpec="isShowSingleSpec" :goodslist="tradeGoods" :canToDetail="false"></goods-list>
			</div>
			<div class="info-box">
				<div class="row-box flex-row ">
					<div>物流选择</div>
					<el-select :popper-append-to-body="false" v-model="selectedlogisticName" placeholder="请选择" @change="logisticChange">
						<el-option v-for="item in logisticList" :key="item.id" :value="item.showname">
						</el-option>
					</el-select>
				</div>
				<div class="row-box flex-row  date_picker mt10" v-if="isOpenExpectedDeliveryTime">
					<!-- <div>期望送达时间</div>
					<el-date-picker style="width: 217px;" format="yyyy-MM-dd HH:mm:ss" popper-class="date_picker"
						value-format="yyyy-MM-dd HH:mm:ss" v-model="expectReciveTime" type="datetime" placeholder="请选择"
						:picker-options="pickerExpectReciveOptions">
					</el-date-picker> -->
					<div>期望发货时间</div>
					<el-date-picker style="width: 217px;" format="yyyy-MM-dd" popper-class="date_picker" value-format="yyyy-MM-dd" v-model="expectedDeliveryTime" type="date" placeholder="请选择" :picker-options="pickerExpectReciveOptions">
					</el-date-picker>
					<!-- <el-select :popper-append-to-body="false" v-model="selectedlogisticName" placeholder="请选择"
						@change="logisticChange">
						<el-option v-for="item in logisticList" :key="item.id" :value="item.showname">
						</el-option>
					</el-select> -->
				</div>
				<div class="row-box flex-row mt10" v-if="isOpenCollectBuyerPhone">
					<div>订购人电话</div>
					<el-input type="text" style="width: 217px;" v-model="changeTel" placeholder="请填写订购人手机号（选填）"></el-input>
				</div>
				<div v-if="isOpenOrderSentAttachments" class="row-box row-remark flex-row">
					<div>{{orderSentAttachmentsRemind}}</div>
					<el-upload accept=".jpg,.jpeg,.png" :limit="3" :action="config.uploadFileUrl" list-type="picture-card" :data="{
						userName: userName,
						fileClass: 'fileImg',
					  }" :on-preview="_handlePictureCardPreview" :on-exceed="_handlePictureCardExceed" :on-success="_handleSuccess">
						<i class="el-icon-plus"></i>
					</el-upload>
				</div>
				<div v-if="isShowRequiredField" class="row-box row-remark flex-row">
					<div style="width: 170px;">{{requiredFieldShowName}}</div>
					<el-input class="trade-remark" type="textarea" maxlength="200" v-model="requiredFieldText" show-word-limit></el-input>
				</div>
				<div class="row-box row-remark flex-row">
					<div>订单备注</div>
					<el-input class="trade-remark" type="textarea" maxlength="500" v-model="customerRemark" show-word-limit></el-input>
				</div>
			</div>

			<div class="info-box total-box" style="position: relative;">
				<div class="flex-row">
					<div class="scorededuction-explain" v-if="isShowScoreDeduction&&isAllowedUseScore">
						<img class="scorededuction-img" @click="_chooseScoreDeduction" v-if="isChooseScoreDeduction" src="../../assets/images/icon-checkedred.svg"></img>
						<img class="scorededuction-img" @click="_chooseScoreDeduction" v-else src="../../assets/images/icon-uncheckedred.svg"></img>
						可用{{deductionNeedScoreForShow}}积分抵扣{{scoreDeductionAmountForShow}}元
					</div>
				</div>
				<el-form label-position="right" label-width="100px" class="flex-col">
					<el-form-item label="商品合计 :">
						<div class="money-text">
							<span v-show="goodsTotalAmount > 0 || goodsTotalScore == 0">{{$currency.symbol}}{{$utils.amountFixed(goodsTotalAmount, 2)}}</span>
							<span v-show="goodsTotalAmount != 0 && goodsTotalScore > 0"> + </span>
							<span v-show="goodsTotalScore > 0">{{goodsTotalScore}}</span>
							<span v-show="goodsTotalScore > 0" class="score-text size14">积分</span>
						</div>
					</el-form-item>
					<el-form-item label="运费 :">
						<div class="money-text  ">
							{{$currency.symbol}}{{$utils.amountFixed(freight, 2)}}
						</div>
						<div class="freepostage" v-if="showFreepostageDifference "> 再买{{freepostagedifference}}元可享受包邮权益</div>
					</el-form-item>
					<el-form-item label="促销优惠 :">
						<div class="money-text">
							<span>-{{$currency.symbol}}{{$utils.amountFixed(crmPloyDeductionAmount, 2)}}</span>
						</div>
					</el-form-item>
					<el-form-item label="积分抵扣 :" v-if="isShowScoreDeduction&&isAllowedUseScore">
						<div class="money-text scorededuction-detail">
							-{{$currency.symbol}}{{scoreDeductionAmount}}
						</div>
					</el-form-item>

					<el-form-item v-if="isPresaleTrade" label="合计 :">
						<div class="money-text">
							<span v-show="rcvTotal > 0 || goodsTotalScore == 0">{{$currency.symbol}}{{$utils.amountFixed(rcvTotal, 2)}}</span>
							<span v-show="goodsTotalScore > 0"> + </span>
							<span v-show="goodsTotalScore > 0">{{goodsTotalScore}}</span>
							<span v-show="goodsTotalScore > 0" class="score-text size16">积分</span>
						</div>
					</el-form-item>
					<el-form-item v-if="isPresaleTrade" label="定金比例 :">
						<div class="money-text">{{depositRatio}}%</div>
					</el-form-item>
				</el-form>
			</div>

			<!-- 底部提交订单 -->
			<div class="info-box submit-box">
				<!-- crm促销策略匹配结果 -->
				<crm-ploy-info class='crm-ploy-info' v-if='isMatchedCrmPloy' :matchResult="crmPloyMatchResult"></crm-ploy-info>

				<div class="submit-box-inner">
					<div class="money-text total">
						<span class="rcv-title-txt">应付：</span>
						<span v-show="showRcvTotal > 0 || tradeScoreTotal == 0">{{$currency.symbol}}{{$utils.amountFixed(showRcvTotal, 2)}}</span>
						<span v-show="tradeScoreTotal > 0 && showRcvTotal > 0"> + </span>
						<span v-show="tradeScoreTotal > 0">{{tradeScoreTotal}}</span>
						<span v-show="tradeScoreTotal > 0" class="score-text size16">积分</span>
					</div>
					<el-button type="danger" class="pay-btn" @click="toPay">提交订单</el-button>
				</div>
			</div>
		</div>
		<selectAddr v-if="addressSelectVisible" :key="addressSelectkey" :addressListVisible="addressSelectVisible" :selectAddressid="addrSelectedId" @val-change="AddressChangeInfo" @cancel="closeSelectAddr" :isFromTradeConfirmWithBondGoods='tradeIsHaveBondedGoods'></selectAddr>
		<!-- 货品检测弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowCheckGoodsDialog" :goodsList="cannotOrderGoodsList" :isNoValidGoods="isNoStockAll" @confirm="filterNoStockGoods" @cancel="cancelCheckModal">
		</check-goods-dialog>
		<!-- 货品检测弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowBlockCheckGoodsDialog" :goodsList="cannotOrderGoodsList" :isNeedTitle="true" :isNoValidGoods="isNoStockAll" :isBlockUpGoodsCheck="true" :noValidGoodsTip="`商品已下架，请重新下单`" :hasValidGoodsTip="`以下商品已下架`" :confirmText="`移除下架商品`" :cancelText="`回到首页`" @confirm="filterBlockUpGoods" @cancel="cancelCheckModal">
		</check-goods-dialog>
		<!-- 多单位货品检测弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowMultiUnitStockCheckModal" :goodsList="baseUnitOverOrderGoodsList" :isMultiUnitOverStock="true" hasValidGoodsTip="库存不足,请重新选货" @cancel="multiUnitOverStockCancel">
		</check-goods-dialog>
		<!-- 活动货品检测弹窗 -->
		<check-goods-dialog :isNeedTitle='true' :isShowDialog.sync="isShowActivityGoodsStockCheckModal" :goodsList="activityGoodsOverOrderList" :isNoValidGoods="isNoStockAll" :isActivityOverStock="true" noValidGoodsTip="本订单不存在可购买的商品，请重新下单" hasValidGoodsTip="以下商品超出限购数量" confirmText="移除超量商品" @confirm="filterNoStockGoods" @cancel="cancelCheckModal">
		</check-goods-dialog>
		<!-- 区域限购检测弹窗 -->
		<check-goods-dialog :isNeedTitle='true' :isShowDialog.sync="isShowRestrictedCheckModal" :goodsList="restrictedGoodsList" :restrictedInfoList="restrictedInfoList" :isNoValidGoods="isRestrictedAllGoods" :isRestrictedCheck="true" :noValidGoodsTip="noValidGoodsTipOfRestrictedCheck" :hasValidGoodsTip="restrictedTitleText" confirmText="移除限购商品" @confirm="filterRestrictedGoods" @cancel="cancelCheckModal">
		</check-goods-dialog>

		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>

<script>
	import vHeader from '../../components/common/Header.vue'
	import goodsList from '../../components/trade/GoodsList.vue'
	import addressList from '../../components/trade/AddressList.vue'
	import selectAddr from '../../components/trade/TradeConfirmAddressList.vue';
	import checkGoodsDialog from '../../components/trade/CheckGoodsDialog.vue';
	import crmPloyInfo from '../../components/goods/CrmPloyInfo.vue';

	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import tradeUtils from '../../utils/api-utils/api-trade.js'
	import commonUtil from '../../utils/common-utlis.js';
	import addressUtils from '../../utils/api-utils/api-address.js';
	import {
		getParam
	} from '../../utils/api-utils/api-params.js';
	import {
		getCustomerInfo
	} from '../../utils/api-utils/api-customer.js';
	import {
		calcMarketingGoodsTotalAmount,
		getPresaleInfoByGoodsId
	} from '../../utils/api-utils/api-activity.js';
	import {
		config
	} from '../../config.js';
	import {
		getShoppingCartList
	} from '../../utils/api-utils/api-goods-shoppingcart.js';
	import {
		getChannelInfo
	} from '../../utils/api-utils/api-channel.js';
	import {
		matchCrmPromotionPloy
	} from '../../utils/api-utils/api-goods-price.js';

	export default {
		name: 'tradeconfirm',
		components: {
			vHeader,
			goodsList,
			addressList,
			selectAddr,
			"check-goods-dialog": checkGoodsDialog,
			"crm-ploy-info": crmPloyInfo,
		},
		computed: {
			pickerExpectReciveOptions() {
				//选择的日期
				let curDate = this.$moment(this.expectReciveTime || this.minTime).format("YYYY-MM-DD");
				//最小日期
				let tempData = new Date();
				let deliveryDelayedDays = (this.tradeGoods.find(item => item.stock <= 0) ? (this.overStockGoodsDelayedDays || this.expectedDeliveryDelayedDays) : this.expectedDeliveryDelayedDays) || "0";
				let tempMinDate = new Date(tempData.setDate(tempData.getDate() + deliveryDelayedDays / 1));
				let minDate = this.$moment(tempMinDate).format("YYYY-MM-DD");
				//前推一天，防止当天日期无法选择
				let preTime = this.minTime - 24 * 3600000 + deliveryDelayedDays / 1 * 24 * 3600000;
				// 如果选择的日期为最小日期，则设置为最小日期的时分秒
				let str = "";
				if (curDate == minDate) {
					str = this.$moment(this.minTime).format("HH:mm:ss");
				} else {
					str = "00:00:00"
				}
				return {
					disabledDate: (time) => {
						return time.getTime() < preTime || time.getTime() < minDate || time.getTime() > this.maxTime;
					},
					selectableRange: str + " - 23:59:59"
				}
			},
			userName() {
				return localStorage.getItem('SubMemberName') || localStorage.getItem('JackyunUserName')
			}
		},
		data() {
			return {
				pickerOptions: {
					disabledDate: this.checkTime
				},
				minTime: Date.now(),
				tradeFrom: '', //订单来源
				addressList: [], //地址列表
				addressShowList: [], // 展示列表
				addrSelectedId: '', //选中的地址id
				address: {}, //下单地址
				tradeGoods: [], //商品列表
				isOpenCityBuyLimit: false, //区域限购
				buyLimitCities: '', //限购地区
				logisticList: [], //物流列表
				bindLogisticId: '', //物流id
				selectedlogisticName: '', //物流名称
				logisticType: 1, //默认普通快递
				goodsTotalScore: 0, //商品总积分
				goodsTotalAmount: 0, //商品总金额
				tradeScoreTotal: 0, //订单总积分
				weightTotal: 0, //重量
				freight: 0.00, //运费
				tradeTotal: 0.00, //除掉运费的总和
				rcvTotal: 0.00, //实际支付	
				showRcvTotal: 0, // 用于展示的应收金额（预售情况即为首款金额）
				isPostageOver: false, //是否已完成运费计算
				isClickPay: false, //防止重复提交
				channelId: '', //渠道信息
				customerRemark: '', //订单备注
				requiredFieldText: '', //必填字段内容
				isOpenZeroStockBuy: false, // 0库存下单
				isShowSingleSpec: true, //展示单规格
				addressSelectVisible: false,
				addressSelectkey: true,
				warehouseId: '',
				blockUpGoodsRenderList: [], // 下架商品列表
				canOrderGoodsList: [], // 限购商品列表
				cannotOrderGoodsList: [], // 剩余可购规格Id
				isNoStockAll: false, // 是否无可购商品
				isShowCheckGoodsDialog: false, // 是否展示库存检测弹窗，存在库存不足的货品就展示
				isShowBlockCheckGoodsDialog: false, // 是否展示下架商品检测弹窗，存在下架货品就展示
				channelInfo: {},
				currencyCode: '',
				currencyName: '',
				isOpenDistributorTradeType: false, // 是否开启分销商代销发货
				isOpenOrderSentAttachments: false,
				isShowRequiredField: false,
				requiredFieldToOms: "",
				requiredFieldShowName: "",
				orderSentAttachmentsRemind: "",
				defaultAccountId: "", // 默认结算账户Id
				defaultAccountName: "", // 默认结算账户名称
				depositRatio: 100, // 预售比例
				isPresaleTrade: false, // 是否为预售订单
				deliveryDate: '',
				expectReciveTime: '',
				changeTel: '',
				isShowCollectChangerInfo: false,
				couponExchangeDelayedSendDays: 0,
				expectedDeliveryTime: '', // 期望发货时间
				freepostagedifference: undefined, //包邮差额
				isOpenExpectedDeliveryTime: false, // 是否开启期望发货时间
				isOpenCollectBuyerPhone: false, // 订购人电话
				isOpenExpectReciveTime: false, // 开启期望送达时间
				freePostageType: 'rcvTotal', // 包邮类型（rcvTotal：实收金额，goodsTotal：商品金额，默认：rcvTotal）
				showFreepostageDifference: false, //是否显示包邮
				expectedDeliveryDelayedDays: "", //期望发货推迟时间
				isShowMultiUnitStockCheckModal: false, //展示多单位库存校验弹窗
				baseUnitOverOrderGoodsList: [], //展示多单位库存不足货品
				config: config, //获取url参数
				attachList: [], // 附件urlList
				dialogImageUrl: '',
				dialogVisible: false,
				tradeAmountLimit_isEnabled: false, // 是否开启下单金额限制
				tradeAmountLimit_limitType: "rcvTotal", // 下单金额限制类型（rcvTotal：实收金额，goodsTotal：商品金额，默认：rcvTotal）
				tradeAmountLimit_limitAmount: 0, // 下单金额限制金额
				scoreDeductionRules: {}, // 积分抵扣规则
				deductionNeedScore: 0, // 抵扣所需积分
				scoreDeductionAmount: 0, // 积分抵扣金额
				isChooseScoreDeduction: true, // 是否选择积分抵扣
				isShowScoreDeduction: false, // 是否展示积分抵扣
				isNeedDeliveryIdInfoWithoutBondGoods: true,
				tradeIsHaveBondedGoods: false,
				isMatchedCrmPloy: false, // 是否匹配到CRM策略
				crmPloyMatchResult: {}, // crm促销策略匹配结果
				crmPloyDeductionAmount: 0, // crm促销策略抵扣金额
				isAllowedUseCoupon: true, // 是否允许使用卡券（默认允许）
				isAllowedUseScore: true, // 是否允许使用积分（默认允许）
				isMatchedCrmPloyForShowModal: false, // 是否匹配到CRM策略（用作展示提示窗）
				crmPromotionPloyCanBeCombinationCoupon: false, // CRM营销策略是否允许和卡券叠加
				crmPromotionPloyCanBeCombinationScore: false, // CRM营销策略是否允许和积分抵扣叠加
				isShowActivityGoodsStockCheckModal: false, // 弹窗展示
				activityGoodsOverOrderList: [], // 弹窗展示
				isShowRestrictedCheckModal: false, // 是否展示限购弹窗
				restrictedTitleText: '', // 限购提示
				isRestrictedAllGoods: false, // 是否不存在可购商品
				restrictedGoodsList: [], // 限购商品列表
				restrictedInfoList: [], // 限购信息列表
				noValidGoodsTipOfRestrictedCheck: '', // 限购校验提示
			}
		},
		// beforeRouteEnter(to, from, next) {
		// 	//判断是否是来自下单页面
		// 	if (['goodsdetail', 'shopcart', 'timedsalegoodsdetail', 'tradeconfirm'].includes(from.name)) {
		// 		next();
		// 	} else {
		// 		next({
		// 			name: 'goodslist'
		// 		});
		// 	}
		// },
		watch: {
			/**
			 * 监听促销策略匹配结果
			 * @param {Object} newValue
			 * @param {Object} oldValue
			 */
			crmPloyMatchResult(newValue, oldValue) {
				// 计算容器高度
				let resultLength = 0;
				if (newValue.promotionResult && newValue.promotionResult.length > 0) {
					resultLength = newValue.promotionResult.length;
					resultLength = resultLength > 3 ? 3 : resultLength;
				}
				let paddingBottom = (resultLength > 0 ? (resultLength * 24 + 20) : 0) + 75;
				document.getElementById('mainContent').style.paddingBottom = paddingBottom + "px";
			},
		},
		async mounted() {
			// 页面数据初始化
			this._initData(this.$route.params);

			// 页面数据初始化
			await this._getCustomerInfo();

			// 获取配置信息
			await this._getParam();

			// 获取购物车列表
			await this._loadShoppingCartList();

			// 加载地址信息
			await this._loadAddress(this.$route.params.addressId);

			// 获取预售商品信息
			await this._getPresaleGoodsInfo();

			// 计算价格
			this._refreshAmountInfo();

			// // 计算订单积分抵扣情况
			// this._calcExpectDeductionScore();

			// // 计算货品价格和积分
			// this._calcTradeTotal();

			// // 计算邮资
			// this._calcFreight();
		},
		methods: {
			/**
			 * 页面数据初始化
			 */
			_initData(params) {
				// 获取购物车Id
				this.cartIds = params.cartIds || '';
				// 获取订单来源
				this.tradeFrom = params.trade_from;

				// 参数检测，参数无效则返回上一页
				if (!this.cartIds) {
					this.goBack();
					this.$message("未检测到商品信息");
				}
			},

			/**
			 * 页面数据初始化
			 */
			async _getCustomerInfo() {
				// 获取客户信息
				let customerInfo = await getCustomerInfo()
				this.customerInfo = customerInfo;
				this.customerId = customerInfo.customerId;
				this.nickname = customerInfo.nickname || "";
			},

			//读取配置参数
			async _getParam() {
				let paramList = await getParam(
					"isCollectChangerInfo,couponExchangeDelayedSendDays,isOpenExpectReciveTime,bindSaleChannelId,isOpenCityBuyLimit,buyLimitCities,bindLogisticList,isOpenZeroStockBuy,isShowSingleSpec,currencyCode,currencyName,isOpenDistributorTradeType,defaultAccountId,defaultAccountName,freePostageType,isOpenCollectBuyerPhone,isOpenExpectedDeliveryTime,expectedDeliveryDelayedDays,overStockGoodsDelayedDays,requiredFieldShowName,requiredFieldToOms,isShowRequiredField,isOpenOrderSentAttachments,OrderSentAttachmentsRemind,isMustIDCard,tradeAmountLimit_isEnabled,tradeAmountLimit_limitType,tradeAmountLimit_limitAmount,isOpenScoreDeduction,rulesScoreDeduction,isNeedDeliveryIdInfoWithoutBondGoods,labelsInExtraPage,crmPromotionPloy_canBeCombination_coupon,crmPromotionPloy_canBeCombination_score,restricted_explainText"
				)
				this.channelId = paramList.bindSaleChannelId || '';
				// 0库存下单
				this.isOpenZeroStockBuy = paramList.isOpenZeroStockBuy == "1";
				// 是否开启区域限购
				if (paramList.isOpenCityBuyLimit && paramList.isOpenCityBuyLimit == "1") {
					this.isOpenCityBuyLimit = true;
					this.buyLimitCities = paramList.buyLimitCities ? JSON.parse(paramList.buyLimitCities) :
						'';
				}
				this.isShowSingleSpec = paramList.isShowSingleSpec == "0" ? false : true;
				this.currencyCode = paramList.currencyCode || 'CNY';
				this.currencyName = paramList.currencyName || '人民币';
				this.isOpenDistributorTradeType = paramList.isOpenDistributorTradeType == "1";
				this.isOpenOrderSentAttachments = paramList.isOpenOrderSentAttachments == "1"; // 是否开启附件必传
				this.isShowRequiredField = paramList.isShowRequiredField == "1"; // 是否开启必填字段设置项
				this.orderSentAttachmentsRemind = paramList.OrderSentAttachmentsRemind; // 附件字段
				this.requiredFieldToOms = paramList.requiredFieldToOms; // 传至oms自定义字段
				this.requiredFieldShowName = paramList.requiredFieldShowName; // 自定义字段展示名称
				this.isMustIDCard = paramList.isMustIDCard == "1"; // 证件必传
				this.isOpenScoreDeduction = paramList.isOpenScoreDeduction == "1"; // 是否开启积分抵扣
				this.scoreDeductionRules = JSON.parse(paramList.rulesScoreDeduction || "{}"); // 积分抵扣规则
				this.labelsInExtraPage = paramList.labelsInExtraPage || ""; // 标签展示配置
				this.restricted_explainText = paramList.restricted_explainText || "以下商品为区域限购商品，不支持在本收货地址下单"; // 限购检测提示文字

				// 获取可选物流列表
				if (paramList.bindLogisticList) {
					let bindLogisticList = JSON.parse(paramList.bindLogisticList);
					if (bindLogisticList && bindLogisticList.length > 0) {
						let logisticList = [];
						let logisticWays = [];
						for (let item of bindLogisticList) {
							let logistic = {};
							logistic.name = item.split(',')[0];
							logistic.id = item.split(',')[1];
							logistic.showname = item.split(',').length > 1 ? item.split(',')[2] : item
								.split(',')[0];
							logisticList.push(logistic);
						}
						this.logisticList = logisticList;
						this.bindLogisticId = logisticList[0].id;
						this.selectedlogisticName = logisticList[0].showname;
					}
				}

				// 是否开启收集发货时间以及发货人手机号
				this.isShowCollectChangerInfo = paramList.isCollectChangerInfo == "1";
				// 是否开启订购人电话
				this.isOpenCollectBuyerPhone = paramList.isOpenCollectBuyerPhone == "1";
				// 是否开启期望发货时间
				this.isOpenExpectedDeliveryTime = paramList.isOpenExpectedDeliveryTime == "1";
				// 期望发货延迟时间
				this.expectedDeliveryDelayedDays = paramList.expectedDeliveryDelayedDays;
				// 缺货商品发货延迟时间
				this.overStockGoodsDelayedDays = paramList.overStockGoodsDelayedDays; // 存在缺货商品时延迟时间

				this.isOpenExpectReciveTime = paramList.isOpenExpectReciveTime == "1";
				// 默认结算账户信息
				this.defaultAccountId = paramList.defaultAccountId;
				this.defaultAccountName = paramList.defaultAccountName;
				// 包邮金额类型
				this.freePostageType = paramList.freePostageType == "goodsTotal" ? "goodsTotal" : "rcvTotal";

				// 是否开启下单金额限制
				this.tradeAmountLimit_isEnabled = paramList.tradeAmountLimit_isEnabled == "1";
				// 下单金额限制类型（rcvTotal：实收金额，goodsTotal：商品金额，默认：rcvTotal）
				this.tradeAmountLimit_limitType = paramList.tradeAmountLimit_limitType || "rcvTotal";
				// 下单金额限制金额
				this.tradeAmountLimit_limitAmount = parseFloat(paramList.tradeAmountLimit_limitAmount || "0");
				// 包含保税商品校验证件信息必传
				this.isNeedDeliveryIdInfoWithoutBondGoods = paramList.isNeedDeliveryIdInfoWithoutBondGoods == "1";
				// CRM营销策略是否允许和卡券叠加
				this.crmPromotionPloyCanBeCombinationCoupon = paramList.crmPromotionPloy_canBeCombination_coupon == "1";
				// CRM营销策略是否允许和积分抵扣叠加
				this.crmPromotionPloyCanBeCombinationScore = paramList.crmPromotionPloy_canBeCombination_score == "1";
			},

			/**
			 * 加载购物车列表
			 */
			async _loadShoppingCartList() {
				// 加载购物车信息
				let shoppingCartList = await getShoppingCartList("", this.cartIds.split(','), true, true, true, false, false, false);

				// 检测是否存在购物车数据，不存在则不进行后续操作
				if (!(shoppingCartList && shoppingCartList.length > 0)) {
					this.goBack();
					this.$message("未检测到商品信息");
					return;
				}

				// 获取促销价
				await this.__calcMarketingPrice(shoppingCartList);

				// 匹配CRM促销策略（内部会判断是否开启配置）
				await this.__matchCrmPromotionPloy(shoppingCartList);

				// 渲染购物车数据
				this.tradeGoods = shoppingCartList;

				// 计算货品价格和积分
				let goodsTotal = 0,
					scoreTotal = 0,
					weightTotal = 0,
					// 预售金额
					presaleTotal = 0,
					rcvTotal = 0;
				for (let spec of shoppingCartList) {
					let count = parseInt(spec.goodsCount);
					goodsTotal += parseFloat(spec.executionPrice) * count;
					scoreTotal += parseInt(spec.score) * count;
					weightTotal += parseFloat(spec.weight) * count * (spec.unit_count_rate || 1);
				}

				this.goodsTotalAmount = goodsTotal;
				this.goodsTotalScore = scoreTotal;
				this.weightTotalAmount = weightTotal;
				// 订单是否包含保税商品
				this.tradeIsHaveBondedGoods = !!this.tradeGoods.find(item => item.isBonded == 1);

				console.log("购物车数据：", shoppingCartList);


			},

			/**
			 * 计算营销活动价格
			 */
			async __calcMarketingPrice(shoppingCartList) {
				// 生成价格计算所需货品列表对象
				let tobeCalcGoodsList = shoppingCartList.map(t => {
					return {
						goodsId: t.goodsId,
						specId: t.specId,
						unitName: t.unitName,
						goodsCount: t.goodsCount,
						executionPrice: t.originalExecutionPrice || t.executionPrice || 0,
						score: t.score || 0,
						baseUnit: t.baseUnit,
						countRate: t.unitCountRate,
						cartId: t.cartId,
						actType: t.actType
					}
				});

				await calcMarketingGoodsTotalAmount(tobeCalcGoodsList);

				// 根据出处的结果反向修改每个商品的执行价
				shoppingCartList.forEach(cart => {
					// 处理需要展示的标签
					let labelListInExtraPage = this.labelsInExtraPage.split(",");
					let labelNameList = (cart.labels || '').split(',').filter(item => item && labelListInExtraPage.includes(item));

					let calcRes = tobeCalcGoodsList.find(t => t.cartId == cart.cartId) || {};
					cart.executionPrice = calcRes.marketingPrice || calcRes.executionPrice || cart.executionPrice;
					cart.marketingLabelName = calcRes.marketingLabelName || "";

					// 补充标签数据
					calcRes.marketingLabelName && labelNameList.unshift(calcRes.marketingLabelName)
					cart.labelNameList = labelNameList;
				});
			},

			//计算订单总额
			_calcTradeTotal() {
				// 订单积分
				this.tradeScoreTotal = this.goodsTotalScore + this.deductionNeedScore;

				// 订单重量
				this.weightTotal = this.weightTotalAmount;

				// 订单总金额
				this.tradeTotal = this.goodsTotalAmount + this.freight;

				// 订单实付
				this.rcvTotal = this.goodsTotalAmount + this.freight - this.scoreDeductionAmount - this.crmPloyDeductionAmount;

				// 预售暂时不支持加入购物车,后续如果支持,需要调整
				this.showRcvTotal = this.isPresaleTrade ? parseFloat((this.rcvTotal * this.depositRatio / 100).toFixed(
					2)) : this.rcvTotal;
			},

			/**
			 * 计算订单积分抵扣情况
			 */
			_calcExpectDeductionScore() {
				try {
					// 是否允许使用积分抵扣
					if (!this.isAllowedUseScore) {
						throw new Error("不允许使用积分抵扣");
					}

					// 检测是否开启积分抵扣功能，未开启则无需计算
					if (!this.isOpenScoreDeduction) {
						throw new Error("未开启积分抵扣功能");
					}

					// 检测是否来自特定的下单场景，是则无需计算
					if (["PC限时抢购"].includes(this.tradeFrom) || this.tradeGoods.filter(t => [1, 2, "1", "2"].includes(t.actType)).length > 0) {
						throw new Error("当前来源不支持积分抵扣");
					}

					// 计算并检测用户积分是否足够，不足则无需计算
					let integralBalance = (this.customerInfo.integralBalance || 0) - this.goodsTotalScore;
					if (integralBalance <= 0) {
						throw new Error("积分不足，无法进行积分抵扣");
					}

					// 可参与抵扣的金额
					let originalAmount = this.goodsTotalAmount - this.crmPloyDeductionAmount;

					// 检测是否满足“满XX可用”
					let amountLimit = parseFloat(this.scoreDeductionRules.deduction_trade_limit || 0);
					if (originalAmount < amountLimit) {
						throw new Error("金额未达到可用门槛，无法进行积分抵扣");
					}

					// 检测可抵扣金额上限是否大于0，否则无需计算（抵扣金额上限：订单金额*上限比例）
					let maxDeductionAmount = parseFloat((parseFloat(this.scoreDeductionRules.deduction_ratio_limit) / 100 * originalAmount).toFixed(2)) || 0;
					if (maxDeductionAmount <= 0) {
						throw new Error("可抵扣金额为0，无法进行积分抵扣");
					}

					// 抵扣1元所需积分
					let unitaryScore = parseInt(this.scoreDeductionRules.deduction_score);
					// 一个积分可抵扣的金额
					let oneScoreAmount = 1 / unitaryScore;

					// 当前积分总共可以抵扣的金额
					let canDeductionAmount = integralBalance * oneScoreAmount;
					// 不能超过可抵扣上限
					canDeductionAmount = canDeductionAmount > maxDeductionAmount ? maxDeductionAmount : canDeductionAmount;

					// 反算所需积分（整数）
					let deductionNeedScore = Math.floor(canDeductionAmount / oneScoreAmount);
					// 由于积分取整了，需要重新反算抵扣金额
					canDeductionAmount = parseFloat((deductionNeedScore * oneScoreAmount).toFixed(2));

					this.scoreDeductionAmount = canDeductionAmount; // 积分抵扣金额
					this.scoreDeductionAmountForShow = canDeductionAmount;
					this.deductionNeedScore = deductionNeedScore; // 抵扣 所需积分
					this.deductionNeedScoreForShow = deductionNeedScore;
					this.isShowScoreDeduction = true;

				} catch (e) {
					this.scoreDeductionAmount = 0; // 积分抵扣金额
					this.deductionNeedScore = 0; // 抵扣 所需积分
					this.isChooseScoreDeduction = false;
				}
			},

			/**
			 * 切换 积分抵扣选择
			 */
			_chooseScoreDeduction() {
				this.isChooseScoreDeduction = !this.isChooseScoreDeduction;

				// 计算价格
				this._refreshAmountInfo();
			},


			/**
			 * 切换 积分抵扣选择
			 */
			_calcScoreDeductionAmount() {
				if (!this.isChooseScoreDeduction) {
					this.scoreDeductionAmount = 0; // 积分抵扣金额
					this.deductionNeedScore = 0; // 抵扣 所需积分
				}
			},

			/**
			 * 重新计算价格
			 */
			_refreshAmountInfo() {
				// 计算预计可抵扣积分
				this._calcExpectDeductionScore();

				// 计算积分抵扣金额
				this._calcScoreDeductionAmount();

				// 更新结算价格
				this._calcTradeTotal();

				// 重新计算邮资
				this._calcFreight();
			},

			// 校验时间
			checkTime(time) {
				return time.getTime() - this.couponExchangeDelayedSendDays * 24 * 60 * 60 * 1000 < Date.now() - 86400000
			},
			//获取用户地址
			async _loadAddress(addressId) {
				this.isPostageOver = false;

				// 获取地址信息，没有传入Id则获取默认地址
				let addressInfo = {};
				if (addressId) {
					addressInfo = await addressUtils.getAddressDetail(addressId);
				} else {
					addressInfo = await addressUtils.getDefaultAddress();
				}
				if (!addressInfo.addressId) {
					return;
				}
				// 渲染地址信息
				this.address = addressInfo;
				this.addrSelectedId = addressInfo.addressId.toString();
				this.addressShowList = [addressInfo];

				// 检测限购区域
				if (!this._checkBuyLimitCities()) {
					this.$message.error("该配送地址不能下单");
					return;
				}
			},

			/**
			 * 获取预售商品信息
			 */
			async _getPresaleGoodsInfo() {
				// 获取下单商品中预售商品的信息
				let presaleGoodsInfo = this.tradeGoods.find(t => t.actType == "3");

				// 组装请求数据
				let tmpSkuList = this.tradeGoods.map(item => {
					return {
						specId: item.specId,
						unit: item.unitName,
						goodsId: item.goodsId
					}
				})
				// 获取预售信息
				let presaleInfo = await getPresaleInfoByGoodsId(tmpSkuList);

				// 预售商品存在，则标记为预收单以及赋值预售比例
				if (!!presaleGoodsInfo && !!presaleInfo) {
					this.isPresaleTrade = true;
					this.depositRatio = presaleInfo[0].depositRatio;
				}
			},

			/**
			 * 获取预售商品信息
			 */
			_getPostage(reqData) {
				return new Promise((resolve, reject) => {
					// 发送请求获取验证码
					this.$request.jackyunPost({
						method: "jmall.trade.postagecalc",
						...reqData
					}, (res) => {
						let postage = -1;
						// 检测验证码发送是否正确
						if (res.code == 200) {
							postage = parseFloat(res.result.data);
						} else {
							this.$message.error(res.msg || '邮资计算失败');
						}
						return resolve(postage);
					});
				})

			},


			//计算运费，邮资计算
			async _calcFreight() {
				let that = this;
				let address = that.address;
				if (!address.addressId) {
					return;
				}
				// if (!that.bindLogisticId) {
				// 	return;
				// }
				if (!that.channelId) {
					return;
				}
				// 第一次计算邮资前获取warehouseId，之后从页面中读取
				if (!!that.channelInfo) {
					// 获取仓库Id
					let warehouseIds = localStorage.getItem("pc_warehouseId") || '';
					// 下单确认重新刷新渠道Info
					let channelInfo = await getChannelInfo(that.channelId, true);
					let warehouseId = "";
					if (channelInfo) {
						warehouseId = channelInfo.warehouseId;
					} else if (!warehouseIds && warehouseIds.length > 0) {
						warehouseId = warehouseIds.split(",")[0];
					} else {
						return;
					}
					that.channelInfo = channelInfo;
					that.warehouseId = warehouseId;
				}

				// 检测包邮金额执行依据
				let tradeTotal = this.freePostageType == "goodsTotal" ? this.goodsTotalAmount : this.tradeTotal;

				// 计算除去邮资外的总金额
				let rcvTotal = this.goodsTotalAmount - this.scoreDeductionAmount - this.crmPloyDeductionAmount;

				// 获取下单货品明细信息（规格单位级，用作邮资计算分仓邮资判断）
				let orderGoodsList = this.__getOrderGoodsList__();

				// 获取下单货品标签分类等信息（货品级，用作邮资策略计算）
				let orderGoodsInfo = await this.__getOrderGoodsInfo__();

				// 获取客户标签（营销设置中会有客户标签排除标记）
				let customerTagList = this.customerInfo.tagArr || [];

				let reqData = {
					customerId: that.customerId,
					channelId: that.channelId,
					warehouseId: that.warehouseId,
					logisticId: that.bindLogisticId,
					logisticType: that.logisticType,
					country: address.country,
					province: address.province,
					city: address.city,
					district: address.district || '',
					town: address.town,
					weightTotal: that.weightTotal,
					tradeTotal: tradeTotal,
					rcvTotal: rcvTotal,
					distance: 99999999,
					orderGoodsList: JSON.stringify(orderGoodsList),
					orderGoodsInfo: JSON.stringify(orderGoodsInfo),
					customerTag: customerTagList.join(",")
				}

				// 获取邮资
				let postage = await this._getPostage(reqData);
				if (postage < 0) {
					return;
				}

				// 参数赋值
				that.isPostageOver = true;
				that.freight = postage;

				// 计算包邮差额
				that.__getfreepostagedifference(reqData);

				// 计算总价
				that._calcTradeTotal();
			},

			/**
			 * 获取下单货品信息（规格单位级，用作邮资计算分仓邮资判断）
			 */
			__getOrderGoodsList__() {
				let orderGoodsList = this.tradeGoods.map(t => {
					return {
						goodsId: t.goodsId,
						specId: t.specId,
						unitName: t.unitName,
						sellCount: t.goodsCount,
					};
				});
				return orderGoodsList;
			},

			/**
			 * 获取下单货品标签分类等信息（货品级，用作邮资策略计算）
			 */
			async __getOrderGoodsInfo__() {
				// 已经生成过，则不重新获取
				if (this.orderGoodsInfoList) {
					return this.orderGoodsInfoList;
				}

				// 获取goodsId集合
				let goodsIdList = this.tradeGoods.map(t => t.goodsId);

				// 获取吉客云商品扩展信息
				let jackyunGoodsExtendedInfoList = await goodsUtils.getJackyunGoodsExtendedInfo(goodsIdList);

				// 订购货品信息
				let orderGoodsInfoList = [];
				this.tradeGoods.forEach(cart => {
					// 获取当前商品的扩展信息
					let currExtendedInfo = jackyunGoodsExtendedInfoList.find(t => t.goodsId == cart.goodsId);
					if (currExtendedInfo) {
						// 获取基础单位购买数量（按件计费需要使用）
						let baseUnitCount = goodsUtils.convertToBaseUnitCount(currExtendedInfo.unitInfo, cart.unitName, cart.goodsCount)
						// 添加到结果对象
						orderGoodsInfoList.push({
							goodsId: cart.goodsId,
							specId: cart.specId,
							unit: cart.unitName,
							sellCount: cart.goodsCount,
							cateId: currExtendedInfo.jackyunCateId || 0,
							goodsTags: currExtendedInfo.jackyunFlagData || "",
							isFit: cart.isPackage,
							baseUnitSellCount: baseUnitCount, // 该字段暂时不生效，后端会去除，改为OMS自动计算
						});
					}
				});

				// 记录结果，不再重复获取
				this.orderGoodsInfoList = orderGoodsInfoList;
				return orderGoodsInfoList;
			},

			//检测区域限购
			_checkBuyLimitCities() {
				let canBuy = true;
				if (this.isOpenCityBuyLimit && this.buyLimitCities.length > 0) {
					canBuy = false;
					let address = this.address;
					let buyLimitCities = this.buyLimitCities;
					let province = address.province ? address.province.substring(0, 2) : "";
					let city = address.city ? address.city : "";
					let town = address.district ? address.district : "";
					for (let i = 0; i < buyLimitCities.length; i++) {
						let item = buyLimitCities[i];
						let limit_province = item.province ? item.province.substring(0, 2) : "";
						let limit_city = item.city ? item.city : "";
						let limit_town = item.town ? item.town : "";
						if (limit_province == province) {
							if (limit_city && limit_city != "全部") {
								if (limit_city == city || limit_city.replace("市", "") == city.replace("市", "")) {
									if (limit_town && limit_town != "全部") {
										if (limit_town == town || limit_town.replace("市", "") == town.replace("市", "") ||
											limit_town.replace("县", "") == town.replace("县", "") ||
											limit_town.replace("区", "") == town.replace("区", "")) {
											canBuy = true;
											break;
										}
									} else {
										canBuy = true;
										break;
									}
								}
							} else {
								canBuy = true;
								break;
							}
						}
					}
				}
				return canBuy;
			},
			//检测数据
			_checkData() {
				//关闭所有警告
				this.$message.closeAll();
				if (this.showRcvTotal >= Math.pow(10, 12)) {
					this.$message.error("订单金额过大，请分批下单");
					return;
				}
				if (this.isClickPay) {
					this.$message.error("请勿重复提交");
					return;
				}

				// 检测下单金额
				if (this.tradeAmountLimit_isEnabled) {
					// 获取需要检测的金额
					let limitAmount = this.tradeAmountLimit_limitType == "goodsTotal" ? this.goodsTotalAmount : this.rcvTotal;
					if (limitAmount < this.tradeAmountLimit_limitAmount) {
						this.$message.error(`${this.tradeAmountLimit_limitType == "goodsTotal"?"商品合计":"实付金额"}需大于${this.tradeAmountLimit_limitAmount.toFixed(2)}，请增加货品后下单`);
						return;
					}
				}

				if (!this.address.address) {
					this.$message.error("请选择收件地址");
					return;
				}
				if (!this._checkBuyLimitCities()) {
					this.$message.error("该配送地址不能下单");
					return;
				}
				if (!this.isPostageOver) {
					this.$message.error("运费计算中，请稍等");
					return;
				}
				// if (this.isShowCollectChangerInfo ) {
				// 	this.$message.error("订购人电话不可为空");
				// 	return;
				// }
				if (this.isOpenExpectedDeliveryTime && !this.expectedDeliveryTime) {
					this.$message.error("发货时间不可为空");
					return;
				}

				if (this.isShowRequiredField && !this.requiredFieldText) {
					this.$message.error(`请填写${this.requiredFieldShowName}`);
					return;
				}

				if (this.isOpenOrderSentAttachments && this.attachList.length == 0) {
					this.$message.error(this.orderSentAttachmentsRemind);
					return;
				}

				// 检测启证件必传
				if (this._checkTradeNeedIdInfo()) {
					this.$message.error("请补充证件信息");
					return;
				}

				// if(this.isOpenExpectReciveTime && !this.expectReciveTime){
				// 	this.$message.error("期望送达时间不能为空");
				// 	return;
				// }
				return true;
			},
			/**
			 * 检测订单是否需要校验证件信息
			 */
			_checkTradeNeedIdInfo() {
				if (!this.isMustIDCard) {
					return false
				}

				// isNeedDeliveryIdInfoWithoutBondGoods为1时 不包含保税商品不需要校验证件信息
				if (!this.isNeedDeliveryIdInfoWithoutBondGoods &&
					!this.tradeIsHaveBondedGoods) {
					return false
				}

				if ([1, 3].includes(this.logisticType) && (!this.address.idCard || !this.address.idCardName)) {
					return true
				}

				return false
			},

			// 获取客户备注
			_getCustomerRemark_() {
				let that = this;
				// 所有用户备注集合
				let customerRemarkList = [];
				if (!!that.customerRemark) {
					customerRemarkList.push(that.customerRemark);
				}
				if (that.isOpenExpectedDeliveryTime) {
					customerRemarkList.push('期望发货时间：' + that.expectedDeliveryTime);

				}
				if (that.changeTel) {
					customerRemarkList.push('订购人电话:' + that.changeTel)
				}
				return customerRemarkList.join('；');
			},
			//获取订单信息
			async _getTradeInfo(goodsList) {
				let that = this;
				let address = that.address;

				let logisticInfo = that.logisticList.find(item => item.id == that.bindLogisticId);
				let tradeInfo = {
					tradeType: this.isPresaleTrade ? "3" : "1",
					channelId: that.channelId,
					channelName: that.channelInfo.channelName,
					warehouseId: that.channelInfo.warehouseId,
					warehouseName: that.channelInfo.warehouseName,
					customerId: that.customerId,
					tradeNick: this.customerInfo.nickname || this.customerInfo.tel,
					upperId: this.customerInfo.upperID,
					logisticType: that.logisticType,
					logisticId: that.bindLogisticId,
					logisticName: logisticInfo ? logisticInfo.name : that.selectedlogisticName,
					sendTo: address.name,
					country: address.country,
					province: address.province,
					city: address.city,
					district: address.district,
					town: address.town,
					address: address.address,
					tel: address.tel,
					tradeFrom: that.tradeFrom,
					idCardType: address.idCardType,
					idCardNo: address.idCard,
					idCardName: address.idCardName,
					// pc暂不支持对接卡券以及积分抵扣
					couponCode: "",
					couponTotal: 0,
					scoreDeductionTotal: this.scoreDeductionAmount,
					deductionScoreTotal: this.deductionNeedScore,
					goodsTotal: that.goodsTotalAmount,
					postageTotal: that.freight,
					allTotal: that.goodsTotalAmount + that.freight,
					rcvTotal: that.rcvTotal,
					exchangeScoreTotal: that.goodsTotalScore,
					onlinePlatTypeCode: that.channelInfo.onlinePlatTypeCode || "",
					currencyCode: that.currencyCode,
					currencyName: that.currencyName,
					isDistributorSubSend: (that.isOpenDistributorTradeType && [1, "1", "True", "true", true]
						.includes(
							this.customerInfo.isDistributor)) ? 1 : 0,
					sellerId: "",
					sellerName: "",
					distance: that.channelInfo.distance,
					customerRemark: that._getCustomerRemark_(),
					goodsInfo: that.__getGoodsInfo__(),
					expectedDeliveryTime: that.expectedDeliveryTime,
					expectedSendGoodsTime: that.expectedDeliveryTime,
					customerAccount: that.__getCustomerAccount(), // 客户账号
					attachUrls: this.attachList.join(";"),
				};

				// 开启自定义字段 填充相关数据
				if (this.isShowRequiredField) {
					tradeInfo.customColumnStr1 = {
						chName: this.requiredFieldShowName,
						omsColumnEnName: commonUtil.underlineToHump(this.requiredFieldToOms),
						columnValue: this.requiredFieldText
					};
				}
				// 补充结算账户信息
				let accountInfo = this.__getAccountlInfo();
				tradeInfo.accountId = accountInfo.accountId;
				tradeInfo.accountName = accountInfo.accountName;
				let sellInfo = await tradeUtils.getTradeManager();
				tradeInfo.sellerId = accountInfo.sellerId;
				tradeInfo.sellerName = accountInfo.sellerName;

				tradeInfo.tradeGoodsList = goodsList.map(t => {
					let goods = {
						goodsId: t.goodsId,
						specId: t.specId,
						goodsName: t.goodsName,
						specName: t.specName,
						unitName: t.unitName,
						unitCountRate: t.unitCountRate,
						sellCount: t.goodsCount,
						sellPrice: t.executionPrice,
						sellTotal: t.goodsCount * t.executionPrice,
						sellScore: t.score,
						scoreTotal: t.score * t.goodsCount,
						actType: t.actType || '',
						actId: t.actId || '',
						marketingId: t.marketingId,
						cartId: t.cartId || '',
						specCode: t.specCode,
						originalPrice: t.referencePrice,
						// pc未对接分仓邮资库存
						warehouseId: '',
						packageGoodsId: t.packageGoodsId,
						packageSpecId: t.packageSpecId,
					};
					return goods;
				});

				// 计算均摊优惠金额
				this.__calcAllocationDeductionAmount__(tradeInfo);

				// 添加订单扩展信息
				this.__addTradeExtendData__(tradeInfo);

				// 添加订单日志
				this.__addTradeLog__(tradeInfo);

				return tradeInfo;
			},

			/**
			 * 计算优惠分摊金额
			 */
			__calcAllocationDeductionAmount__(tradeInfo) {
				try {
					// 一：计算crm促销策略优惠分摊
					if (this.isMatchedCrmPloy) {
						// 计算抵扣细节
						this.___calcDetailAllocationAmountForCrmPloy___(tradeInfo, this.crmPloyMatchResult);
					}

					// 二：计算积分抵扣优惠分摊
					if (this.scoreDeductionAmount > 0) {
						// 计算抵扣细节
						this.___calcDetailAllocationAmount___(tradeInfo.tradeGoodsList, this.scoreDeductionAmount, "scoreDeductionAmount");
					}
				} catch (e) {
					console.log("分摊计算异常：" + e.message);
				}
			},

			/**
			 * 计算crm促销策略优惠均摊
			 * @param {*} tradeInfo 
			 * @param {*} crmPloyMatchResult 
			 */
			___calcDetailAllocationAmountForCrmPloy___(tradeInfo, crmPloyMatchResult) {
				// 将匹配结果分类（订单级和货品级）
				let promotionResult_trade = [];
				let promotionResult_goods = [];
				let deductionAmount_trade = 0;
				let deductionAmount_goods = 0;
				crmPloyMatchResult.promotionResult.forEach(item => {
					if (item.goodsMultipleArr.length > 0) {
						promotionResult_goods.push(item);
						deductionAmount_goods += item.discount || 0;
					} else {
						promotionResult_trade.push(item);
						deductionAmount_trade += item.discount || 0;
					}
				});

				// 记录订单上的优惠金额
				tradeInfo.crmPloyTotalTrade = deductionAmount_trade;
				tradeInfo.crmPloyTotalGoods = deductionAmount_goods;

				// 将订单级优惠均摊到货品上
				this.___calcDetailAllocationAmount___(tradeInfo.tradeGoodsList, deductionAmount_trade, "crmPloyDeductionAmountTrade");

				// 将货品级优惠均摊到货品上
				promotionResult_goods.forEach(promotion => {
					// 获取出应用了策略的货品
					let hitGoodsInfoList = [];
					promotion.goodsMultipleArr.forEach(goods => {
						hitGoodsInfoList = hitGoodsInfoList.concat(goods.satisfyGoodsArr.map(t => `${t.goodsId}-${t.specId}-${t.unit}`));
					});
					hitGoodsInfoList = distinctArr(hitGoodsInfoList);

					// 获取出命中的货品
					let tmpTradeGoodsList = tradeInfo.tradeGoodsList.filter(t => hitGoodsInfoList.includes(`${t.goodsId}-${t.specId}-${t.unitName}`));

					// 将订单级优惠均摊到货品上
					this.___calcDetailAllocationAmount___(tmpTradeGoodsList, promotion.discount, "crmPloyDeductionAmountGoods");
				});
			},

			/**
			 * 计算抵扣细节（公共方法）
			 * @param {*} tradeGoodsList 
			 * @param {*} allDeductionAmount 
			 * @param {*} fieldName 
			 */
			___calcDetailAllocationAmount___(tradeGoodsList, allDeductionAmount, fieldName) {
				// 获取下单货品总行数
				let goodsRowCount = tradeGoodsList.length;
				// 获取货品总金额
				let goodsTotalAmount = commonUtil.sumArr(tradeGoodsList, "sellTotal");
				// 记录剩余可分配额度
				let leftDeductionAmount = allDeductionAmount;

				// 遍历货品信息进行分摊处理
				tradeGoodsList.forEach((goods, index) => {
					// 初始化字段
					goods[fieldName] = goods[fieldName] || 0;

					// 执行n-1个货品，且需要可用额度大于0
					if (index + 2 <= goodsRowCount && leftDeductionAmount > 0) {
						// 计算当前货品的分摊金额（总额，不安数量细分，会导致精度不准确）
						let allocationAmount = parseFloat((allDeductionAmount * (goods.sellTotal / goodsTotalAmount)).toFixed(2));

						// 最小控制0.01元，且小于可分配额度
						allocationAmount = allocationAmount < 0.01 ? 0.01 : allocationAmount;
						allocationAmount = allocationAmount > leftDeductionAmount ? leftDeductionAmount : allocationAmount;

						// 赋值到指定的均摊金额字段
						goods[fieldName] += commonUtil.floatKeepDigit(allocationAmount);

						// 扣减掉剩余金额
						leftDeductionAmount -= allocationAmount;
					}
				});

				// 计算最后一个货品的分摊金额
				tradeGoodsList[goodsRowCount - 1][fieldName] += commonUtil.floatKeepDigit(leftDeductionAmount);
			},

			/**
			 * 商品描述
			 */
			__getGoodsInfo__() {
				// 先获取一个数据相对完整的货品
				let exampleGoods = this.tradeGoods.find(t => !!t.goodsName && !!t.specName && !!t.unitName);
				if (!exampleGoods) {
					exampleGoods = this.tradeGoods[0];
				}

				// 计算所有购买商品的总数量
				let allBuyCount = commonUtil.sumArr(this.tradeGoods, "goodsCount");

				// 计算商品大类的数量
				let goodsCount = this.tradeGoods.length;

				// 生成商品摘要信息
				return `${exampleGoods.goodsName}【${exampleGoods.specName}】（${exampleGoods.goodsCount}${exampleGoods.unitName}）${goodsCount > 1 ? "等" : ""} 共${allBuyCount}件`;

			},

			/**
			 * 添加订单扩展信息
			 */
			__addTradeExtendData__(tradeInfo) {
				// 初始化
				tradeInfo.tradeExtendData = {};

				// crm促销策略匹配结果
				if (this.isMatchedCrmPloy) {
					tradeInfo.tradeExtendData.crmPloyDetail = JSON.stringify(this.crmPloyMatchResult.promotionResult);
				}
			},

			/**
			 * 添加订单日志
			 */
			__addTradeLog__(tradeInfo) {
				// 初始化
				tradeInfo.tradeLog = "";
				let tradeLogList = [];

				// crm促销策略匹配日志
				if (this.isMatchedCrmPloy) {
					let tmpArr = this.crmPloyMatchResult.promotionResult.map(t => `【${t.ployName}】（优惠￥${commonUtil.floatKeepDigit(t.discount).toFixed(2)}）`)
					tradeLogList.push(`匹配促销策略：合计优惠￥${commonUtil.floatKeepDigit(this.crmPloyMatchResult.discount).toFixed(2)}，${tmpArr.join("、")}`);
				}

				// 生成日志数据
				tradeInfo.tradeLog = tradeLogList.join(";");
			},

			//编辑地址保存成功
			editAddrSuccess(obj) {
				this.address = obj;

				if (!this._checkBuyLimitCities()) {
					this.$message.error({
						message: "该配送地址不能下单"
					});
					return;
				}
				this.addressShowList = [obj];
				this.addrSelectedId = obj.addressId;
				this.isPostageOver = false;
				this._calcFreight();
			},
			//切换地址
			AddressChange(e) {
				// this.addrSelectedId = e;
				// this.address = this.addressList.find((item) => (e == item.addressId));
				// if (!this._checkBuyLimitCities()) {
				// 	this.$message.error({
				// 		message: "该配送地址不能下单"
				// 	});
				// 	return;
				// }
				// this.isPostageOver = false;
				// this._calcFreight();
			},
			AddressChangeInfo(e) {
				this.addressSelectVisible = false;
				this.addrSelectedId = e.addressId;
				this.address = e;

				this.addressShowList = [e];
				if (!this._checkBuyLimitCities()) {
					this.$message.error({
						message: "该配送地址不能下单"
					});
					return;
				}
				this.isPostageOver = false;
				this._calcFreight();
			},
			//切换物流
			logisticChange(e) {
				this.bindLogisticId = this.logisticList.find((item) => (e == item.showname)).id;
				this.isPostageOver = false;
				this._calcFreight();
			},

			// 限购检测
			async _checkRestrictedGoods(goodsList) {
				// 获取省市区信息
				let {
					province,
					city,
					district
				} = this.address;
				// 获取当前货品规格Id集合
				let specIds = goodsList.map(t => t.specId);
				// 调用限购检测接口，获取检测结果（限购商品+有效商品）
				let res = await tradeUtils.checkRestrictedGoods(province, city, district, specIds);
				if (res.restrictedGoodsList && res.restrictedGoodsList.length > 0) {
					this.restrictedInfoList = res.restrictedInfoList; // 限购信息列表
					this.restrictedGoodsList = res.restrictedGoodsList; // 限购商品列表
					this.isShowRestrictedCheckModal = true; // 是否展示限购弹窗
					this.isRestrictedAllGoods = res.validSpecIds && res.validSpecIds.length == 0; // 是否不存在可购商品
					this.validSpecIds = res.validSpecIds; // 剩余可购规格Id
					this.noValidGoodsTipOfRestrictedCheck = res.restrictedInfoList[0] && res.restrictedInfoList[0].limitSalesReminder;
					return false;
				} else {
					return true;
				}
			},
			// 校验用户下单时候的积分
			async _checkScore() {
				// 重置customerInfo信息
				let customerInfo = await getCustomerInfo({}, true);
				// 获取客户积分
				let customerScore = !!customerInfo.integralBalance && customerInfo.integralBalance > 0 ? customerInfo
					.integralBalance : 0;

				// 记录当前积分
				this.curScore = customerInfo.integralBalance

				return this.tradeScoreTotal <= customerScore;
			},

			//去支付
			async toPay() {
				let that = this;
				// 检测下单参数
				if (!that._checkData()) {
					return;
				}
				that.isClickPay = true;

				// 检测下单货品是否满足限时抢购活动的会员等级要求
				if (await this._checkActivityMemberGrade()) {
					that.isClickPay = false;
					return this.$message.error("抢购活动已失效");
				}

				// 检测货品是否是会员等级限制商品
				if (await this._checkMemberBlockGoods()) {
					that.isClickPay = false;
					return this.$message.error("存在下架商品");
				}

				// 检测货品是否下架
				if (await this._checkBlockUpGoods()) {
					that.isClickPay = false;
					return;
				}

				// 检测下单商品库存
				if (!await this._checkOrderGoodsStock()) {
					that.isClickPay = false;
					return;
				}
				let filteredGoodsList = this.tradeGoods;

				// 检测库存过滤后是否还有货品，没有则提示异常信息
				if (filteredGoodsList.length <= 0) {
					that.isClickPay = false;
					return this.$message.error("暂无可下单商品");
				}
				// 区域限购
				if (!await this._checkRestrictedGoods(filteredGoodsList)) {
					that.isClickPay = false;
					return;
				}
				// 校验积分
				if (!await this._checkScore()) {
					that.isClickPay = false;
					return this.$message.error("积分不足，当前积分为" + this.curScore);
				}

				this.createTradeInfo(filteredGoodsList);
			},

			/**
			 * 创建订单并前往结算页面
			 * @param {Object} filteredGoodsList
			 */
			async createTradeInfo(filteredGoodsList) {
				let that = this;
				let trade = await that._getTradeInfo(filteredGoodsList);
				that.$request.jackyunPost({
					method: "jmall.trade.tradecreate",
					jsonStr: JSON.stringify(trade)
				}, function(res) {
					if (res.code == '200') {
						that.$eventBus.$emit("updateHeaderShopCartCount"); //修改购物车总数
						that.$router.push({
							name: 'pay',
							params: {
								tradeNo: res.result.data.tradeNo
							}
						});
					} else {
						that.$message.error({
							message: res.msg
						});
						that.isClickPay = false;
					}
				});
			},
			// 返回上一页
			goBack() {
				this.$router.back(-1);
			},
			/**
			 * 检测下单货品库存
			 */
			async _checkGoodsStock(goodsList) {
				try {
					// 获取所有Id集合
					let skuIds = goodsList.map(t => t.specId);

					// 获取库存数据
					let stockList = await goodsUtils.getSkuStockList(skuIds);

					// 补充库存数据
					goodsList.forEach(spec => {
						let stockInfo = stockList.find(t => t.skuId == spec
							.specId &&
							(t.unitName == spec.unitName || !t.unitName));
						if (!!stockInfo) {
							spec.stock = stockInfo.stock;
						} else {
							spec.stock = 0;
						}
					});

					// 检测是否包含负库存商品
					let stockNotEnoughGoodsNamelist = goodsList.filter(t => t
						.stock < t.goodsCount).map(t =>
						`${t.goodsName}【${t.specName}-${t.unitName}】`);

					// 检测库存不足的货品
					if (stockNotEnoughGoodsNamelist && stockNotEnoughGoodsNamelist.length >
						0) {
						await this.$confirm(
							`${stockNotEnoughGoodsNamelist.join('，')} 因库存变动，导致库存不足，是否忽略无库存或者购买最大可用库存商品，继续下单？`
						);
					}
					return true;
				} catch (e) {
					return false;
				}
			},

			// 打开我的地址页面
			openAddress() {
				this.addressSelectVisible = true;
				this.addressSelectkey = !this.addressSelectkey;
			},

			/**
			 * 过滤货品数据，将库存为0的商品去除，以及修改选购量
			 */
			_filterGoodsList(goodsList) {
				// 去除库存为0的商品
				goodsList = goodsList.filter(t => t.stock > 0);

				// 修改库存不足的商品选购量
				goodsList.forEach(t => {
					if (t.stock > 0 && t.stock < t.goodsCount) {
						t.goodsCount = t.stock;
					}
				});

				return goodsList;
			},

			// 关闭地址选择
			closeSelectAddr() {
				this.addressSelectVisible = false;
			},

			/**
			 * 检测下单货品库存
			 */
			async _checkOrderGoodsStock() {
				// 检测普通库存
				let baseStockCheck = await this._checkOrderGoodsBaseStock();
				// 检测活动库存
				let activityStockCheck = baseStockCheck && await this._checkOrderGoodsActivityStock();

				return baseStockCheck && activityStockCheck
			},

			/**
			 * 检测下单货品erp库存
			 */
			async _checkOrderGoodsBaseStock() {
				try {
					// 接口需要传入tradeId
					if (this.tradeGoods[0].actType == "3") {
						return true;
					}

					// 生成检测数据
					let preCheckGoodsList = this.tradeGoods.map(t => {
						return {
							goodsId: t.goodsId,
							specId: t.specId,
							unitName: t.unitName || "",
							goodsCount: t.goodsCount,
							activityType: t.actType,
							actId: t.actId
						}
					});

					// 检测货品库存，结果包含可购和不可购两个列表
					let res = await goodsUtils.checkGoodsErpStock(preCheckGoodsList);

					if (res.baseUnitOverOrderGoodsList.length == 0) {
						return true;
					}

					// 超卖情况下，下单商品包含多单位
					if (this._checkCartHasMultiUnitOverOrder(res)) {
						this.isShowMultiUnitStockCheckModal = true;
						this.baseUnitOverOrderGoodsList = res.baseUnitOverOrderGoodsList;
					} else {
						// 数据渲染
						this.canOrderGoodsList = res.canOrderGoodsList; // 限购商品列表
						this.cannotOrderGoodsList = res.baseUnitOverOrderGoodsList; // 剩余可购规格Id
						this.isNoStockAll = res.canOrderGoodsList.length <= 0; // 是否无可购商品
						this.isShowCheckGoodsDialog = true; // 是否展示库存检测弹窗，存在库存不足的货品就展示
					}
					return false;

				} catch (e) {
					return true;
				}
			},

			/**
			 * 检测下单货品活动库存
			 */
			async _checkOrderGoodsActivityStock() {
				try {
					let that = this;

					// 避免原数组被更改
					let tradeGoods = JSON.parse(JSON.stringify(this.tradeGoods));

					// 生成检测数据
					let actGoodsList = tradeGoods.filter(item => item.actId).map(t => {
						return {
							goodsId: t.goodsId,
							specId: t.specId,
							unitName: t.unitName || "",
							goodsCount: t.goodsCount,
							activityType: t.actType,
							actId: t.actId,
						}
					});

					// 如果不存在活动商品 直接返回
					if (actGoodsList.length == 0) {
						return true;
					}

					// 获取 活动商品 最大可购数
					let actGoodsMaxCountInfoList = await goodsUtils.checkGoodsActivityStock(actGoodsList);

					actGoodsMaxCountInfoList.forEach(item => {
						let tempGoods = tradeGoods.find(cartItem => cartItem.goodsId == item.goodsId && cartItem.specId == item.specId && cartItem.unitName == item.unitName && cartItem.actType == item.activityType && cartItem.actId == item.actId)
						// 超卖库存赋值
						item.overStock = tempGoods.goodsCount - item.buyCountMax;
						// 超卖弹窗需要数据补充
						item.goodsName = tempGoods.goodsName;
						item.coverPic = tempGoods.coverPic;
					})

					// 超卖活动商品
					let overOrderGoodsList = actGoodsMaxCountInfoList.filter(item => item.overStock > 0);

					// 如果不存在超卖活动商品 直接返回
					if (overOrderGoodsList.length == 0) {
						return true;
					}

					let canOrderGoodsList = tradeGoods.map(cartItem => {
						let overOrderGoods = overOrderGoodsList.find(item => cartItem.goodsId == item.goodsId && cartItem.specId == item.specId && cartItem.unitName == item.unitName && cartItem.actType == item.activityType && cartItem.actId == item.actId);
						cartItem.goodsCount = overOrderGoods ? overOrderGoods.buyCountMax : cartItem.goodsCount;
						return cartItem;
					});

					// 过滤数量为0的可购购物车商品
					canOrderGoodsList = canOrderGoodsList.filter(item => item.goodsCount > 0);


					// 数据渲染
					this.canOrderGoodsList = canOrderGoodsList; // 限购商品列表
					this.activityGoodsOverOrderList = overOrderGoodsList; // 剩余可购规格Id
					this.isNoStockAll = overOrderGoodsList.length == tradeGoods.length && canOrderGoodsList.length == 0; // 是否无可购商品
					// this.isNoStockAll = false
					this.isShowActivityGoodsStockCheckModal = true; // 是否展示库存检测弹窗，存在库存不足的货品就展示

					return false;
				} catch (e) {
					return true;
				}
			},

			/**
			 * 校验货品是否上架
			 */
			async _checkBlockUpGoods() {
				try {
					let reqTradeGoods = this.tradeGoods.map(item => {
						return {
							goodsId: item.packageGoodsId || item.goodsId,
							specId: item.packageSpecId || item.specId,
							goodsName: item.goodsName,
							specName: item.specName,
						}
					});

					// 校验货品是否上架
					let blockUpGoodsList = await goodsUtils.checkBlockUpGoods(reqTradeGoods);
					blockUpGoodsList.forEach((item) => {
						let tradeGood = this.tradeGoods.find((tradeItem) => item.goodsId == (tradeItem.packageGoodsId || tradeItem.goodsId));
						if (tradeGood) {
							item.picUrl = tradeGood.coverPic;
							item.specId = tradeGood.packageSpecId || tradeGood.specId;
							item.goodsName = tradeGood.goodsName;
							item.specName = tradeGood.specName;
						}
					})
					this.cannotOrderGoodsList = blockUpGoodsList;
					this.isNoStockAll = this.cannotOrderGoodsList.length == reqTradeGoods.length; // 是否无可购商品
					//this.isNoStockAll = false
					if (blockUpGoodsList.length > 0) {
						this.isShowBlockCheckGoodsDialog = true;
					}
					return blockUpGoodsList.length > 0;
				} catch (e) {
					return false;
				}
			},

			/**
			 * 取消多规格单位库存检测弹窗
			 */
			multiUnitOverStockCancel() {
				this.isShowMultiUnitStockCheckModal = false;
				this.goBack()
			},

			/**
			 * 检测下单货品是否满足限时抢购活动的会员等级要求
			 */
			async _checkActivityMemberGrade() {
				// this.tradeGoods没有抢购商品时isHaveGoodsInvalid 返回false
				if (this.tradeGoods.filter(item => item.actType == 1).length == 0) {
					return false
				}
				let extraInfolist = await goodsUtils.getGoodsExtraInfo(this.tradeGoods.filter(item => item.actType == 1).map(item => item.goodsId));
				// 存在不满足限时抢购会员等级限制的商品
				let isHaveGoodsInvalid = true;
				if (!!extraInfolist) {
					isHaveGoodsInvalid = !(extraInfolist.length == this.tradeGoods.filter(item => item.actType == 1).length);
				}
				return isHaveGoodsInvalid
			},

			/**
			 * 检测下单货品是否是超卖（多单位）
			 */
			async _checkMultiUnitOverStock() {
				try {
					let orderGoodsList = this.tradeGoods.map((item) => {
						return {
							goodsId: item.goodsId,
							specId: item.specId,
							unitName: item.unitName,
							goodsCount: item.goodsCount
						}
					})
					let checkStockResult = await goodsUtils.checkOrderGoodsStock(orderGoodsList);
					// 关闭加载遮罩层
					if (checkStockResult.baseUnitOverOrderGoodsList && checkStockResult.baseUnitOverOrderGoodsList.length > 0) {
						this.isShowMultiUnitStockCheckModal = true;
						this.baseUnitOverOrderGoodsList = checkStockResult.baseUnitOverOrderGoodsList;
						return true;
					}
					return false;
				} catch (e) {
					return false;
				}

			},

			/**
			 * 检测下单货品是否是会员等级限制商品
			 */
			async _checkMemberBlockGoods() {
				try {
					// 待检测商品ID
					let goodsIdList = this.tradeGoods.map((item) => item.packageGoodsId || item.goodsId);

					// 校验货品是否是会员等级限制商品  提示跟下架的情况相同
					let blockUpGoodsList = await goodsUtils.checkMemberBlockGoods(goodsIdList);
					blockUpGoodsList.forEach((item) => {
						let tradeGood = this.tradeGoods.find((tradeItem) => item.goodsId == (tradeItem.packageGoodsId || tradeItem.goodsId));
						if (tradeGood) {
							item.picUrl = tradeGood.coverPic;
							item.specId = tradeGood.packageSpecId || tradeGood.specId;
							item.goodsName = tradeGood.goodsName;
							item.specName = tradeGood.specName;
						}
					})
					this.cannotOrderGoodsList = blockUpGoodsList;
					this.isNoStockAll = this.cannotOrderGoodsList.length == this.tradeGoods.length; // 是否无可购商品
					//this.isNoStockAll = false
					if (blockUpGoodsList.length > 0) {
						this.isShowBlockCheckGoodsDialog = true;
					}
					return blockUpGoodsList.length > 0;
				} catch (e) {
					return false;
				}
			},

			/**
			 * 检测超卖情况下下单货品是否包含多单位
			 */
			_checkCartHasMultiUnitOverOrder: function(checkStockResult) {
				// 超卖商品的SpecId数组
				let resultSpecIdList = checkStockResult.baseUnitOverOrderGoodsList.map((item) => item.specId);
				let cartUnitOverOrderListLength = this.tradeGoods.filter(t => resultSpecIdList.includes(t.specId)).length;
				return cartUnitOverOrderListLength > resultSpecIdList.length
			},

			/**
			 * 组件方法：过滤无库存货品继续下单
			 */
			async filterBlockUpGoods() {
				// 接收新的待下单货品
				let newOrderGoodsList = [];
				// 遍历当前下单货品，排除不可购商品重新生成待下单货品
				let cannotOrderGoodsList = this.cannotOrderGoodsList;
				let canOrderGoods = this.tradeGoods.filter((goods) => {
					return !cannotOrderGoodsList.find(t => t.goodsId == (goods.packageGoodsId || goods.goodsId) && t.specId == (goods.packageSpecId || goods.specId))
				});
				newOrderGoodsList = canOrderGoods;

				this.__addTempShoppingCartBatchAndRefresh(newOrderGoodsList, "isShowBlockCheckGoodsDialog")
			},

			/**
			 * 组件方法：过滤无库存货品继续下单
			 */
			filterNoStockGoods() {
				// 接收新的待下单货品
				let newOrderGoodsList = [];
				// 遍历当前下单货品，结合可购列表重新生成待下单货品
				this.tradeGoods.forEach((goods, index) => {
					// 获取对应的可购货品信息
					let canOrderGoods = this.canOrderGoodsList.find(t => t.goodsId == goods.goodsId && t.specId ==
						goods.specId && t.unitName == goods.unitName);
					// 存在对应的可购商品，则修改其数量并添加到最新可购列表
					if (canOrderGoods) {
						goods.goodsCount = canOrderGoods.goodsCount;
						newOrderGoodsList.push(goods);
					}
				});

				this.__addTempShoppingCartBatchAndRefresh(newOrderGoodsList, "isShowCheckGoodsDialog")
			},

			/**
			 * 过滤限购货品后继续执行
			 */
			async filterRestrictedGoods() {
				// 获取可下单货品规格Id
				let validSpecIds = this.validSpecIds;

				// 获取可下单货品数据
				let tempCartIds = this.tradeGoods.filter(t => validSpecIds.includes(t.specId)).map(t => t.cartId) || [];
				
				this.isShowRestrictedCheckModal = false;
				
				// 刷新页面
				this.__refreshPage(tempCartIds);
			},

			/**
			 * 加入临时购物车并刷新页面
			 */
			async __addTempShoppingCartBatchAndRefresh(newOrderGoodsList, dialogJudgeName) {
				// 补充单位比例以及购物车属性
				newOrderGoodsList.forEach((goods, index) => {
					try {
						// 获取当前购物车对应的
						let cartInfo = this.tradeGoods.find(t => t.goodsId == goods.goodsId && t.specId == goods.specId && t.unitName == goods.unitName);
						goods.unitCountRate = cartInfo.unitCountRate;
						goods.cartType = cartInfo.cartType;
						goods.actType = cartInfo.actType;
						goods.actId = cartInfo.actId;
					} catch (e) {}
				});

				// 加入临时购物车
				let tempCartIds = await goodsUtils.addTempShoppingCartBatch(newOrderGoodsList);
				if (tempCartIds) {
					// 如果是购物车下单，则需要删除原购物车信息，非购物车数据就无需处理
					if (this.tradeGoods.length >= 1 && !!this.tradeGoods[0].cartId) {
						// 删除原购物车数据
						goodsUtils.deleteShoppingCart(this.tradeGoods.map(t => t.cartId));
					}

					// 刷新页面
					this.__refreshPage(tempCartIds);
				} else {
					this.$message("数据移除失败，请重试");
					this.goBack();
				}


			},

			/**
			 * 刷新页面
			 */
			async __refreshPage(tempCartIds) {
				// 刷新页面数据
				this._initData({
					cartIds: tempCartIds + "",
					trade_from: this.tradeFrom
				});
				await this._loadShoppingCartList();

				// 计算邮资
				this._calcFreight();

				// 关闭弹窗
				this.isShowCheckGoodsDialog = false;
				this.isShowActivityGoodsStockCheckModal = false;
				this.isShowBlockCheckGoodsDialog = false;
				this.isShowMultiUnitStockCheckModal = false;
			},

			/**
			 * 获取客户账号
			 */
			__getCustomerAccount() {
				try {
					// 获取客户档案信息
					let customerInfo = this.customerInfo;

					// 检测客户账号是否存在
					if (!customerInfo.customerSourceArr || customerInfo.customerSourceArr.length <= 0) {
						return "";
					}

					// 优先获取默认账户
					let accountInfo = customerInfo.customerSourceArr.find(t => t.isDefault == "1");
					if (accountInfo) {
						return accountInfo.customerAccount || "";
					}

					// 次级获取当前渠道账户
					let currentChannelId = localStorage.getItem('pc_channelId');
					accountInfo = customerInfo.customerSourceArr.find(t => t.channelId == currentChannelId);
					if (accountInfo) {
						return accountInfo.customerAccount || "";
					}

					// 默认返回第一个账户
					return customerInfo.customerSourceArr[0].customerAccount || "";
				} catch (e) {
					return "";
				}
			},

			/**
			 * 获取当前结算账户信息
			 */
			__getAccountlInfo() {
				// 已经在吉商城配置了结算账户，则直接取配置信息
				if (this.defaultAccountId) {
					return {
						accountId: this.defaultAccountId,
						accountName: this.defaultAccountName
					}
				}

				// 没有配置的情况，则获取当前渠道的默认结算账户
				let currChannelInfo = localStorage.getItem("pc_channelInfo");
				// 获取渠道默认结算账户
				try {
					let channelDefaultAccountInfo = currChannelInfo.salesSettlementAccountList.find(t => t.isDefault ==
						1);
					return {
						accountId: channelDefaultAccountInfo.bankaccountId,
						accountName: channelDefaultAccountInfo.bankaccountName
					}
				} catch (e) {}

				return {
					accountId: "",
					accountName: ""
				};
			},

			/**
			 * 附件上传
			 */
			_handlePictureCardPreview(response, file, fileList) {
				this.dialogImageUrl = response.response.error_info;
				this.dialogVisible = true;
			},
			_handlePictureCardExceed(files, fileList) {
				this.$message.error("附件数量不可超出3个")
			},
			_handleSuccess(response, file, fileList) {
				if (response.error_code == "0") {
					this.attachList.push(response.error_info)
				}
			},

			/**
			 * 获取包邮差价
			 */
			async __getfreepostagedifference(reqData) {
				// 邮资判断
				if (this.freight == 0) {
					this.showFreepostageDifference = false;
					return;
				}

				// 获取客户标签（营销设置中会有客户标签排除标记）
				let customerTagList = this.customerInfo.tagArr || [];

				let obj = {
					channelId: reqData.channelId,
					distance: reqData.distance,
					logisticId: reqData.bindLogisticId,
					logisticType: reqData.logisticType,
					province: reqData.province,
					tradeTotal: reqData.tradeTotal,
					vipLevel: localStorage.getItem('pc_defaultMemberGradeId'),
					weightTotal: reqData.weightTotal,
					customerTag: customerTagList.join(",")
				};

				// 获取包邮差额
				let sellInfo = await tradeUtils.calfreepostagedifference(JSON.stringify(obj));

				// 参数赋值
				this.freepostagedifference = sellInfo.result.data.freePostageDifference;
				this.showFreepostageDifference = sellInfo.result.data.isHavePostage && !sellInfo.result.data.isFreePostage && sellInfo.result.data.freePostageDifference < 999;

			},

			/**
			 * 匹配CRM促销策略
			 */
			__matchCrmPromotionPloy: async function(shoppingCartList) {
				// 匹配CRM促销策略
				let crmPloyMatchResult = await matchCrmPromotionPloy(shoppingCartList);

				// 是否匹配到生效的策略
				let isMatchedCrmPloy = crmPloyMatchResult.discount > 0;

				// 渲染数据
				this.isMatchedCrmPloy = isMatchedCrmPloy; // 是否匹配到促销策略
				this.crmPloyMatchResult = crmPloyMatchResult; // 促销策略匹配结果
				this.crmPloyDeductionAmount = crmPloyMatchResult.discount; // crm促销策略抵扣金额
				this.isAllowedUseCoupon = !isMatchedCrmPloy || (isMatchedCrmPloy && this.crmPromotionPloyCanBeCombinationCoupon); // 是否可以使用卡券
				this.isAllowedUseScore = !isMatchedCrmPloy || (isMatchedCrmPloy && this.crmPromotionPloyCanBeCombinationScore); // 是否可以使用积分抵扣
				this.isMatchedCrmPloyForShowModal = crmPloyMatchResult.promotionRemindDtoList.length > 0; // 是否匹配到促销策略用作展示匹配结果弹窗
			},
			
			/**
			 * 检测组件触发的关闭
			 */
			cancelCheckModal(){
				this.isShowRestrictedCheckModal = false;
				this.isShowActivityGoodsStockCheckModal = false;
				this.isShowMultiUnitStockCheckModal = false;
				this.isShowBlockCheckGoodsDialog = false;
				this.isShowCheckGoodsDialog = false;
			}
		}
	}
</script>

<style scoped="scoped">
	.date_picker /deep/.el-picker__footer .el-button--text {
		display: none;
	}

	.trade-container {
		/* background-color: #F2F2F2; */
		font-size: 18px;
	}

	.content-box {
		padding: 30px 0 75px 0;
	}

	.tip-text {
		width: 78px;
		padding-right: 20px;
		line-height: 40px;
	}

	.tip-text2 {
		width: 110px;
		line-height: 40px;
		padding-right: 50px;
	}

	.info-box {
		background-color: #fff;
		margin-bottom: 10px;
		padding: 30px 50px;
	}

	.back-box {
		margin-left: -30px;
	}

	.flew-row1 {
		display: flex;
		flex-direction: row;
	}

	.back-btn {
		font-size: 42px;
		position: relative;
		top: -10px;
		right: 10px;
	}

	.title {
		margin-bottom: 26px;
	}

	.row-box {
		justify-content: space-between;
		align-items: center;
	}

	.money-text {
		text-align: right;
		margin-left: 50px;
		display: inline-block;
	}

	.total-box {
		display: flex;
		justify-content: flex-end;
	}

	.total-box /deep/.el-form-item__label,
	.total-box /deep/.el-form-item__content {
		font-size: 18px !important;
		color: #666 !important;
	}

	.total-box /deep/.el-form-item {
		margin-bottom: 5px !important;
	}

	.total {
		color: #333;
		font-size: 24px;
		font-weight: bold;
		margin-right: 50px;
	}

	.pay-box {}

	.pay-btn {
		/* height: 75px; */
		height: 45px;
		width: 190px;
		font-size: 17px !important;
	}

	.score-text {
		position: relative;
		bottom: 2px;
		left: 5px;
	}

	.size14 {
		font-size: 14px;
	}

	.size16 {
		font-size: 16px;
	}

	.row-remark {
		margin-top: 20px;
	}

	.trade-remark {
		width: 400px;
	}

	.mt10 {
		margin-top: 10px;

	}

	.freepostage {
		position: absolute;
		left: -137px;
		bottom: -18px;
		width: 250px;
		font-size: 14px;
		color: red;
		text-align: right;
	}

	.freepostage-text {
		display: flex;
	}

	.presale-style {
		background: #0c0c0c;
		color: #FFFFFF;
		padding: 3px 10px;
		font-size: 14px;
		vertical-align: text-top;
	}

	/deep/.el-upload--picture-card {
		width: 100px !important;
		height: 100px !important;
	}

	.el-icon-plus {
		transform: translateY(-16px);
	}

	/* /deep/textarea {
		width: 400px;
		margin-left: 442px;
		height: 40px;
	} */

	/deep/.el-upload-list__item {
		width: 100px !important;
		height: 100px !important;
	}

	.scorededuction-explain {
		width: 300px;
		height: 40px;
		position: absolute;
		left: 50px;
		top: 170px;
	}

	.scorededuction-img {
		width: 20px;
		height: 20px;
		transform: translateY(4px);
		cursor: pointer;
	}

	.cgoods-list {
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 700px;
	}

	.rcv-title-txt {
		font-size: 21px;
	}

	.submit-box {
		position: fixed;
		bottom: 0;
		width: 100%;
		min-width: 700px;
		max-width: 1050px;
		box-sizing: border-box;
		margin-bottom: 0;
		padding: 15px 50px;
	}

	.submit-box-inner {
		position: relative;
		text-align: right;
	}

	.container {
		min-width: 700px;
		max-width: 1050px;
	}

	.crm-ploy-info {
		position: absolute;
		left: 0;
		bottom: 75px;
		z-index: 9;
	}
</style>