// 引入组件
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/home/home.vue'
import Error from './views/home/error.vue'
import Login from './views/home/login.vue'
import GoodsList from './views/goods/goodslist.vue'
import GoodsDetail from './views/goods/goodsdetail.vue'
import AddressList from './views/mine/addresslist.vue'
import TradeConfirm from './views/trade/tradeconfirm.vue'
import TradeList from './views/trade/tradelist.vue'
import TradeDetail from './views/trade/tradedetail.vue'
import TradeDetailJmc from './views/trade/tradedetailjmc.vue'
import ShopCart from './views/mine/shopcart.vue'
import Pay from './views/pay/pay.vue'
import PaySuccess from './views/pay/success.vue'
import ContactService from './views/mine/contactservice.vue'
import TimedSaleGoodsDetails from './views/goods/timedsalegoodsdetail.vue'
import TimedSaleGoodsList from './views/goods/timedsalegoodslist.vue'
import DesignPage from './views/home/designpage.vue'

// 使用组件
Vue.use(VueRouter)

// 向外抛出一个VueRouter
export default new VueRouter({
	// mode: 'history',
	// base: '/jmc_450001/',
	routes: [
		// 默认选中首页组件
		{
			path: '/',
			redirect: '/home'
		},
		{
			path: '/home',
			name: 'home',
			meta: {
				//自定义数据对象
				keepAlive: true, //缓存此页面
				refreshPage: true //是否刷新此页面
			},
			component: Home,
		},
		{
			path: '/login',
			name: 'login',
			component: Login
		},
		{
			path: '/error',
			name: 'error',
			component: Error
		},
		{
			path: '/goodslist',
			name: 'goodslist',
			meta: {
				//自定义数据对象
				keepAlive: true, //缓存此页面
				refreshPage: true //是否刷新此页面
			},
			component: GoodsList
		},
		{
			path: '/goodsdetail',
			name: 'goodsdetail',
			meta: {
				//自定义数据对象
				// keepAlive: true, //缓存此页面
				refreshPage: true //是否刷新此页面
			},
			component: GoodsDetail
		},
		{
			path: '/addresslist',
			name: 'addresslist',
			component: AddressList
		},
		{
			path: '/tradeconfirm',
			name: 'tradeconfirm',
			component: TradeConfirm,
			meta: {
				//自定义数据对象
				refreshPage: true, //是否刷新此页面
			},
		},
		{
			path: '/tradelist',
			name: 'tradelist',
			meta: {
				//自定义数据对象
				keepAlive: true, //缓存此页面
				refreshPage: true, //是否刷新此页面
				refreshTab: 0, //跳转的tab页
			},
			component: TradeList
		},
		{
			path: '/tradedetail',
			name: 'tradedetail',
			component: TradeDetail,
			meta: {
				isEdit: false //是否做过数据修改
			},
		},
		{
			path: '/tradedetailjmc',
			name: 'tradedetailjmc',
			component: TradeDetailJmc
		},
		{
			path: '/shopcart',
			name: 'shopcart',
			component: ShopCart
		},
		{
			path: '/pay',
			name: 'pay',
			component: Pay
		},
		{
			path: '/success',
			name: 'paysuccess',
			component: PaySuccess
		},
		{
			path: '/contactservice',
			name: 'contactservice',
			component: ContactService
		},
		{
			path: '/timedsalegoodsdetail',
			name: 'timedsalegoodsdetail',
			component: TimedSaleGoodsDetails
		},
		{
			path: '/timedsalegoodslist',
			name: 'timedsalegoodslist',
			component: TimedSaleGoodsList
		},
		{
			path: '/designpage',
			name: 'designpage',
			component: DesignPage
		},
	]
})

//解决不能跳转到当前页面的报错信息
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
