<template>
	<div class="container">
		<div class='navigation'>
			<i class="el-icon-arrow-left back-btn" @click="gotoTradeList"></i>
		</div>
		<div class="flex-center pay-info-box">
			<div class="pay-info-img">
				<img src="../../assets/images/pay/icon-right.svg" />
			</div>
			<div class="pay-info-txt-box">
				<div class="pay-info-txt">{{successTip}}！</div>
				<div class="pay-info-tips">可进入 <span @click="gotoTradeList">我的订单</span> 查看详情</div>
			</div>
		</div>
		<div class="trade-info-box">
			<div class="flex-center trade-info-item">
				<div class="trade-info-key">支付方式</div>
				<div class="trade-info-val">{{tradeInfo.chargeType}}</div>
			</div>
			<div class="flex-center  trade-info-item">
				<div class="trade-info-key">支付时间</div>
				<div class="trade-info-val">{{tradeInfo.payTime}}</div>
			</div>
			<div class="flex-center  after-sale-info">
				<span>如有疑义，请联系</span>
				<div @click="gotoAfterSale">
					<img src="../../assets/images/icon-contact.png" />
					售后客服
				</div>
			</div>
			<div class="flex-center after-sale-info">
				<el-button type="danger" class="flex-center goods-btn" @click="gotoGoodsList">继续选购</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import vHeader from '../../components/common/Header.vue';
	import tradeUtils from '../../utils/api-utils/api-trade.js'
	
	export default {
		components: {
			vHeader,
		},
		data() {
			return {
				tradeNo: "", // 待支付的订单编号
				tradeInfo: {}, // 待支付订单内容
				successTip:"支付成功"
			}
		},
		created: function() {
			// 接收并检测
			if (!this.receiveAndCheckParams()) {
				return false;
			}

			// 获取订单信息
			this.getTradeInfo();
		},
		methods: {
			/**
			 * 接收并检测传递参数
			 */
			receiveAndCheckParams() {
				let _this = this;

				// 获取待支付的订单编号
				let tradeNo = _this.$route.params.tradeNo;
				// 支付场景
				let payScene = _this.$route.params.payScene;
				// 检测订单编号是否存在，不存在则跳转到商品列表
				if (!tradeNo) {
					// 弹出错误提示
					// _this.$message.error('未检测到订单编号');

					// 跳转到商品列表
					_this.gotoGoodsList(1000);

					return false;
				}

				// 订单编号存在则保存页面参数中
				_this.tradeNo = tradeNo;
				// 保存支付场景到页面参数
				_this.payScene = payScene;
				
				return true;
			},

			/**
			 * 获取待支付订单信息
			 */
			async getTradeInfo() {
				let _this = this;

				let tradeInfo = await tradeUtils.getJmallTradeDetail(this.tradeNo);
				
				// 页面展示处理
				tradeInfo.chargeType = tradeInfo.chargeType && tradeInfo.chargeType.includes('线下付款') ? '线下付款' : tradeInfo.chargeType
				
				if (!tradeInfo.tradeId) {
					this.$message.error("支付信息获取失败，请重试")
					// 延迟跳转到订单列表
					this.gotoTradeList(1000);
				}
				// 将订单详情赋值的页面参数
				_this.tradeInfo = tradeInfo;
			},

			/**
			 * 跳转到订单列表
			 */
			gotoTradeList() {
				this.$router.replace({
					name: 'tradelist',
					params: {
						tabIndex :this.payScene == 2 ? "1":""
					},
				});
			},

			/**
			 * 跳转到商品列表
			 */
			gotoGoodsList(sleep) {
				let _this = this;
				setTimeout(function() {
					_this.$router.replace({
						name: 'goodslist'
					});
				}, sleep || 1);
			},

			/**
			 * 跳转到售后服务
			 */
			gotoAfterSale() {
				this.$router.replace({
					name: 'contactservice'
				});
			},

		}
	}
</script>

<style scoped="scoped">
	.container {
		padding: 20px 30px 80px 30px;
		color: #333;
		background-color: #fff;
		margin-top: 30px;
	}

	.navigation {
		height: 40px;
		color: #333;
		margin-top: 10px;
	}

	.back-btn {
		font-size: 40px;
		line-height: 40px;
		vertical-align: top;
		cursor: pointer;
	}

	.pay-info-box {
		padding: 20px 0 40px 0;
		border-bottom: 1px solid #e7e7e7;
	}

	.pay-info-img {
		background: #E7FCF0;
		width: 130px;
		height: 130px;
		border-radius: 70px
	}

	.pay-info-img img {
		width: 70px;
		height: 70px;
		margin: 31px;
	}

	.pay-info-txt-box {
		margin-left: 30px;
	}

	.pay-info-txt {
		font-size: 23px;
		margin: 0px 0 30px 0px;
	}

	.pay-info-txt span {
		color: #ff5757;
	}

	.pay-info-tips {
		font-size: 15px;
		color: #a1a1a1;
	}

	.pay-info-tips span {
		color: #ff5757;
		cursor: pointer;
		text-decoration: underline;
	}

	.trade-info-box {
		padding-top: 20px;
	}

	.trade-info-item {
		font-size: 18px;
		line-height: 50px;
	}

	.trade-info-key {
		width: 375px;
		text-align: right;
		color: #a1a1a1;
	}

	.trade-info-val {
		width: 450px;
		text-align: left;
		margin-left: 65px;
	}

	.after-sale-info {
		line-height: 24px;
		font-size: 16px;
		padding-top: 120px;
	}

	.after-sale-info span {
		margin-right: 25px;
		color: #a1a1a1;
	}

	.after-sale-info img {
		width: 22px;
		height: 22px;
		margin: 0 3px;
		vertical-align: top;
	}

	.after-sale-info div {
		cursor: pointer;
	}

	.goods-btn {
		width: 180px;
		height: 60px;
		font-size: 17px;
	}
</style>
