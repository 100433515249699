import {
	callFeign,
	callFeignV1
} from '../api-utils/api-callFeign.js';
import commonUtlis from '../common-utlis.js';
const request = require('../request-utils.js');
const {
	getSingleParam
} = require('../api-utils/api-params.js');

/**
 * 继续支付检测并获取新订单编号（如果和PC存在支付冲突会重新生成订单编号）
 */
const getContinuePayTradeNo = async (tradeNo) => {
	return new Promise(async (resolve, reject) => {
		request.default.jackyunPost({
			method: 'jmall.trade.getcontinuepaytradeno',
			tradeNo: tradeNo,
			channelId: localStorage.getItem("pc_channelId"),
			paymentTerminal: 'pc'
		}, (res) => {
			if (res.code == 200) {
				resolve(res.result.data);
			} else {
				resolve({
					error: res.msg
				})
			}
		})
	});
}

const tradePayConfirmAndPush = (tradeNo, outTradeNo, payTotal, payType, payScene) => {
	return new Promise(async (resolve, reject) => {
		// 推尾款
		if (payScene == "3") {
			request.default.jackyunPost({
				method: 'jmall.trade.payorderfinalpayment',
				jsonStr: JSON.stringify({
					tradeNo: tradeNo,
					outTradeNo: outTradeNo,
					finalPayment: payTotal,
					payType: payType
				})
			}, (res) => {
				if (res.code == '200') {
					resolve(true);
				} else {
					resolve(false);
				}
			})
			return;
		}

		// 新推单
		request.default.jackyunPost({
			method: "jmall.trade.pushtradetooms",
			tradeNo: tradeNo,
			outTradeNo: outTradeNo,
			payTotal: payTotal,
			payNo: '',
			payType: payType
		}, (res) => {
			if (res.code == '200') {
				resolve(true);
			} else {
				resolve(false);
			}
		})
	})
}

/**
 * 判断是否校验区域限购在白名单
 */
const checkRestrictedInWhiteList = (phone) => {
	return new Promise((resolve, reject) => {
		request.default.getParam('restrictedWhitePhoneList', paramlist => {
			if (!!paramlist && !!paramlist.restrictedWhitePhoneList) {
				let restrictedWhitePhones = paramlist.restrictedWhitePhoneList.split(",");
				if (restrictedWhitePhones.includes(phone)) {
					resolve(true);
				} else {
					resolve(false)
				}
			} else {
				resolve(false);
			}
		})
	})
}

/**
 * 区域限购检测
 */
const checkRestrictedGoods = (province, city, district, specIds) => {
	return new Promise(async (resolve, reject) => {
		// 获取用户手机号
		let customerInfoStr = localStorage.getItem("pc_customerInfo");
		let customerInfo = JSON.parse(customerInfoStr);
		// 获取客户标签
		let customerTagList = (customerInfo && customerInfo.tagArr || []).join(',');
		// 检测手机号是否在白名单，是则不需要进行限购检测
		if (await checkRestrictedInWhiteList(customerInfo.phone)) {
			resolve({
				restrictedGoodsList: [],
				validSpecIds: specIds,
				restrictedInfoList: [],
			});
			return;
		}

		// 全局区域限购提示
		let restricted_explainText = localStorage.getItem('restricted_explainText');

		request.default.jackyunPost({
			method: "jmall.trade.checkrestrictedpurchase",
			province: province || '',
			city: city || '',
			district: district || '',
			specIds: specIds.join(","),
			customerTagList
		}, (res) => {
			// 限购商品列表
			let restrictedGoodsList = [];
			// 限购数据列表
			let restrictedInfoList = [];
			// 剩余可购商品规格Id
			let validSpecIds = specIds;

			// 检测限购结果，返回数据为限购数据
			if (res.code == 200 && res.result.data.length >= 0) {
				// 限购数据列表
				restrictedInfoList = res.result.data;

				// 处理限购提示 策略设置>全局设置>默认值
				restrictedInfoList.forEach(item => {
					item.limitSalesReminder = item.limitSalesReminder || restricted_explainText || '以下区域为区域限购商品，不支持在本收货地址下单'
				})

				// 限购商品列表
				restrictedGoodsList = res.result.data.map(item => item.goodsDtoList).flat(1);
				// 剩余可购商品规格Id
				validSpecIds = _getValidSpecIds(specIds, restrictedGoodsList);
			}
			// 返回结果数据
			resolve({
				restrictedGoodsList, // 限购商品
				validSpecIds, // 剩余可购规格Id
				restrictedInfoList
			});
		})
	});
}

/**
 * 过滤出有效规格信息
 * @param {*} originalSpecIds 
 * @param {*} restrictedGoodsList 
 */
const _getValidSpecIds = (originalSpecIds, restrictedGoodsList) => {
	// 获取所有限购商品的规格Id
	let restrictedSpecIds = restrictedGoodsList.map(t => t.specId);
	// 过滤出有效规格Id
	return originalSpecIds.filter(t => !restrictedSpecIds.includes(t.toString())) || [];
}


/**
 *获取订单业务员
 */
const getTradeManager = () => {
	return new Promise((resolve, reject) => {
		try {
			request.default.getParam('isDistributionAsSaleman',
				paramlist => {
					if (!!paramlist && paramlist.isDistributionAsSaleman == "1") {
						request.default.postData({
							method: "app.trade.gettrademanager",
							customer_id: localStorage.getItem("pc_customerId")
						}, (res) => {
							if (res.error_code == "0") {
								resolve({
									sellerId: res.sellId,
									sellerName: res.sellName
								})
							} else {
								resolve({
									sellerId: "",
									sellerName: ""
								})
							}
						});
					} else {
						resolve({
							sellerId: "",
							sellerName: ""
						})
					}
				});
		} catch {
			resolve({
				sellerId: "",
				sellerName: ""
			})
		}

	})

}
/**
 * 获取吉商城订单详情
 */
const getJmallTradeDetail = (tradeNo) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.tradedetail",
			tradeNo: tradeNo,
		}, (res) => {
			let data = {};
			if (res.code == 200) {
				data = res.result.data || {};
			} else {
				console.log(`订单详情获取失败（${tradeNo}）:`, res);
			}
			resolve(data);
		})
	})
}

/**
 * 如果是differ加密的对参数进行设置
 * 后续如果需要对地址以及联系人解密，可以继续沿用
 * @param {*} items 
 * @param {*} type 
 * @param {*} content 
 */
const initDecryptJson = (items, type, content) => {
	var reg = /.*~\/(\S*)\/~1~~/;
	if (reg.test(content)) {
		let plat = content.match(reg)[1];
		if (plat == "differ") {
			var decryContent = {};
			decryContent["type"] = type;
			decryContent["encryptStr"] = content;
			items.push(decryContent);
		}
	}
	return items;
}

/**
 * 客户手机号对接differ解密
 */
const getdecryDifferMobile = async (mobile) => {
	// 检测是否为differ兜底加密，是则解密，否则直接返回
	let reg = /.*~\/(\S*)\/~1~~/;
	if (!reg.test(mobile)) {
		return mobile;
	}

	let plat = mobile.match(reg)[1];
	switch (plat) {
		// 进行differ兜底解密
		case "differ":
			try {
				var decryptResult = await decryptDiffer("phone", mobile);
				mobile = decryptResult.result.data.sensitiveOrders[0].items[0].decryptStr;
				return mobile;
			} catch {}
			break;
		default:
			break;
	}
}

// differ解密
const decryptDiffer = (type, content) => {
	// 加密信息
	var decryContent = {};
	decryContent["type"] = type;
	decryContent["encryptStr"] = content;
	var items = [decryContent];
	var sensitiveOrders = {
		"items": items
	};
	var obj = {
		"encryptModel": "DIFFER"
	};
	obj["sensitiveOrders"] = sensitiveOrders;
	return new Promise(async (resolve, reject) => {
		request.default.jackyunPost({
			method: "trade.decryptdata",
			jsonStr: JSON.stringify(obj)
		}, (res) => {
			resolve(res);
		})
	})
}

// differ解密
const decryptDifferNew = (tradeNo, items) => {
	return new Promise(async (resolve, reject) => {
		// 从缓存中读取已经解密过的订单数据
		let decryptedTradeMobile = JSON.parse(localStorage.getItem("decryptedTradeMobile") || "[]");
		let decryInfo = decryptedTradeMobile.find(item => item.tradeNo == tradeNo);
		if (!!decryInfo) {
			resolve(decryInfo.mobile);
			return;
		}
		var sensitiveOrders = {
			"items": items
		};
		var obj = {
			"encryptModel": "DIFFER"
		};
		obj["sensitiveOrders"] = sensitiveOrders;

		callFeignV1("oms.trade.differdecrypt", {
			jsonStr: JSON.stringify({
				jsonStr: obj
			})
		}).then((res) => {
			if (res.code != 200 || res.result.data.isSuccess != 1) {
				console.log("笛佛订单解密失败：", res);
				resolve(null);
				return;
			}

			var sensitiveOrders = res.result.data.sensitiveOrders;
			if (sensitiveOrders[0].items[0].isSuccess != 1) {
				resolve(null);
				return;
			}

			// 将解密结果保存至缓存
			decryptedTradeMobile = decryptedTradeMobile.concat({
				tradeNo: tradeNo,
				mobile: sensitiveOrders[0].items[0].decryptStr
			});
			localStorage.setItem("decryptedTradeMobile", JSON.stringify(decryptedTradeMobile));
			resolve(sensitiveOrders[0].items[0].decryptStr);
		});
	})
}

/**
 * 包邮差价
 */
const calfreepostagedifference = (jsonStr) => {
	return new Promise(async (resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.calfreepostagedifference",
			jsonStr: jsonStr
		}, (res) => {
			resolve(res);
		})
	})
}

/**
 * 吉商城待结算订单应收合计 trade.payment
 */
const getJmallTradePayment = (tradeStatus, tradeNo, goodsName, tel, address, beginTime, endTime, sendTo) => {
	return new Promise(async (resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.tradelist.totalamount",
			customerId: localStorage.getItem("pc_customerId"),
			tradeStatusList: tradeStatus,
			tradeNoList: tradeNo,
			goodsName,
			tel,
			address,
			beginTimeStr: beginTime ? beginTime + " 00:00:00" : "",
			endTimeStr: endTime ? endTime + " 23:59:59" : "",
			sendTo
		}, (res) => {
			resolve(res.result.data);
		})
	})
}

/**
 * 吉商城oms订单应收合计 
 */
const getOmsTradePayment = (tradeStatus, tradeTimeBegin, tradeTimeEnd, notIncludeFlagIdList, goodslist, paymentLikeSelectDto, jmallAccountName) => {
	return new Promise(async (resolve, reject) => {
		request.default.jackyunPost({
			method: "trade.payment",
			jsonStr: JSON.stringify({
				customerId: localStorage.getItem("pc_customerId"),
				notIncludeFlagId: await getSearchOmsTradeListNotIncludeFlagId(),
				tradeStatus,
				goodslist,
				jmallAccountName,
				likeSelectDto: paymentLikeSelectDto,
				tradeTimeBegin: tradeTimeBegin ? tradeTimeBegin + " 00:00:00" : "",
				tradeTimeEnd: tradeTimeEnd ? tradeTimeEnd + " 23:59:59" : "",
				tradeFromList: [4, 11]
			})
		}, (res) => {
			resolve(res.result.data);
		})
	})
}

/**
 * 取消订单
 */
const cancelTrade = (tradeId) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.canceltrade",
			tradeIdList: tradeId,
			operatorName: "PC商城"
		}, res => {
			resolve(res.code == 200 && res.result.data);
		});
	});
}

/**
 * 添加订单商品库存占用信息
 */
const addTradeGoodsStockHold = (tradeId) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.addtradegoodsstockhold",
			jsonStr: JSON.stringify({
				tradeId: tradeId,
				channelId: localStorage.getItem("pc_channelId"),
			})
		}, res => {
			resolve(res.code == 200 && res.result.data);
		});
	});
}

/**
 * 释放订单商品库存占用信息
 */
const releaseTradeGoodsStockHold = (tradeId) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.releasetradegoodsstockhold",
			jsonStr: JSON.stringify({
				tradeId: tradeId,
			})
		}, res => {
			resolve(res.code == 200 && res.result.data);
		});
	});
}

/**
 * 获取支付表信息
 */
const getCombinePayInfo = (tradeNo) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.gettradepayinfo",
			jsonStr: JSON.stringify({
				tradeNo
			})
		}, (res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data || [];
			} else {
				console.log("获取支付表信息失败：", res);
			}
			resolve(data);
		})
	})
}
/**
 * 获取支付表信息
 */
const getTradeStatus = (tradeNo) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.gettradestatus",
			tradeNo
		}, (res) => {
			// 只有允许在支付的状态才返回正确结果
			resolve(res && res.result && res.result.data || -1);
		})
	})
}

/**
 * 获取待结算订单数据
 */
const getJmallTradeList = (pageIndex, pageSize, tradeStatus, keyword, tradeNo, goodsName, tel, address, beginTime, endTime, sendTo) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.tradelist",
			pageIndex,
			pageSize,
			customerId: localStorage.getItem("pc_customerId"),
			tradeStatusList: tradeStatus,
			tradeNoList: tradeNo,
			goodsName,
			tel,
			address,
			keyword,
			beginTimeStr: beginTime ? beginTime + " 00:00:00" : "",
			endTimeStr: endTime ? endTime + " 23:59:59" : "",
			queryPresaleFlag: true,
			queryStockOccupyFlag: false,
			sendTo
		}, (res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data || [];
			}
			resolve(data);
		})
	})
}

/**
 * 获取吉客云订单数据
 */
const getOmsTradeList = async (jsonObj, pageInfo, pageIndex = 0, pageSize = 15) => {
	jsonObj.isSelectTotal = 1;
	jsonObj.customerId = localStorage.getItem("pc_customerId");
	//过滤售后单
	jsonObj.notIncludeFlagId = await getSearchOmsTradeListNotIncludeFlagId();
	jsonObj.tradeFrom = [4, 11];

	//配置显示字段
	let cols = ["tradeOrder.tradeNo", "tradeOrder.tradeTime", "tradeOrder.shopName",
		"tradeOrder.warehouseName",
		"tradeOrder.logisticType", "tradeOrder.logisticName", "tradeOrder.tradeType",
		"tradeOrder.tradeStatus",
		"tradeOrder.payment", "tradeOrder.tradeCount", "tradeOrder.goodslist", "tradeOrder.mainPostid",
		"tradeOrder.tradeStatusExplain", "tradeOrderReceiver.receiverName", "tradeOrderReceiver.mobile",
		"tradeOrderReceiver.address", "sourceTradeNo", "tradeOrderInvoice.invoiceStatus",
		"tradeOrder.tradeFrom"
	];

	return new Promise((resolve, reject) => {
		callFeign("oms.trade.tradelist", {
			jsonStr: JSON.stringify({
				jsonStr: jsonObj,
				cols,
				pageInfo,
				pageIndex,
				pageSize
			})
		}).then((res) => {
			let data = [];
			if (res && res.code == 200) {
				data = res.result.data;
			} else {
				console.log("订单列表加载失败：", res);
			}
			resolve(data);
		});
	})
}

/**
 * 确认收货
 */
const confirmTradeArrive = (tradeId) => {
	return new Promise((resolve, reject) => {
		callFeignV1("oms.trade.arrive", {
			jsonStr: JSON.stringify({
				jsonStr: {
					tradeIds: [tradeId]
				}
			})
		}).then((res) => {
			if (res.code != 200 || res.msg) {
				console.log("确认收货失败", res);
			}
			resolve(res.code == 200 && res.msg == "");
		});
	})
}

/**
 * 获取吉客云订单数量
 */
const getOmsTradeCount = (jsonObj) => {
	return new Promise((resolve, reject) => {
		callFeign("oms.trade.tradecount", {
			jsonStr: JSON.stringify({
				jsonStr: jsonObj
			})
		}, "v1").then((res) => {
			let data = 0;
			if (res.code == 200 && !res.subCode) {
				data = parseInt(res.result.data || 0);
			} else {
				console.log("获取oms销售单数量失败", res);
			}
			resolve(data);
		});
	})
}

/**
 * 获取个人oms销售单数量
 * @param {*} tradeStatusList 
 */
const getPersonalOmsTradeCount = async (tradeStatusList) => {
	let accountName = localStorage.getItem("SubMemberName") || localStorage.getItem("JackyunUserName");

	let jsonObj = {
		customerId: localStorage.getItem("pc_customerId"),
		tradeStatus: tradeStatusList,
		tradeFrom: [4, 11],
		notIncludeFlagId: await getSearchOmsTradeListNotIncludeFlagId(),
		jmallAccountName: accountName
	};

	let count = await getOmsTradeCount(jsonObj);
	return count;
}

/**
 * 根据原始单号获取订单数量
 * @param {*} sourceTradeNo 
 * @param {*} tradeStatusList 
 */
const getOmsTradeCountBySourceTradeNos = async (sourceTradeNos, tradeStatusList) => {
	if (!sourceTradeNos) {
		return 0;
	}

	let tradeCount = 0;
	for (let sourceTradeNo of sourceTradeNos.split(',')) {
		let jsonObj = {
			sourceTradeNo,
			tradeStatus: tradeStatusList
		};

		let countItem = await getOmsTradeCount(jsonObj);
		tradeCount += countItem;
	}
	return tradeCount;
}

/**
 * 获取待结算订单数据
 */
const getJmallTradeCount = (tradeStatus) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.tradecount",
			customerId: localStorage.getItem("pc_customerId"),
			tradeStatusList: tradeStatus
		}, (res) => {
			try {
				resolve(res.result.data || 0);
			} catch (e) {
				resolve(0);
			}
		})
	})
}

/**
 * 获取OMS订单详情
 * @param {*} tradeNo 
 */
const getOmsTradeDetail = (tradeNo) => {
	return new Promise((resolve, reject) => {
		callFeignV1("oms.trade.tradedetail", {
			jsonStr: JSON.stringify({
				tradeNo
			})
		}).then((res) => {
			let data = {};
			if (res.code == 200) {
				data = res.result.data;
				try {
					// 处理订单中实际售价
					data.tradeOrderGoodsArr.forEach(goods => {
						// 处理真实售价（含优惠扣减和邮资）
						goods.realSellPrice = commonUtlis.floatKeepDigit((goods.sellTotal - goods.shareFavourableFee || 0) / goods.sellCount, 4);
						// 处理积分
						goods.sellPoint = commonUtlis.floatKeepDigit(parseFloat(goods.discountPoint) / parseFloat(goods.sellCount), 4);
						// 处理邮资
						goods.sellPostage = commonUtlis.floatKeepDigit(goods.sellTotal / data.tradeOrder.totalFee * data.tradeOrder.receivedPostFee / goods.sellCount, 4);

						// 将原始单号数据添加货品对象中
						let goodsOnline = data.tradeOrderGoodsRelateOnlineArr.find(t => t.subTradeId == goods.subTradeId);
						if (goodsOnline) {
							goods.sourceTradeNo = goodsOnline.sourceTradeNo || "";
						}
					});
				} catch (e) {}
			} else {
				console.log("订单详情加载失败：", res);
			}
			resolve(data);
		});
	})
}

/**
 * 订单解密（新）
 * @param {*} tradeInfo 
 */
const tradeDecrypt = async (tradeInfo) => {
	// 解密订单
	let tradeList = [tradeInfo];
	await tradeListDecrypt(tradeList);
	// 重新赋值订单信息
	tradeInfo = tradeList[0];
}

/**
 * 订单列表解密（新）
 * @param {*} tradeList 
 */
const tradeListDecrypt = async (tradeList) => {
	// 没有订单则不执行
	if (!(tradeList.length > 0)) {
		return;
	}

	var items = [];
	items = initDecryptJson(items, "phone", tradeList[0].tradeOrderReceiver.jackyunMobile || tradeList[0].tradeOrderReceiver.mobile);

	// 判断是否是differ加密
	if (tradeList.length == 1 && items.length > 0) {
		let mobile = await decryptDifferNew(tradeList[0].tradeNo, items);
		// 解密成功
		if (mobile) {
			tradeList[0].tradeOrderReceiver.mobile = mobile || "";
			tradeList[0].tradeOrderReceiver.realTel = mobile || "";
			return;
		}
	}

	// 获取需要解密的数据（手机号不存在）
	let encryptedTradeNoList = tradeList.filter(t => t.tradeOrderReceiver.mobile.includes('*')).map(t => t.tradeNo);
	if (!(encryptedTradeNoList.length > 0)) {
		return;
	}
	// 调用订单解密接口
	let decryptResultList = await _getDecryptTradeInfo(encryptedTradeNoList);
	if (!(decryptResultList.length > 0)) {
		// 没有返回解密数据则认为是商城前端加密的手机号，将mobile赋值为realTel
		encryptedTradeNoList.forEach(item => {
			let tempItem = tradeList.find(t => t.tradeNo == item);
			tempItem.tradeOrderReceiver.mobile = tempItem.tradeOrderReceiver.realTel;
		})

		return;
	}

	// 补充明文到订单信息
	for (let trade of tradeList) {
		try {
			// 不需要解密，则进行下一个
			if (!trade.tradeOrderReceiver.mobile.includes('*')) {
				continue;
			}

			// 获取解密信息没有获取到或者解密失败，则进行下一个
			let decryptData = decryptResultList.find(t => t.tradeNo == trade.tradeNo);
			if (!decryptData || decryptData.isSuccess != 1) {
				continue;
			}

			// 遍历解密结果赋值到具体的字段
			decryptData.items.forEach(d => {
				// 获取明文信息-手机号
				if (d.decryptField == "MOBILE") {
					trade.tradeOrderReceiver.mobile = d.decryptStr || trade.tradeOrderReceiver.mobile || "";
				}
				// 获取明文信息-收件人
				else if (d.decryptField == "RECEIVER_NAME") {
					trade.tradeOrderReceiver.receiverName = d.decryptStr || trade.tradeOrderReceiver.receiverName || "";
				}
				// 获取明文信息-手机号
				else if (d.decryptField == "ADDRESS") {
					trade.tradeOrderReceiver.address = d.decryptStr || trade.tradeOrderReceiver.address || "";
				}
			});
		} catch (e) {}
	}
}

/**
 * 获取订单解密数据
 */
const _getDecryptTradeInfo = async (tradeNoList) => {
	// 从缓存中读取已经解密过的订单数据
	let decryptedTradeList = JSON.parse(localStorage.getItem("decryptedTradeList") || "[]");

	// 从缓存中获取已解密结果，并且记录未解密的订单编号
	let cacheResultList = [];
	let unDecryptedTradeNoList = [];
	tradeNoList.forEach(tradeNo => {
		let decryptedData = decryptedTradeList.find(t => tradeNo == t.tradeNo);
		if (decryptedData) {
			cacheResultList.push(decryptedData);
		} else {
			unDecryptedTradeNoList.push(tradeNo);
		}
	});

	// 检测是否还存在未解密数据，没有则直接返回结果
	if (!(unDecryptedTradeNoList.length > 0)) {
		return cacheResultList;
	}

	// 解密剩余数据
	let originResultList = await __getDecryptTradeInfoFromOrigin(unDecryptedTradeNoList);

	// 将解密结果保存至缓存
	decryptedTradeList = decryptedTradeList.concat(originResultList);
	localStorage.setItem("decryptedTradeList", JSON.stringify(decryptedTradeList));

	// 返回最终结果
	return cacheResultList.concat(originResultList)
}

/**
 * 调用订单解密接口
 */
const __getDecryptTradeInfoFromOrigin = (tradeNoList) => {
	return new Promise((resolve, reject) => {
		callFeign("oms.trade.decrypt", {
			jsonStr: JSON.stringify({
				tradeNos: tradeNoList.join(','),
				from: "JMALL",
				operate: "query",
				isFuzzy: "NO"
			})
		}).then((res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data;
			}
			resolve(data);
		});
	})
}

/**
 * 获取吉商城订单详情列表（包含商品信息）
 * @param {*} tradeNos
 */
const getJmallTradeDetailList = async (tradeNos) => {
	return new Promise((resolve, reject) => {
		if (!tradeNos) {
			resolve([]);
		}

		// 生成订单详情获取请求集合
		let promiseList = [];
		tradeNos.split(",").forEach(tradeNo => {
			promiseList.push(getJmallTradeDetail(tradeNo));
		});

		// 统一请求减少时间消耗
		Promise.all(promiseList).then((res) => {
			resolve(res);
		});
	});
}

/**
 * 根据TradeIdList获取商品图片
 */
const getPicByTradeNoList = (sourceTradeNoList) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.getpicbytradeidlist",
			jsonStr: JSON.stringify(sourceTradeNoList)
		}, (res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data;
			}
			resolve(data);
		})
	})
}


/**
 * 添加订单确认收获日志
 */
const addTradeConfirmArriveLog = (tradeId, tradeNick) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.addtradelog",
			jsonStr: JSON.stringify({
				tradeId: tradeId,
				userName: tradeNick,
				logDetail: "客户确认收货"
			})
		}, (res) => {
			if (res.code != 200) {
				console.log("添加订单收货日志失败：", res);
			}
			resolve();
		})
	});
}

/**
 * 获取有效的卡券抵扣明细
 * @param {*} sourceTradeNoList 
 */
const getCouponUseDetail = async (sourceTradeNoList) => {
	// 获取卡券抵扣明细
	let couponUseDetailList = await _getCouponUseDetailFromOrigin(sourceTradeNoList);
	// 过滤返回还可以返还的卡券
	couponUseDetailList.forEach(t => {
		t.sellCount = t.sellCount - (t.returnCount || 0);
	});
	return couponUseDetailList.filter(t => t.sellCount > 0);
}

/**
 * 查询卡券使用明细
 */
const _getCouponUseDetailFromOrigin = (sourceTradeNoList) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.getcouponusedetail",
			jsonStr: JSON.stringify({
				tradeNoList: sourceTradeNoList
			})
		}, (res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data || [];
			} else {
				console.log("查询卡券使用明细", res);
			}
			resolve(data);
		})
	});
}

/**
 * 更新订单 收货地址信息
 * @param {*} tradeNo 
 * @param {*} addressInfo 
 */
const updateTradeAddressInfo = (tradeId, addressInfo) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.updatetradebaseinfo",
			tradeId: tradeId,
			jsonStr: JSON.stringify({
				sendTo: addressInfo.sendTo,
				country: addressInfo.country,
				province: addressInfo.province,
				city: addressInfo.city,
				district: addressInfo.district,
				town: addressInfo.town,
				address: addressInfo.address,
				tel: addressInfo.tel,
			})
		}, res => {
			if (res.code == 200) {
				resolve(true);
			} else {
				resolve(false);
			}
		})
	})
}

/**
 * 添加订单日志
 */
const addTradeLog = (tradeId, logDetail) => {
	return new Promise((resolve, reject) => {
		let customerInfoStr = localStorage.getItem("pc_customerInfo");
		let customerInfo = JSON.parse(customerInfoStr);
		request.default.jackyunPost({
			method: "jmall.trade.addtradelog",
			jsonStr: JSON.stringify({
				tradeId: tradeId,
				userName: customerInfo.nickname || "客户",
				logDetail: logDetail
			})
		}, (res) => {
			if (res.code != 200) {
				console.log("添加订单日志失败：", res);
			}
			resolve();
		})
	});
}

/**
 * 更新oms订单 收货地址信息
 * @param {*} tradeNo 
 * @param {*} addressInfo 
 */
const updateOmsTradeAddressInfo = (jackyunTradeNo, addressInfo) => {
	return new Promise((resolve, reject) => {
		let requestAddressDto = {
			name: addressInfo.sendTo,
			mobile: addressInfo.tel,
			phone: addressInfo.tel,
			province: addressInfo.province,
			country: addressInfo.country,
			city: addressInfo.city,
			area: addressInfo.district,
			town: addressInfo.town,
			addressDetail: addressInfo.address,
		}
		callFeign("oms.trade.modifyaddress", {
			jsonStr: JSON.stringify({
				tradeNo: jackyunTradeNo,
				buyerType: 1,
				requestAddressDto
			})
		}).then((res) => {
			if (res.code != 200) {
				console.log("修改销售单地址信息失败：", res);
			}
			resolve();
		});
	})
}

/**
 * 更新订单结算账户信息
 * @param {*} tradeNo 
 * @param {*} accountId 
 * @param {*} accountName 
 */
const updateTradeAccountInfo = (tradeNo, accountId, accountName, tradeStatus, payType) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.updatetradebaseinfo",
			tradeNo: tradeNo,
			jsonStr: JSON.stringify({
				accountId: accountId,
				accountName: accountName,
				tradeStatus: tradeStatus,
				chargeType: payType
			})
		}, (res) => {
			if (res.code == 200) {
				resolve(true);
			} else {
				resolve(false);
			}
		})
	})
}

/**
 * 订单积分扣减
 * @param {*} tradeNo 
 */
const deductTradeScore = (tradeNo) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.changetradescore",
			jsonStr: JSON.stringify({
				tradeNo,
				changeScoreType: 1, // 1 扣减，2 返还
			})
		}, (res) => {
			if (res.code != 200) {
				console.log("扣减订单积分失败：", res);
			}
			resolve(res.code == 200);
		})
	});
}

/**
 * 获取搜索OMS订单需要过滤的标记（系统标签）（默认过滤售后单，系统标记19）
 */
const getSearchOmsTradeListNotIncludeFlagId = async () => {
  // 获取配置数据
  let searchOmsTradeListNotIncludeFlagId = await getSingleParam("searchOmsTradeListNotIncludeFlagId");
  return searchOmsTradeListNotIncludeFlagId ? searchOmsTradeListNotIncludeFlagId.split(',') : [19];
}

export default {
	getContinuePayTradeNo,
	getdecryDifferMobile,
	tradePayConfirmAndPush,
	getJmallTradeDetail,
	getJmallTradeDetailList,
	getTradeManager,
	checkRestrictedGoods,
	cancelTrade,
	calfreepostagedifference,
	getJmallTradePayment,
	getOmsTradePayment,
	releaseTradeGoodsStockHold,
	addTradeGoodsStockHold,
	getCombinePayInfo,
	getTradeStatus,
	getJmallTradeList,
	getOmsTradeList,
	confirmTradeArrive,
	getOmsTradeCount,
	getJmallTradeCount,
	getOmsTradeDetail,
	tradeDecrypt,
	tradeListDecrypt,
	getPicByTradeNoList,
	getPersonalOmsTradeCount,
	getOmsTradeCountBySourceTradeNos,
	addTradeConfirmArriveLog,
	getCouponUseDetail,
	updateTradeAddressInfo,
	addTradeLog,
	updateOmsTradeAddressInfo,
	updateTradeAccountInfo,
	deductTradeScore,
	getSearchOmsTradeListNotIncludeFlagId
}