<template>
	<div>
		<!-- <div>规格选择</div> -->
		<!-- <div class="attr-div" v-if="noAttrSpecList.length>1 || (noAttrSpecList.length == 1 && showSingleSpec)"> -->
		<div class="attr-div" v-if="noAttrSpecList.length > 0">
			<div class='attr-name'>规格</div>
			<div class='attr-flex '>
				<div v-for="(item, attr_index) in noAttrSpecList" :key="attr_index" class='attr-box' v-bind:class='{attrselected: selectedSpecID == item.specId, attrdisabled: noAttrSpecDisabled}' :data-name='item.specName' :data-index='attr_index' :data-specid='item.specId' @click='specTap'>
					<span class='attr-nametext' :title="item.specName && item.specName.length > 20 ? item.specName : ''">{{item.specName}}</span>
				</div>
			</div>
		</div>
		<div v-for="(item, attr_index) in attrList" :key="attr_index">
			<div v-if="item.attrName != lastAttrName">
				<div class='attr-name'>{{item.attrName}}</div>
				<div class='attr-flex'>
					<div v-for="(subItem, sku_index) in item.attrValue" :key="sku_index">
						<div class='attr-box' v-bind:class='{attrselected: sku_index == item.selected, attrdisabled: subItem.disabled}' :data-name='subItem.value' :data-attrindex='attr_index' :data-skuindex='sku_index' :data-attrname='subItem.value' @click='attrTap'>
							<el-image v-if="item.attrName=='颜色' && colorImgMap.get(subItem.value)" class="color-img" fit='scale-down' :title='subItem.value' :alt='subItem.value' :src="$utils.setImg(colorImgMap.get(subItem.value))"></el-image>
							<span v-else class='attr-nametext' :title="subItem.value && subItem.value.length > 20 ? subItem.value : '' ">{{subItem.value}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="lastAttrList.length > 0">
			<div class='attr-name'>{{lastAttrName}}</div>
			<div class='attr-table flex-row'>
				<div class="attr-first-col">
					<div class="attr-first-row"></div>
					<div class="attr-td" v-if="sizeSpecList.filter((item)=>!!item.validityDate).length>0 && (sizeSpecList[0].validityDateObj.validateFrom != 'bathInfo' || sizeSpecList[0].validityDateObj.isShowValidateBySplitType != '1')">有效期</div>
					<div v-else-if="sizeSpecList.filter((item)=>!!item.validityDate).length>0">
						<div class="attr-td" style="width: 63px;">生产日期</div>
						<div class="attr-td">保质期</div>
					</div>
					<div class="attr-td price-td price-el">价格</div>
					<div class="attr-td" v-if="isShowStock">库存</div>
					<div class="attr-td h40">数量</div>
				</div>
				<div class="attr-tbody flex-row min-scroll">
					<div class="attr-row" v-for="(size, size_index) in lastAttrList" :key="size_index">
						<div class="attr-first-row">{{size.value}}</div>
						<div class="attr-td-column" v-for="(specItem, specIndex) in sizeSpecList" :key="'spec' + size_index + specIndex" v-if="!size.disabled && (specItem[lastAttrName] == size.value || specItem.specId == selectedSpecID)">
							<!-- 有效期 -->
							<div class="attr-td flex-row" v-show="specItem.validityDate && (specItem.validityDateObj.validateFrom != 'bathInfo' || specItem.validityDateObj.isShowValidateBySplitType != '1')">
								<div class="attr-inner onlyOneRow">{{specItem.validityDate}}</div>
							</div>
							<div v-show="specItem.validityDate && (specItem.validityDateObj.validateFrom == 'bathInfo' && specItem.validityDateObj.isShowValidateBySplitType == '1')">
								<div class="attr-td flex-row">
									<div class="attr-inner onlyOneRow">{{specItem.validityDateObj.productionDate}}</div>
								</div>
								<div class="attr-td flex-row">
									<div class="attr-inner onlyOneRow">{{specItem.validityDateObj.shelfLife || ""}}{{specItem.validityDateObj.shelfLiftUnitName || ""}}</div>
								</div>
							</div>
							<!-- 价格 -->
							<div class="attr-td price-td flex-row" v-show="isShowPrice" v-if="!(specPriceInfoList[specItem.specId + unitName]?specPriceInfoList[specItem.specId + unitName].isTieredPricing:false)">
								<div class='attr-inner'>{{$currency.symbol}}{{$utils.filterTrailingZeros(specPriceInfoList[specItem.specId + unitName].priceMember)}}</div>
							</div>
							<div class="attr-td price-td2 flex-row price-el" v-show="isShowPrice" v-else>
								<div class='attr-inner'>
									<div v-calcPriceHeight="specPriceInfoList[specItem.specId + unitName].tieredPricingList" v-for="(item,index) in specPriceInfoList[specItem.specId + unitName].tieredPricingList" :key="index">
										<div class="tiered-pricing-item" :class="attrItemWidthClass">
											<span class="pricing-item-num" v-if="_changeTieredData(item.limitCount) ==_changeTieredData(item.upperLimitCount)">
												{{_changeTieredData(item.limitCount)}}{{item.unitName}}
											</span>
											<span class="pricing-item-num" v-else-if="item.upperLimitCount<999999">
												{{_changeTieredData(item.limitCount)}}-{{_changeTieredData(item.upperLimitCount)}}{{item.unitName}}
											</span>
											<span class="pricing-item-num" v-else>
												≥{{_changeTieredData(item.limitCount)}}{{item.unitName}}
											</span>
											<span class="pricing-item-price" :class="item.isActive?'active-price':''">{{$currency.symbol}}{{$utils.filterTrailingZeros(item.executionPrice)}}</span>
										</div>
									</div>
								</div>
							</div>
							<!-- 库存 -->
							<div class="attr-td flex-row" v-if="isShowStock">
								<div class='attr-inner'>{{distinctStock == '1' ? (Math.floor(specItem.stockInfoList.find(item=>item.countRate==1).stock / unitCountRate) > 0 ? '充足' : '缺货') : Math.floor(specItem.stockInfoList.find(item=>item.countRate==1).stock / unitCountRate)}}</div>
							</div>
							<!-- 数量 -->
							<div class="attr-td h40 flex-row">
								<el-input class="spec-order-input attr-inner" maxlength="10" v-model="specItem.goodsCount" :max="Math.floor(specItem.canOrderCount / unitCountRate)" :disabled="!ignoreMaxStock && specItem.canOrderCount <= 0" @input="(newVal) => {changeGoodsCount(newVal,specIndex)}" @focus="emitFoucs(specItem.specId)"></el-input>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="display: none;">已选：{{selectedCount}}</div>
	</div>
</template>

<script>
	import {
		getParam
	} from '../../utils/api-utils/api-params.js';
	export default {
		props: {
			goodsSpec: Array,
			selectedID: String,
			unitCountRate: String,
			unitName: String,
			marketinggoods: Array,
			priceList: Array,
			distinctStock: String, // 模糊库存
			showSingleSpec: {
				type: Boolean,
				default: true
			},
			isShowPrice: {
				type: Boolean,
				default: false
			},
			ignoreMaxStock: {
				type: Boolean,
				default: false
			},
			goodsSelectionActionType: {
				type: String,
				default: "0"
			},
			baseUnit: String
		},

		watch: {
			goodsSpec(newValue, oldValue) {
				this.specList = JSON.parse(JSON.stringify(newValue));
				this.noAttrSpecDisabled = false;
				this.initData();
				this.reloadSpecPrice();
			},
			unitCountRate(newVal, oldVal) {
				this.reloadSpecPrice();
			}
		},
		computed: {
			// 尺码列表
			lastAttrList() {
				let sizeList = [];
				let sizeInfo = this.attrList.find((item) => {
					return item.attrName == this.lastAttrName
				});

				if (sizeInfo && sizeInfo.attrValue.length > 0) {
					let can_choose_count = sizeInfo.attrValue.filter(subItem => {
						return !subItem.disabled
					}).length;
					if (can_choose_count > 0) {
						sizeList = sizeInfo.attrValue;
					}
				}


				return sizeList.length > 0 ? sizeList : [{
					value: '默认',
					disabled: false
				}];
			},
			// 剩余可选规格
			sizeSpecList() {
				// if (!this.priceList || this.priceList.length <= 0 || !this.specList || this.specList.length <= 0) {
				// 	return [];
				// }

				let unSelAttr = []; // 未选规格
				let leftSpecList = this.specList; // 剩余可选规格列表

				if (this.selectedSpecID) {
					// 满足当前已选属性，已经确定规格，
					let singleSpec = this.specList.find((specItem) => {
						return specItem.specId == this.selectedSpecID;
					});
					leftSpecList = [singleSpec];
				} else {
					// 获取满足当前已选属性，但是未确定规格
					for (let attr of this.attrList) {
						let valueIndex = attr.selected;
						if (valueIndex != -1) {
							leftSpecList = leftSpecList.filter((specItem) => {
								return specItem[attr.attrName] == attr.attrValue[valueIndex].value;
							})
						}
					}
				}

				// 当只有一个属性的时候 initData会给attrList[0]的selected设为0 这时候应该返回specList
				if (this.attrList.length == 1) {
					return this.specList;
				}

				if (this.selectedSpecID || !!this.lastAttrName) {
					return leftSpecList;
				} else {
					return [];
				}


			},
			// 已填写规格数量总和
			selectedCount() {
				let totalCount = 0;
				let stockError = [],
					priceError = '';
				this.specList.forEach((item) => {
					if (item.goodsCount && item.goodsCount > 0) {
						totalCount += parseInt(item.goodsCount);
						if (!this.ignoreMaxStock && parseInt(item.goodsCount) > Math.floor(item.canOrderCount / this.unitCountRate)) {
							stockError.push(item.specName);
						}
					}
				})
				this.$emit('selectSpec', {
					selectedSpeclist: this.specList.filter((item) => {
						return item.goodsCount > 0
					}),
					totalCount,
					error: totalCount == 0 ? '请选择规格，填写订购量' : (stockError.length > 0 ?
						`规格[${stockError.join('，')}]库存不足` : '')
				});
				return totalCount;
			},

			/**
			 * 当前规格响应的价格信息（包括正常价格和阶梯价）
			 */
			specPriceInfoList() {
				// // 价格或者规格有缺失的情况则不执行
				// if (!this.priceList || this.priceList.length <= 0 || !this.specList || this.specList.length <= 0 || !this
				// 	.unitName) {
				// 	return {};
				// }
				let priceList = this.priceList;
				let unitName = this.unitName;

				let priceInfo = {};
				this.specList.forEach(spec => {
					// 获取基础价格信息
					let basePrice = priceList.find(p => p.skuId == spec.specId && p.unitName == unitName) || {};

					// 添加会员价和零售价
					let priceInfoItem = {
						priceMember: Number(basePrice.priceMember) || 0,
						priceDetail: Number(basePrice.priceDetail) || 0
					}
					// 生成阶梯价
					priceInfoItem.tieredPricingList = this._getTieredPricingList(spec
						.specId, unitName, priceInfoItem.priceMember);
					priceInfoItem.isTieredPricing = priceInfoItem.tieredPricingList.length > 1;

					priceInfo[spec.specId + unitName] = priceInfoItem;
				});

				return priceInfo;
			},

			/**
			 * 属性宽度class（根据数量控制）
			 */
			attrItemWidthClass() {
				let length = 1;
				try {
					length = this.lastAttrList.length;
					length = length > 3 ? 3 : length;
				} catch (e) {}
				return `attr-width-${length}`;
			},
		},
		data() {
			return {
				specList: [], //父组件传递的数据
				attrList: [], // 属性集合，用于前端选项展示
				noAttrSpecList: [], // 没有属性的自定义规格列表
				noAttrSpecDisabled: false, // 没有属性的自定义规格是否可选
				selectedSpecID: 0, // 选中的自定义规格ID
				colorImgMap: new Map(), // 颜色图片键值Map
				selectedColorName: "",
				marketingGoodsinfo: {},
				lastAttrName: "", // attrList的最后一个属性的name
				isShowStock: false,
			};
		},
		directives: {
			calcPriceHeight: {
				inserted: (el, binding) => {
					window.priceElHeightCount = window.priceElHeightCount > (binding.value && binding.value.length) ? window.priceElHeightCount : binding.value.length;
					let priceEl = document.querySelectorAll(".price-el");
					priceEl.forEach(item => {
						item.style.height = window.priceElHeightCount * 30 + "px";
					})
				},
			}
		},
		created() {
			window.priceElHeightCount = 0;
			// 获取配置
			this._getParam();

			this.initData();


		},
		methods: {
			/**
			 * 获取配置
			 */
			async _getParam() {
				// 获取配置信息
				let paramList = await getParam("stockShowPageSelect");
				this.isShowStock = (paramList.stockShowPageSelect || "goodsList,goodsDetail,shopcart").includes('goodsDetail');
			},

			/**
			 * 生成阶梯价列表
			 * @param {Object} specId
			 * @param {Object} unitName
			 * @param {Object} basePrice
			 */
			_getTieredPricingList(specId, unitName, basePrice) {
				// 获取当前符合要求的营销活动数据
				let marketingInfoList = (this.marketinggoods || []).filter(t => t.specId == specId && t.unitName ==
					unitName);

				// 标记是否执行基础单位的策略
				let isDoBaseUnitMarketing = false;

				// 不存在 且 促销设置的单位为基础单位 则通过基础单位在进行选择 
				if (marketingInfoList.length == 0) {
					let countRate = parseFloat(this.unitCountRate);
					marketingInfoList = (this.marketinggoods || []).filter(t => t.specId == specId && t.unitName == this.baseUnit);


					marketingInfoList = marketingInfoList.map(item => {
						// 执行价格向上保留两位小数
						item.executionPrice = Math.ceil(item.favorablePrice * countRate * 100) / 100;
						return item;
					})

					isDoBaseUnitMarketing = true;
				}
				// 获取当前单位对应的基础价格
				let defaultPricing = {
					specId,
					unitName,
					executionPrice: parseFloat(basePrice) || 0,
					limitCount: 1,
					upperLimitCount: 99999999,
					isHideNum: true,
					marketingId: 0
				};

				// 阶梯价展示列表
				let tieredPricingList = [];

				// 没有阶梯价信息，则直接返回默认价格列表
				if (marketingInfoList.length <= 0) {
					tieredPricingList.push(defaultPricing);
					return tieredPricingList;
				}

				// 生成所需数据格式
				tieredPricingList = marketingInfoList.map(t => {
					t.executionPrice = parseFloat(t.executionPrice || t.favorablePrice);
					t.limitCount = parseInt(t.limitCount);
					t.upperLimitCount = parseInt(t.upperLimitCount)
					return t;
				});

				// 按照限制数量下限进行排序
				tieredPricingList = tieredPricingList.sort(this.$utils.sortBy("limitCount"));

				// 阶梯价设置项如果最小门槛是>1，则需要补充1-n的基础加部分
				if (tieredPricingList[0].limitCount > 1) {
					// 处理阶梯价未设置的部分
					defaultPricing.upperLimitCount = tieredPricingList[0].limitCount - 1;
					defaultPricing.isHideNum = false;
					tieredPricingList = [defaultPricing, ...tieredPricingList];
				}
				
				this.isDoBaseUnitMarketing = isDoBaseUnitMarketing;
				
				return isDoBaseUnitMarketing ? this._removeDuplicates(tieredPricingList) : tieredPricingList;
			},

			/**
			 * 切换辅助单位时数据去重
			 */
			_removeDuplicates(tieredPricingList) {
				const set = new Set();
				let unitCountRate = this.unitCountRate
				// 要取转换后 limitCount较大的那一组数据
				return tieredPricingList.reverse().filter(item => {
					const value = item["limitCount"];
					if (!set.has(Math.ceil(value / unitCountRate))) {
						set.add(Math.ceil(value / unitCountRate));
						return true;
					}
					return false;
				}).reverse();
			},

			_changeTieredData(data) {
				let countRate = this.isDoBaseUnitMarketing ? (this.unitCountRate || 1) : 1
				return Math.ceil(data / countRate)
			},

			initData() {
				var specList = this.specList;
				var attrList = []; //属性集合
				var objAttrMap = new Map(); //属性键值Map
				var specAttrList = []; // 当前商品规格的属性信息 mapSpecAttr 的集合，使用map是为了防止出现某个属性没有值的规格 [{"颜色":"红","尺寸":"S"}]
				var specAttrCodeList = []; // 当前商品规格的属性信息索引 [[0,0],[0,1]]
				var noAttrSpecList = []; // 没有属性的自定义规格列表
				var selectedID = this.selectedID; // 父页面指定选中规格ID
				var selectedAttrMap = new Map(); // 父页面指定选中规格属性键值对
				var colorSet = new Set(),
					colorMap = new Map(); // 颜色值
				let isSelecSpecWithAttr = this.goodsSelectionActionType != "0" // 0规格模式 1批发模式 2组合模式
				// 单规格默认选中，多规格拆分属性
				if (specList.length > 0) {
					// 单规格默认选中
					if (specList.length == 1) {
						selectedID = specList[0].specId;
					}
					this.selectedSpecID = this.selectedSpecID || selectedID;

					// 遍历所有规格，获取属性名及属性值
					for (var index = 0; index < specList.length; index++) {
						var specItem = specList[index];
						var mapSpecAttr = new Map(); // 当前规格属性信息 {"颜色":"红","尺寸":"S"}
						if (specItem.specAttr && specItem.specAttr != "{}" && isSelecSpecWithAttr) {
							// 有规格属性的规格，获取属性名及属性值
							var jsonAttr = JSON.parse(specItem.specAttr); // 规格的属性列表
							for (let attrKey in jsonAttr) {
								var attrValue = jsonAttr[attrKey];
								mapSpecAttr.set(attrKey, attrValue);
								specItem[attrKey] = attrValue;
								// 过滤重复属性名
								if (!objAttrMap.has(attrKey)) {
									objAttrMap.set(attrKey, attrValue); // 新增属性名
								} else if ((',' + objAttrMap.get(attrKey) + ',').indexOf((',' + attrValue + ',')) == -
									1) {
									var new_value = objAttrMap.get(attrKey) ? objAttrMap.get(attrKey) + "," +
										attrValue : attrValue;
									objAttrMap.set(attrKey, new_value); // 没有该属性值，则新增属性值
								}
								// 颜色图片键值对
								if (attrKey == "颜色" && !colorSet.has(attrValue)) {
									colorSet.add(attrValue);
									colorMap.set(attrValue, specItem.color_pic);
								}
							}
							mapSpecAttr.set("specIndex", index); // 当前规格在全部规格列表中的索引
							specAttrList.push(mapSpecAttr);

							// 父页面指定选中某个自定义规格
							if (!!selectedID && selectedID == specItem.specId) {
								selectedAttrMap = mapSpecAttr;
							}
						} else {
							// 无规格属性的自定义规格
							noAttrSpecList.push(specItem);
							// 父页面指定选中某个自定义规格
							if (!!selectedID && selectedID == specItem.specId) {
								this.selectedSpecID = selectedID;
							}
						}
					}

					// 组装属性名称及选项，用户前端展示
					for (let item of objAttrMap) {
						let name = item[0];
						let attrValue = [];
						let arrValue = item[1].split(',');
						for (let value of arrValue) {
							attrValue.push({
								value: value,
								disabled: false
							});
						}
						var attrItem = {
							attrName: name,
							attrValue: attrValue,
							selected: -1
						};
						attrList.push(attrItem);
						this.lastAttrName = this.lastAttrName != '尺码' ? name : '尺码';
					}

					// try {
					// 	// 检测是否已经有默认选中的属性，没有则默认第一个
					// 	if (!attrList.find(item => (item.selected != -1))) {
					// 		attrList[0].selected = 0; // 选中第0个属性
					// 		this.selectedAttrIndex = 0;
					// 	}
					// } catch (e) {}

					// 已有规格对应的属性索引 列表
					for (let specItem of specAttrList) {
						var attrCode = [];
						attrList.forEach(function(attr, i) {
							if (specItem.has(attr.attrName)) {
								var find = false;
								for (var j = 0; j < attr.attrValue.length; j++) {
									if (specItem.get(attr.attrName) == attr.attrValue[j].value) {
										attrCode.push(j);
										find = true;
										break;
									}
								}
								if (!find) {
									attrCode.push(-1);
								}
							} else {
								attrCode.push(-1);
							}
						})
						specAttrCodeList.push(attrCode);
					}
				}

				this.attrList = attrList;
				this.specAttrList = specAttrList; // 所有规格属性信息 列表
				this.specAttrCodeList = specAttrCodeList; // 所有规格属性信息 索引列表
				this.noAttrSpecList = noAttrSpecList; // 没有属性自定义的规格列表
				this.colorImgMap = colorMap; // 颜色图片键值对
				this.marketingGoodsinfo = this.marketinggoods;
				// 如果有传入选中规格，属性selected
				if (selectedAttrMap.size > 0) {
					this.setAttrSelected(selectedAttrMap);
				}

				this._setLastAttrList();

				try {
					// 获取选中的属性
					let selectAttr = this.attrList.find(item => (item.selected > -1));
					this.selectedColorName = selectAttr.attrValue[selectAttr.selected].value;

					// 设置主图内容
					this._setParentCurrentImg();
				} catch (e) {
					//TODO handle the exception
				}

			},
			// 父页面传入的选中规格，选中属性 {0: {"尺寸" => "M"}
			setAttrSelected: function(selectedAttrMap, attrList) {
				var attrList = this.attrList;
				var attrIndexList = []; // 规格属性选择列表 [0,0,-1]
				var selectedAttrLength = 0; // 已选属性的个数
				// 遍历所有属性，选中当前规格属性
				for (let attr of attrList) {
					var attrName = attr.attrName;
					if (selectedAttrMap.has(attrName)) {
						for (var valueIndex = 0; valueIndex < attr.attrValue.length; valueIndex++) {
							var attrValue = attr.attrValue[valueIndex].value;
							if (selectedAttrMap.get(attrName) == attrValue) {
								attr.selected = valueIndex; // 选中此属性选项
								selectedAttrLength++;
								break;
							}
						}
					}
					attrIndexList.push(attr.selected);
				}

				// 有属性选择时，判断剩余属性是否可选，不可选则置灰，否则保持
				if (selectedAttrLength > 0) {
					this.setAttrCanChooseOrNot(attrIndexList, selectedAttrLength);
				}

				this.attrList = attrList;
			},

			// 规格选择
			specTap: function(e) {
				if (!this.noAttrSpecDisabled) {
					let specId = e.currentTarget.dataset.specid; // 当前选中值
					this.reloadSpecPrice();
					if (this.selectedSpecID == specId) {
						// 取消选择，属性全部置为可选
						this.selectedSpecID = 0;
						this.setSpecAttrDisabled(false);
					} else {
						// 选择自定义规格，属性全部置为不可选
						this.selectedSpecID = specId;
						this.setSpecAttrDisabled(true);
					}
				}
			},

			// 规格属性设置是否可选
			setSpecAttrDisabled: function(disabled) {
				var attrList = this.attrList;
				attrList.forEach(function(attr, index) {
					for (var j = 0; j < attr.attrValue.length; j++) {
						attr.attrValue[j].disabled = disabled;
					}
				})
				this.attrList = attrList;
			},

			// 属性选择
			attrTap: function(e) {
				var attrList = this.attrList; // 规格属性集合
				let attrindex = e.currentTarget.dataset.attrindex; // 当前点击的属性索引
				let skuindex = e.currentTarget.dataset.skuindex; // 当前点击的属性值索引
				let disabled = attrList[attrindex].attrValue[skuindex].disabled; //判断是否可选
				this.selectedColorName = e.currentTarget.dataset.attrname; // 当前选中的属性名称
				this.selectedAttrIndex = attrindex; // 当前点击的属性索引
				this.selectedAttrValueIndex = skuindex; // 当前点击的属性值索引

				if (!disabled) {
					// 选中或取消选中当前点击选项
					attrList[attrindex].selected = (attrList[attrindex].selected == skuindex) ? -1 : skuindex;

					// 取消选中时 重置selectedAttrIndex
					this.selectedAttrIndex = attrList[attrindex].selected == -1 ? "" : this.selectedAttrIndex;
					this.isCancelSelect = attrList[attrindex].selected == -1;

					// 获取所有已选属性值，并将所有规格属性选项置为可选
					var attrList = this.attrList;
					var attrIndexList = []; // 规格属性选择列表 [0,0,-1]
					var selectedAttrLength = 0; // 已选属性的个数
					attrList.forEach(function(attr, index) {
						// 所有规格属性置为可选
						for (var j = 0; j < attr.attrValue.length; j++) {
							attr.attrValue[j].disabled = false;
						}
						if (attr.selected != -1) {
							selectedAttrLength++;
						}
						attrIndexList.push(attr.selected);
					})

					// 判断自定义规格是否可选
					this.setNoAttrSpecDisabled(selectedAttrLength > 0);

					// 有属性选择时，判断剩余属性是否可选，不可选则置灰，否则保持
					if (selectedAttrLength > 0) {
						this.setAttrCanChooseOrNot(attrIndexList, selectedAttrLength);
					}

					this.attrList = attrList;

					// 返回当前规格选择
					this.returnSelectedAttrSpec(selectedAttrLength);
				}

				// 设置 lastAttrList
				this._setLastAttrList();

				// 设置主图内容
				this._setParentCurrentImg();
			},

			// 选中属性后 判断是否展示lastAttrList
			_setLastAttrList: function() {
				// 当前点击的属性索引 selectedAttrIndex
				// 当前点击的属性值索引selectedAttrValueIndex
				if (this.isCancelSelect) {
					this.lastAttrName = "";
					return
				}

				// 过滤当前属性
				let tempAttrList = this.attrList.filter((item, index) => index != this.selectedAttrIndex);

				// 判断剩余属性是否存在可选属性值 过滤掉不存在可选的和已选择过的
				tempAttrList = tempAttrList.filter(item => !!item.attrValue.find(attrV => !attrV.disabled) && item.selected == -1);

				if (tempAttrList.length == 0 || tempAttrList.length > 1) {
					this.lastAttrName = "";
				} else {
					this.lastAttrName = tempAttrList[0].attrName;
				}
			},

			// 自定义规格设置是否可选
			setNoAttrSpecDisabled: function(disabled) {
				this.noAttrSpecDisabled = disabled;
			},

			// 有属性选择时，判断剩余属性是否可选
			setAttrCanChooseOrNot: function(attrIndexList, selectedAttrLength) {
				var attrList = this.attrList;
				for (var index = 0; index < attrList.length; index++) {
					var attr = attrList[index];
					if (attr.selected == -1) {
						// 未选择的属性，所有属性值与所有已选择的属性匹配，匹配到则可选
						for (var valueIndex = 0; valueIndex < attr.attrValue.length; valueIndex++) {
							this.filterSpecCanChoose(index, valueIndex, attrIndexList);
						}
					} else if (selectedAttrLength > 1) {
						// 如果有多个已选属性，需要判断选择过的属性 其他值是否可选
						// 已选择的属性，所有值（除了当前选中）与其他已选择的属性匹配，匹配到则可选
						for (var valueIndex = 0; valueIndex < attr.attrValue.length; valueIndex++) {
							if (valueIndex != attr.selected) {
								this.filterSpecCanChoose(index, valueIndex, attrIndexList);
							}
						}
					}
				}
			},

			// 过滤可选的属性值
			filterSpecCanChoose: function(attr_index, valueIndex, attrIndexList) {
				var attrList = this.attrList; // 属性及选择项列表
				var specList = this.specAttrCodeList; // 所有规格属性信息 索引列表

				//当前判断的属性项先设置为不可选
				attrList[attr_index].attrValue[valueIndex].disabled = true;

				//遍历所有规格，判断当前属性是否可选
				for (var i = 0; i < specList.length; i++) {
					var specItem = specList[i];
					var selectedSize = 0; // 已选中的属性数
					var findSize = 0; // 当前规格匹配的属性数
					for (var j = 0; j < attrIndexList.length; j++) {
						if (j != attr_index && attrIndexList[j] != -1) {
							if (specItem[j] == attrIndexList[j]) {
								findSize++;
							}
							selectedSize++;
						}
					}
					if (findSize == selectedSize && specItem[attr_index] == valueIndex) {
						// 当前规格与所有已选的属性匹配 并且 与当前属性也匹配，则表示当前属性项可选
						attrList[attr_index].attrValue[valueIndex].disabled = false;
					}
				}
			},

			// 判断当前所有已选规格属性组合，是否有对应的skuID,有则将结果返回父页面，无则返回0
			returnSelectedAttrSpec: function(selectedAttrLength) {
				//没有选中任何属性，直接返回
				if (selectedAttrLength > 0) {
					var specAttrList = this.specAttrList; // 所有有属性的规格 属性值键值对[{0: {"尺寸" => "M"} 1: {"specIndex" => 0}}]
					var attrList = this.attrList; // 属性列表
					var selectedAttrMap = new Map(); // 当前选中的所有属性键值对 {"尺寸" => "M"}
					var unSelAttr = []; // 没有选择的属性名
					for (var attr of attrList) {
						var valueIndex = attr.selected;
						if (valueIndex != -1) {
							selectedAttrMap.set(attr.attrName, attr.attrValue[valueIndex].value);
						} else {
							unSelAttr.push(attr.attrName);
						}
					}

					var haveMatched = false; // 当前选中的属性是否已经匹配到某规格
					// 遍历所有规格及属性，匹配规格
					for (var specItem of specAttrList) {
						// 除去specItem中的specIndex键值对，如果和已选的键值对相同则匹配
						if (selectedAttrMap.size == (specItem.size - 1)) {
							var findIndex = 0; // 匹配到的属性数量
							for (var attrItem of selectedAttrMap) {
								let attrKey = attrItem[0];
								let arrValue = attrItem[1].split(',');
								if (specItem.has(attrKey) && specItem.get(attrKey) == arrValue) {
									findIndex++;
								} else {
									break;
								}
							}
							// 所有已选属性都相同，则匹配，返回规格索引
							if (findIndex == selectedAttrMap.size) {
								this.selectedSpecID = this.specList[specItem.get("specIndex")].specId;
								this.reloadSpecPrice();
								haveMatched = true;
								break;
							}
						}
					}

					// 没有匹配的规格
					if (!haveMatched) {
						this.selectedSpecID = 0;
					}
				} else {
					this.selectedSpecID = 0;
				}
			},

			// 重新加载规格价格信息
			reloadSpecPrice() {
				let unitName = this.unitName; // 当前选中的单位        
				let countRate = this.unitCountRate ? parseFloat(this.unitCountRate) : 1; // 单位比例        
				let commissionMode = localStorage.getItem('pc_commissionMode'); // 分销模式
				//2022/5/30 促销活动
				// 购买数量
				let sizeSpec = this.sizeSpecList.find(item => item.specId == this.selectedSpecID);
				let goodCount = 0;
				if (!!sizeSpec && !!sizeSpec.goodsCount) {
					let minCount = sizeSpec.min_count;
					minCount = minCount > 1 ? parseInt(Number(minCount) / countRate) : 1;
					goodCount = sizeSpec.goodsCount < minCount ? minCount : sizeSpec.goodsCount;
					sizeSpec.goodsCount = goodCount;
				}
				if (!!goodCount) {
					// 选中规格
					let selectedSpecID = this.selectedSpecID;
					// 选择的规格详情
					let selectedSpecInfo = this.specList.find(specItem => specItem.specId == selectedSpecID);
					// 获取商品详情
					let marketingGoodsInfo = this.marketinggoods;

					// 分销价格
					if (commissionMode == "1" && selectedSpecInfo.customer_disprice) {
						selectedSpecInfo.priceShown = parseFloat(selectedSpecInfo.customer_disprice) * countRate;
					} else if (selectedSpecInfo.UnitPriceList) {
						let marketing_price = selectedSpecInfo.priceDetail;
						// 获取当前单位的价格信息
						let skuPriceList = this.priceList;
						if (skuPriceList.length > 0) {
							let customerPrice = skuPriceList.find((priceInfo) => priceInfo.skuId == selectedSpecID &&
								unitName == priceInfo
								.unitName).priceMember;
							marketing_price = customerPrice;
						}
						if (marketingGoodsInfo) {
							{
								let marketing_goods = marketingGoodsInfo.find(f => f.specId == this.selectedSpecID && f
									.unitName == unitName && parseInt(f.limitCount) <= parseInt(goodCount));
								marketing_price = !!marketing_goods ? marketing_goods.favorablePrice : marketing_price;
							}
						}
						selectedSpecInfo.priceShown = marketing_price;
						return;
					} else {
						let skuPriceList = this.priceList;
						if (skuPriceList.length > 0) {
							let customerPrice = skuPriceList.find((priceInfo) => priceInfo.skuId == selectedSpecID &&
								unitName == priceInfo
								.unitName).priceMember;
							selectedSpecInfo.priceShown = customerPrice;
						} else {
							selectedSpecInfo.priceShown = selectedSpecInfo.priceDetail;
						}

					}
				} else {
					// 兼容过去价格设置习惯
					// 获取规格价格信息
					this.specList.forEach((specItem) => {
						if (commissionMode == "1" && specItem.customer_disprice) {
							specItem.priceShown = parseFloat(specItem.customer_disprice) * countRate;
						} else if (specItem.UnitPriceList) {
							let priceInfo = specItem.UnitPriceList.find((item) => {
								return item.unitName == this.unitName
							});
							if (priceInfo && parseFloat(priceInfo.priceMember) != 0) {
								specItem.priceShown = priceInfo.priceMember;
							} else {
								priceInfo = specItem.UnitPriceList.find((item) => {
									return item.unitName == specItem.unit
								});
								specItem.priceShown = priceInfo ? parseFloat(priceInfo.priceMember) * countRate :
									0;
							}
						} else {
							specItem.priceShown = specItem.priceDetail;
						}
					})
				}
			},

			// 修改订购量
			changeGoodsCount(value, index) {
				let newVal = value;
				if (value) {
					newVal = value < 0 ? '0' : value;
					newVal = Number(newVal.replace(/[^\d]/g, ""));
					// 没有开启模糊库存，不能大于最大购买量，也不能小于0
					if (this.distinctStock != "1" && !this.ignoreMaxStock) {
						let max = Math.floor(parseFloat(this.sizeSpecList[index].canOrderCount) / this.unitCountRate);
						newVal = (newVal > max && max > 0) ? max : newVal;
					}
					let minCount = parseInt(this.sizeSpecList[index].minCount);
					let stepCount = parseInt(this.sizeSpecList[index].stepCount);
					let countRate = this.unitCountRate ? parseFloat(this.unitCountRate) : 1; // 单位比例	
					minCount = parseInt(Number(minCount) / countRate);
					minCount = minCount > 1 ? minCount : 1;
					if (newVal < minCount) {
						newVal = minCount;
					} else if ((newVal - minCount) % stepCount > 0) {
						newVal = minCount;
						this.$message.error("购买数量必须为单次购买量" + stepCount + "的倍数加最小起购量")
					}
				}

				this.sizeSpecList[index].goodsCount = newVal;
				let tmp = this.selectedSpecID;
				this.selectedSpecID = this.sizeSpecList[index].specId;
				this.reloadSpecPrice();
				this.selectedSpecID = tmp;

				// 阶梯价命中效果，并添加阶梯价信息到结果
				let currSpecId = this.sizeSpecList[index].specId;
				let currUnitName = this.unitName;
				// 获取命中的阶梯价
				try {
					// 当前操作对象对应的阶梯价
					let currTieredPricingList = this.specPriceInfoList[currSpecId + currUnitName].tieredPricingList;
					// 置为未命中
					currTieredPricingList.forEach(t => {
						t.isActive = false;
					});
					// 获取命中的价格
					let activePricing = currTieredPricingList.find(t => newVal >= this._changeTieredData(t.limitCount) && newVal <= this._changeTieredData(t.upperLimitCount));
					if (activePricing) {
						// 当前价格置为命中
						activePricing.isActive = true;
						// 金额等信息赋值的返回结果
						this.sizeSpecList[index].priceShown = activePricing.executionPrice;
						this.sizeSpecList[index].score = 0;
					}
				} catch (e) {}
			},

			/**
			 * 设置当前页面上主图内容
			 */
			_setParentCurrentImg: function() {
				try {
					// 获取选中规格数据，得到颜色图片细腻
					this.$emit("setCurrentImg", this.colorImgMap.get(this.selectedColorName));
				} catch (e) {
					this.$emit("setCurrentImg", "");
				}
			},
			rebuildSpecSelect(specSelectedList) {
				this.specList = this.specList.map(item => {
					let specSelectInfo = specSelectedList.find(f => f.specId == item.specId && f.unitName == item.unit);
					if (specSelectInfo) {
						item.goodsCount = specSelectInfo.goodsCount;
					} else {
						item.goodsCount = 0;
					}
					return item;
				})
			},

			emitFoucs: function(specId) {
				this.$emit('foucsedSpec', specId);
			},

		},
	}
</script>

<style scoped="scoped">
	.attr-table {
		margin: 20px 0;
		border: solid 1px #d5d5d5;
		overflow: hidden;
	}

	.attr-first-row {
		height: 36px;
		line-height: 36px;
		background-color: #f2f2f2;
		text-align: center;
		min-width: 50px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.attr-first-col {
		width: 80px;
		text-align: center;
	}

	.attr-td {
		line-height: 25px;
		padding: 0 10px;
		margin: 10px 0;
		text-align: center;
	}

	.attr-tbody {
		width: calc(100%);
		overflow-x: auto;
		overflow-y: hidden;
	}

	.attr-row {
		flex: 1;
		min-width: 203px;
		/* 产品要求一行最多3个 */
	}

	.spec-order-input {
		width: 100%;
		max-width: 100px;
	}

	/deep/.el-input__inner {
		padding: 0 !important;
		text-align: center !important;
	}

	.h40 {
		height: 40px;
		line-height: 40px;
	}

	.attr-flex {
		padding: 10px 0;
	}

	.attr-box {
		margin: 10px 20px 10px 0;
	}

	.flex-row {
		display: flex;
		flex-direction: row;
		align-items: normal;
	}

	.tiered-pricing-item {
		width: calc(100% - 60px);
		padding: 0 30px;
		height: 30px;
		margin: auto;
	}

	.pricing-item-num {
		float: left;
	}

	.pricing-item-price {
		float: right;
		color: #A6A6A6;
	}

	.active-price {
		color: #FF5757;
	}

	.price-td {
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.price-td2 {
		/* height: 30px; */
		display: flex;
		align-items: stretch;
		justify-content: space-evenly;
	}

	.attr-inner {
		margin: auto;
		width: 80%;
	}

	.attr-td-column {
		height: 100%;
	}

	.attr-width-1 {
		max-width: 170px;
	}

	.attr-width-2 {
		max-width: 150px;
	}

	.attr-width-3 {
		max-width: 120px;
	}

	.attr-nametext {
		max-width: 400px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.onlyOneRow {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>