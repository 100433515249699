<!--搜索-->
<template>
	<div class="search-box">
		<el-input v-model="searchValue" @focus="changeHistory(true)" :placeholder="showPlaceholder?placeholder:''" @keyup.13.native="emitSearch">
			<el-button type="danger" slot="append" @click="emitSearch" icon="el-icon-search">
			</el-button>
		</el-input>
		<div v-show="historyShow" class="history-box">
			<div class="search-history_null" v-if="history.length===0&&historyShow">
				无历史搜索记录
			</div>
			<ul v-else class="search-history">
				<li v-for="(item,index) in history" :data-index="index" class="search-history_item">
					<div class="item-text" @click="historySearch(index)">{{item}}</div>
					<div class="delete item-delect" id="item-delect" @click="singleClear(index) " v-hover>搜索历史</div>
				</li>
				<li class="search-history_item  delete alldelete" @click="clearHistory()">全部删除</li>
			</ul>
		</div>

	</div>

</template>

<script>
	export default {
		name: 'search',
		props: ["showPlaceholder"],
		directives: {
			// 在模板中启用 v-focus
			hover: {
				bind(el) {
					el.addEventListener("mouseenter", (e) => {
						el.innerText = "删除"
					});
					el.addEventListener("mouseleave", (e) => {
						el.innerText = "搜索历史"

					});
				},
			}
		},
		data() {
			return {
				searchValue: "", //搜索内容
				history: [], //搜索历史
				historyShow: false, //历史搜索的显示控制
				historyClick: false,
				deleteText: "搜索历史",
				placeholder: ""
			}
		},
		created() {


		},
		mounted() {

		},
		activated() {
			//设置历史搜索数据
			let history = this.getHistoryStorage() === null ? [] : this.getHistoryStorage()
			this.history = history.slice(0, 20)
			this.searchValue = ""
			if (this.showPlaceholder) {
				this.placeholder = history[0]
			}
			let searchBox = document.querySelector(".search-box")
			searchBox.addEventListener("mouseleave", (e) => {
				this.historyShow = false
			});
		},
		beforeDestroy() {
			//组件销毁前，取消监听滚动事件

		},
		methods: {
			//触发父组件的搜索
			emitSearch() {
				// 清除前后空格
				let searchValue = this.searchValue.trim();
				//设置历史搜索缓存
				let history = this.history

				if (searchValue !== "") {
					history.unshift(searchValue)
				}

				this.addHistoryStorage(this.unique(history.slice(0, 20)))
				this.$emit('searchGoods', searchValue || this.placeholder)
				this.historyShow = false
				this.history = this.getHistoryStorage()
			},

			//历史搜索触发父组件的搜索
			historySearch(index) {
				//设置历史搜索缓存
				let history = this.history
				this.placeholder = history[index]
				this.searchValue = history[index]
				this.$emit('searchGoods', history[index])
				history.unshift(history[index])
				this.addHistoryStorage(this.unique(history.slice(0, 20)))
				this.historyShow = false
				this.history = this.getHistoryStorage()
			},
			//搜索添加到缓存
			addHistoryStorage(data) {
				let tempData = JSON.stringify(data)
				localStorage.setItem("searchHistory", tempData)
			},
			//获取历史搜索
			getHistoryStorage(data) {
				return JSON.parse(localStorage.getItem("searchHistory"));
			},
			//清除缓存
			clearHistory() {
				localStorage.removeItem("searchHistory");
				this.history = []
			},
			//数组去重
			unique(arr) {
				return Array.from(new Set(arr))
			},
			//单个删除
			singleClear(index) {
				this.history.splice(index, 1)
				this.addHistoryStorage(this.history)
			},

			//输入框获取 失去焦点
			changeHistory(judge) {
				if (this.placeholder && this.placeholder != "" && this.showPlaceholder) {
					this.searchValue = this.placeholder
					this.placeholder = ""
				}
				this.historyShow = judge

			},

		}
	}
</script>

<style scoped>
	.el-input {
		width: 350px !important;
	}

	.search-box {
		height: 70px;
		line-height: 70px;
		position: relative;
	}

	.history-box {
		position: relative;
		z-index: 100;
		transform: translateY(-71px);
	}

	.search-history {
		list-style: none;
		padding: 0;
		margin: 0;
		width: 290px;
		position: absolute;
		top: 56px;
		z-index: 1;
		background-color: #fff;
		border: 1px solid #ccc;
		box-shadow: 1px 2px 1px rgba(0, 0, 0, .2);
		border-bottom-left-radius: 4px;
		border-top-left-radius: 4px;

	}

	.search-history_null {
		color: #666;
		font-size: 12px;
		line-height: 24px;
		width: 290px;
		position: absolute;
		top: 56px;
		z-index: 1;
		background-color: #fff;
		border: 1px solid #ccc;
		box-shadow: 1px 2px 1px rgba(0, 0, 0, .2);
		border-bottom-left-radius: 4px;
		border-top-left-radius: 4px;
		text-align: center;

	}

	.search-history_item {
		font-size: 12px;
		line-height: 24px;
		display: flex;
		justify-content: space-between;
		padding: 1px 6px;
		cursor: pointer;
		color: #666;
	}

	.search-history_item:hover {
		background-color: #f5f5f5;
	}

	.delete {
		color: #666;
		/* display: none; */
	}

	.alldelete {
		border-top: 1px solid #efefef;
		display: flex;
		justify-content: flex-end;
	}

	.item-text {
		width: 70%;
	}

	.item-delect {
		width: 30%;
		text-align: right;
	}
</style>