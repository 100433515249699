// 主机域名
// const host = 'http://localhost:1400'; // 开发环境
// const host = window.location.origin + "/jmall_420001"; //集成测试
// const host = "http://home.es86.com:3008/jmall_420001"; //集成测试
// const host = window.location.origin + "/jmall_450001"; //系统测试
// const host = "http://home.es86.com:3008/jmall_450001"; //系统测试
// const host = window.location.origin + "/jmall_440001"; //hotfix测试
// const host = "http://home.es86.com:3008/jmall_440001"; //hotfix测试
// const host = window.location.origin + "/jmc"; // 正式环境
const host = "https://jmall.jackyun.com/jmc"; // 正式环境
// const host = window.location.origin + "/jmc_tmp"; // 正式环境
// const host = "https://jmall.jackyun.com/jmall_pc_single"; // 灰度环境

const config = {
	headUrl: host, // 接口域名
	uploadFileUrl: `${host}/Interface/FileUpload`, // 上传文件接口(OSS服务器)

	// 开发环境
	// jmallApiUrl: '/JMallApi/Post', // 吉商城数据接口
	// jackyunApiUrl: '/JackYunApi/Post', // 吉客云数据接口
	// payUrl: '/Weixin/QRCodePay', // 扫码支付请求
	// payQueryUrl: '/Weixin/PayStatusQuery', // 支付状态查询
	// jMallOpenApiUrl: `/JMallOpenApi/Post`, // 吉商城对外接口

	// 正式/测试环境
	jmallApiUrl: `${host}/JMallApi/Post`, // 吉商城数据接口
	jackyunApiUrl: `${host}/JackYunApi/Post`, // 吉客云数据接口
	payUrl: `${host}/Weixin/QRCodePay`, // 扫码支付请求
	payQueryUrl: `${host}/Weixin/PayStatusQuery`, // 支付状态查询
	jMallOpenApiUrl: `${host}/JMallOpenApi/Post`, // 吉商城对外接口
}

// 缓存key和时间
const constant = {
	REFRESH_SECOND_SYS_TIMESTAMP: 60, // 系统时间戳刷新间隔	单位：秒
	DATA_KEY_SYS_TIMESTAMP: "sysTimestamp", // 系统时间戳key
	REFRESH_SECOND_SYS_SETTING: 3600, // 系统配置刷新间隔
	DATA_KEY_SYS_SETTING: "sysSettting", // 系统配置key
	REFRESH_SECOND_MALL_ACCOUNT_INFO: 300, // 商城账号信息刷新间隔
	DATA_KEY_MALL_ACCOUNT_INFO: "mallAccountInfo", // 商城账号信息key
	REFRESH_SECOND_USER_SETTING: 180, // 用户配置刷新间隔
	DATA_KEY_USER_SETTING: "userSetting", // 用户配置key
	REFRESH_SECOND_CUSTOMER: 300, // 客户档案刷新间隔
	DATA_KEY_CUSTOMER: "pc_customerInfo", // 用户配置key
	REFRESH_SECOND_GOODS_PRICE: 300, // 商品价格刷新间隔（价格的过期时间需要精确到货品，暂时不用时效数据保存）
	DATA_KEY_GOODS_PRICE: "goodsPrice", // 商品价格key
	REFRESH_SECOND_ALL_CUSTOMER_ID: 3600, // 手机号对应的所有客户档案ID刷新间隔
	DATA_KEY_ALL_CUSTOMER_ID: "allCustomerId", // 手机号对应的所有客户档案ID key
	REFRESH_SECOND_CART_CACHE: 120, // 购物车缓存时间
	DATA_KEY_CART_CACHE: "shoppingcartList", // 购物车缓存key
	REFRESH_SECOND_CUSTOMFIELD: 300, // 货品自定义字段缓存时间
	DATA_KEY_CUSTOMFIELD: "goodsCustomField", // 货品自定义字段缓存key
}

// 配置key
const allUserSettingKeyList = [
	"isOpenExpectReciveTime",
	"auth_code",
	"appid",
	"app_token",
	"bindLogisticId",
	"bindLogisticName",
	"bindSaleChannelId",
	"bindSaleChannelName",
	"isOpenCustomerService",
	"mall_title",
	"isOpenStore",
	"sign_score",
	"sign_rule",
	"task_setting",
	"commission_mode",
	"distribution_settlement_days",
	"isAutoCheck",
	"commission_onlyfor_distributor",
	"distribution_rule",
	"bindSaleChannelCert",
	"JMallSceneType",
	"isOpenDisShop",
	"redpack_praiseMoney",
	"redpack_wishing",
	"isOpenBalancePay",
	"commonModular",
	"smsSign",
	"stockRatio",
	"bindWarehouseId",
	"maxDeliveryDistance",
	"multipleChannelInfo",
	"bOnlyLogisticInfo",
	"isOpenWxPay",
	"isOpenEWallet",
	"isOpenCityBuyLimit",
	"custom_type",
	"deliveryFreeLowerLimit",
	"weixinPaymentCodeImg",
	"weixinPayAccountId",
	"weixinPayAccountName",
	"aliPayAccountId",
	"aliPayAccountName",
	"isOpenCodePay",
	"shippingLowerLimit",
	"isMustIDCard",
	"defaultMemberGradeId",
	"defaultMemberGradeName",
	"isOpenActivityCommission",
	"currentcyType",
	"community_commission_rate",
	"community_settlement_days",
	"community_commission_rule",
	"bindLogisticList",
	"isLoginForMemberGrade",
	"loginForMemberGradeId",
	"isOpenDistributorTradeType",
	"isOnlyShowJmallTrade",
	"ignoreSkuCustomField",
	"isOpenExpectedDeliveryTime",
	"expectedDeliveryDelayedDays",
	"wx_tab_goodsNavigation",
	"isOpenCashOnDelivery",
	"is_stop_coupon_receive",
	"onlyShowCreateChannel",
	"isOpenStaffBuy",
	"staffBuySetting",
	"isOpenIndistinctStock",
	"isOpenAgreement",
	"agreementTitle",
	"agreementContent",
	"isOpenBalanceRecharge",
	"isHiddenBrandList",
	"rulesScoreDeduction",
	"isOpenScoreDeduction",
	"couponFreePostageCount",
	"isHideListStock",
	"UncheckTradeAutoRefund",
	"alipayPaymentCodeImg",
	"paymentType",
	"isOpenBondedSetting",
	"posters_isShowGoodsName",
	"posters_isShowGoodsPrice",
	"posters_isShowGoodsCode",
	"posters_isShowCompanyLogo",
	"companyLogoSrc",
	"couponExchangeRule",
	"canSelectSelfPick",
	"areaShipList",
	"isShowOriginalPriceBtn",
	"isNoFillWarehouse",
	"appIsOverseas",
	"defaultAccountName",
	"defaultAccountId",
	"isShowTradeReceiver",
	"isDistributionAsSaleman",
	"isOpenOrderSentAttachments",
	"OrderSentAttachmentsRemind",
	"isOpenAutoCheckStock",
	"wxShareImg",
	"isOpenShowDetailPrice",
	"buyLimitCities",
	"selectCategoryId",
	"isShowjackyun",
	"isOpenDeliverySubscribe",
	"isOpenSuccessPaySubscribe",
	"isOpenTeamBuySuccessSubscribe",
	"isOpenWaitPaySubscribe",
	"isOpenSuccessDistributorSubscribe",
	"Subscribe_SuccessPayTemplateId",
	"Subscribe_DeliveryTemplateId",
	"Subscribe_TeamBuySuccessTemplateId",
	"Subscribe_SuccessDistributorTemplateId",
	"Subscribe_WaitPayTemplateId",
	"thirdPartyFromJson",
	"isShowGoodsValidityDate",
	"goodsValidityDateFrom",
	"skuCustomFieldName",
	"isShowGoodsStock",
	"loginMode",
	"isHideBalanceInfo",
	"companyCollect_businessType",
	"companyCollect_remark",
	"companyCollect_memberGradeId",
	"companyCollect_memberGradeName",
	"isShowSingleSpec",
	"currencyCode",
	"currencyName",
	"currencySymbol",
	"isOpenZeroStockBuy",
	"isOpenMultiShopCart",
	"isOpenCombinePay",
	"combinePayLimitType",
	"wechatMustPay",
	"wechatPayRatio",
	"premiummembership_isOpenMembership",
	"isOpenSeparateWarehousePostage",
	"restricted_isOpenRestrictedCheck",
	"restricted_explainText",
	"showFreePostageTip",
	"isShowNotFreeShipFreight",
	"restrictedWhitePhoneList",
	"genericGoodsDetailInfo",
	"isOpenGenericGoodsTemplates",
	"genericGoodsDetailPosition",
	"isShowGoodsNo",
	"noshipMemberGrade",
	"isOpenSaveCustomerContacts",
	"appThemeName",
	"customerServiceType",
	"customerService_workWeChat_companyId",
	"customerService_workWeChat_serviceUrl",
	"isOpenDebtPay",
	"bindRefundLogisticList",
	"isHideGoodsName",
	"isShowGoodsListStock",
	"isNewTradePush",
	"quickShoppingDefaultOrderField",
	"goodsSelectionActionType",
	"app_restrictLoginRichText",
	"DistributionPoster_showCustomerName",
	"program_qrcode_url_formal",
	"isOpenCannotReturnTime",
	"cannotReturnDays",
	"isOpenTradeAutoCancel_normal",
	"tradeAutoCancelTimeLimit_normal",
	"tradeAutoCancelTimeLimit_timed",
	"isOpenTradeAutoCancel_presale",
	"tradeAutoCancelTimeLimit_presale",
	"isOpenPwdLogin",
	"OpenZeroStock_tipText",
	"OpenZeroStock_isOpenRemind",
	"offlinePayList",
	"isAutoPushTrade_offlinePay",
	"freePostageType",
	"distribution_isHideRankingList",
	"isOpenCollectBuyerPhone",
	"isShowSingleUnit",
	"selfPickDelayedDays",
	"canSelectSelfPickTimeRange",
	"isShowSellCount",
	"isShowOverStockTag",
	"isShowStockOuts",
	"overStockGoodsDelayedDays",
	"carouseImgUrls",
	"channelAnotherNameList",
	"miniProgramInfo",
	"wechatTransfer_singleLimitAmount",
	"isShowRequiredField",
	"requiredFieldShowName",
	"requiredFieldToOms",
	"isOpenStockHold",
	"isOpenTonglianCashierForWechat",
	"isOpenShowVisitorPrice",
	"isOpenTonglianCashierForWechat",
	"isOpenShowVisitorPrice",
	"isShowComment",
	"tradeAmountLimit_isEnabled",
	"tradeAmountLimit_limitType",
	"tradeAmountLimit_limitAmount",
	"indistinctStockExceptionalLowerLimit",
	"indistinctStockExceptionalUpperLimit",
	"isShowCustomerBalance",
	"decimalKeepPlaces",
	"stockShowPageSelect",
	"isOpenCrmPromotionPloy",
	"crmPromotionPloy_canBeCombination_coupon",
	"crmPromotionPloy_canBeCombination_score",
	"labelsInExtraPage",
	"isFillWarehouseInfo",
	"searchOmsTradeListNotIncludeFlagId",
];


export {
	config,
	constant,
	allUserSettingKeyList
}