<template>
	<div id="home" :style="{backgroundColor:bgImgOfDesignPage? '' : bgColorOfDesignPage,backgroundImage:'url('+bgImgOfDesignPage+')'}">
		<!-- 顶部菜单 -->
		<div class="top-tabbar">
			<el-row>
				<el-col :span="4">
					<img class="mall-logo" v-if="mallLogo" :src="$utils.setImg(mallLogo)" />
				</el-col>
				<el-col :span="3">
					<div class="cate-tabbar flex-row" @mouseover="openGoodsCate" @mouseleave="closeGoodsCate">
						<i class="el-icon-menu"></i>
						<span class="cate-tab">商品分类</span>
						<goods-cate v-show="showGoodsCate" v-on:close-goods-cate="closeGoodsCate" v-on:select-cate="selectCategory"></goods-cate>
					</div>
				</el-col>
				<el-col :span="11" class="flex-wrap">
					<div v-for="(item,index) in goodsListTitle">
						<div class="title-text" @click="toGoodsList(item.goodsfrom,item.goodsids)">{{item.title}}</div>
					</div>
				</el-col>
				<el-col :span="6">
					<search @searchGoods="searchGoods" :showPlaceholder="false"></search>
					<!-- <el-input v-model="goodsSearchValue" @keyup.13.native="searchGoods">
						<el-button type="danger" slot="append" icon="el-icon-search" @click="searchGoods"></el-button>
					</el-input> -->
				</el-col>
			</el-row>
			<!-- 			<img class="mall-logo" v-if="mallLogo" :src="$utils.setImg(mallLogo)" />
			<div class="cate-tabbar flex-row" @mouseover="openGoodsCate" @mouseleave="closeGoodsCate">
				<i class="el-icon-menu"></i>
				<span class="cate-tab">商品分类</span>
				<goods-cate v-show="showGoodsCate" v-on:close-goods-cate="closeGoodsCate"
					v-on:select-cate="selectCategory"></goods-cate>
			</div>
			<div style="width: 900px;" class="flex-wrap">
				<div v-for="(item,index) in goodsListTitle" >
					<div class="title-text" @click="toGoodsList(item.goodsfrom,item.goodsids)">{{item.title}}</div>
				</div>
			</div>
			<div class="search-wrap">
				<el-input v-model="goodsSearchValue" @keyup.13.native="searchGoods">
					<el-button type="danger" slot="append" icon="el-icon-search" @click="searchGoods"></el-button>
				</el-input>
			</div> -->
		</div>

		<!-- 内容主体 -->
		<div class="home-container">

			<!-- 自定义样式 轮播图 -->
			<div id="styleList">
				<div v-for="(item,index) in styleList">
					<!-- 轮播图 -->
					<div v-if="item.type==1 && item.carouselInfoDtoList && item.carouselInfoDtoList.length == 1" class="carousel-img-wrap" :data-index="index" :data-subindex="0" @click="toCarouselLink" :interval="5000">
						<img class="carousel-img single" :src="$utils.setImg(item.carouselInfoDtoList[0].picUrl)" :data-index="0" />
					</div>
					<el-carousel v-if="item.type==1 && item.carouselInfoDtoList && item.carouselInfoDtoList.length>1" v-show="carouselImgHeight>0" :height="carouselImgHeight + 'px'" arrow="hover" :interval="5000">
						<el-carousel-item v-for="(item1,index1) in item.carouselInfoDtoList">
							<div class="carousel-img-wrap" :data-index="index" :data-subindex="index1" @click="toCarouselLink">
								<!-- <el-image class="carousel-img" :src="$utils.setImg(item1.picUrl)" fit="contain"></el-image> -->
								<img class="carousel-img" :src="$utils.setImg(item1.picUrl)" :data-index="index" @load="carouselImgLoad" />
								<div class="matte" />

							</div>
						</el-carousel-item>
					</el-carousel>
					<!-- 热区广告 -->
					<div v-if="item.type==2 && item.picUrl" :data-index="index" @click="toZoneLink">
						<img class="carousel-img single" :src="$utils.setImg(item.picUrl)" :data-index="0" />
					</div>
					<!-- 广告通知 -->
					<div v-if="item.type==3 && item.picUrl" :data-index="index">
						<img class="carousel-img single" :src="$utils.setImg(item.picUrl)" />
					</div>
					<!-- 商品列表 -->
					<div v-if="item.type == 4" :data-index="index">
						<div class="container modular" v-if="item.goodsList && item.goodsList.length > 0" :style="{backgroundColor: !item.bgImg  ? item.bgcolor : '', backgroundImage: item.bgImg ? `url(${item.bgImg})` : ''}">
							<div class="list-title-wrap">
								<span class="goods-list-title" :style="{color: item.titlecolor}">{{item.title}}</span>
								<div class="more-btn" @click="toGoodsList(item.goodsfrom,item.goodsIds)">更多</div>
							</div>
							<div v-if="item.picUrl"><img style="width: 100%;" :src="item.picUrl"></div>
							<div class="goods-list flex-row">
								<div class="goods-item flex-col" v-for="(gitem,gindex) in item.goodsList" :data-goodsid="gitem.goodsId" @click="toGoodsDetail(gitem)">
									<div class="goods-img-wrap">
										<img class="goods-img" :src="$utils.set400Img(gitem.coverPic)" />
									</div>
									<div class="goods-name text-ellipsis" :title="gitem.goodsName" v-if="showGoodsName">
										<span class="presale-style" v-if="gitem.presaleId">预售</span><span class="presale-style activity-labele" v-if="gitem.activityType == '抢购'">抢购</span>{{gitem.goodsName}}
									</div>
									<div class="goods-name text-ellipsis" :title="gitem.goodsNo" v-else><span class="presale-style" v-if="gitem.presaleId">预售</span><span class="presale-style activity-label" v-if="gitem.activityType == '抢购'">抢购</span>{{gitem.goodsNo}}
									</div>
									<div class="goods-sub-title-home" v-show="!!gitem.stockTxt && !gitem.presaleId" style="margin-top: 3px;">
										{{gitem.stockTxt}}
									</div>
									<div class="goods-sub-title-home" v-show="goodsValidityDateData[gitem.goodsId]" style="margin-top: 3px;">
										{{goodsValidityDateData[gitem.goodsId] || ""}}
									</div>

									<img class='sold-out-img' src="../../assets/images/ico-soldout.svg" v-show="isShowOverStockTag && gitem.stock<=0&& !gitem.presaleId" />

									<div class="goods-price flex-row" v-if="isShowPrice">
										<span v-show="gitem.priceMember || !gitem.score || gitem.score == 0">
											<span v-show="gitem.priceMember || !gitem.score || gitem.score == 0">{{$currency.symbol}}{{$utils.filterTrailingZeros(gitem.priceMember)}}</span>
											<span v-show="gitem.priceMember && gitem.priceMember!=0 && gitem.score > 0" class="goods-score">+</span>
											<span v-show="gitem.score>0" class="goods-score">{{gitem.score}}积分</span>
											<span class='goods-price-detail' v-if="showPriceDetail&&gitem.priceDetail&&parseFloat(gitem.priceDetail)>parseFloat(gitem.priceMember)">{{$currency.symbol}}{{ $utils.filterTrailingZeros(gitem.priceDetail)}}</span>
										</span>

									</div>
									<div class="label-content">
										<span class="button-border" v-for="item in gitem.labelNameList">
											{{item}}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 抢购模块 -->
					<div v-if="item.type==5">
						<div class="container">
							<timed-list :key="timeListkey" :isFromIndex='true'></timed-list>
						</div>
					</div>
					<!-- 商品分类 -->
					<div v-if="item.type==6" :data-index="index">
						<!-- <div class="category-title">商品分类</div> -->
						<!-- <div><img style="width: 100%;" :src="item.picUrl"></div> -->
						<div class="category-content  goods-list flex-row modular" :style="{backgroundColor: !item.bgImg ? item.bgcolor==''?'#ffffff':item.bgcolor : ' ', backgroundImage: item.bgImg ? `url(${item.bgImg})` : ''}">
							<div class="category-content_item" v-for="(gitem,gindex) in item.categoryRenderList" @click="selectCategory(gitem)">
								<div class="content-img goods-img-wrap">
									<img class="goods-img cate-img" :src="$utils.set400Img(gitem.picUrl)" />
								</div>
								<div class="category-text">
									{{gitem.categoryName}}
								</div>
							</div>
						</div>
					</div>
					<!-- 自由容器 -->
					<div v-if="item.type=='8'" :data-index="index">
						<div class="free-box-container" :style="{backgroundImage: (item.style.bgImg?`url(${item.style.bgImg})`:''), backgroundColor:(item.style.bgColor||'#ffffff')}">
							<!-- 容器标题 -->
							<div class="free-box-title" v-if="!!item.style.titleText" :style="{color:(item.style.titleColor||'#333333'),}">{{item.style.titleText}}</div>
							<!-- 子模块列表 -->
							<div class='free-box-module-list' :style="{width:`${item.style.width}px !important`, height:`${item.style.height}px !important`}">
								<div class="free-box-module-item" v-for="(itemModule, index) in item.itemModuleList" :style="{width:`${itemModule.realPosition.width}px`, height:(itemModule.realPosition.height=='auto'?'auto':`${itemModule.realPosition.height}px`), top:`${itemModule.realPosition.top}px`, left:`${itemModule.realPosition.left}px`, zIndex:`${itemModule.type=='category'?'99':'98'}`}">
									<!-- 子模块-轮播图 -->
									<div v-if="itemModule.type=='carousel'">
										<el-carousel class='free-box-el-carousel' arrow="hover" :interval="5000" :height="`${itemModule.realPosition.height}px`" :width="`${itemModule.realPosition.width}px`">
											<el-carousel-item v-for="(carouselItem,carouselIndex) in itemModule.data">
												<div class="carousel-img-wrap" @click="gotoLink(carouselItem.linkType,carouselItem.linkData)">
													<img class="carousel-img" :src="$utils.setImg(carouselItem.picUrl)" />
												</div>
											</el-carousel-item>
										</el-carousel>
									</div>
									<!-- 子模块-热区 -->
									<div v-if="itemModule.type=='hotZone'" class='hot-zone-box'>
										<img class="hot-zone-img" :src="$utils.setImg(itemModule.data.picUrl)" />
										<div class='hot-zone-click' v-for="(area, index) in itemModule.data.areaList" :style="{width:`${area.realPosition.width}px`, height:`${area.realPosition.height}px`, top:`${area.realPosition.top}px`, left:`${area.realPosition.left}px`}" @click="gotoLink(area.link.linkType,area.link.linkData)"></div>
									</div>
									<!-- 子模块-商品列表 -->
									<div class='free-box-module-goods' v-if="itemModule.type=='goodsList' && itemModule.data.goodsList && itemModule.data.goodsList.length > 0" :style="{backgroundImage: (itemModule.data.bgImg?`url(${itemModule.data.bgImg})`:''), backgroundColor:(itemModule.data.bgColor||'#ffffff')}" :class="itemModule.data.title?'has-goods-title':''">
										<div class="free-box-list-title">
											<span :style="{color: itemModule.data.titleColor||'#333333'}">{{itemModule.data.title}}</span>
											<div class="free-box-more-btn" @click="gotoGoodsListPage(itemModule.data.goodsFrom,itemModule.data.goodsData)">更多</div>
										</div>
										<div class="free-box-goods-list">
											<div class="free-box-goods-item" v-for="(goodsItem,goodsIndex) in itemModule.data.goodsList" @click="gotoLink(1,goodsItem.goodsId)">
												<!-- 图片 -->
												<img class="free-box-goods-img" :src="$utils.setImg(goodsItem.coverPic)" />
												<!-- 品名和标记 -->
												<div class="free-box-goods-name text-ellipsis" :title="showGoodsName?goodsItem.goodsName:goodsItem.goodsNo">
													<span class="presale-style" v-if="goodsItem.presaleId">预售</span>
													<span class="presale-style activity-labele" v-if="goodsItem.activityType == '抢购'">抢购</span>
													<span>{{showGoodsName?goodsItem.goodsName:goodsItem.goodsNo}}</span>
												</div>
												<!-- 库存 -->
												<div class="goods-sub-title-home" v-if="!!goodsItem.stockTxt && !goodsItem.presaleId">
													{{goodsItem.stockTxt}}
												</div>
												<!-- 有效期 -->
												<div class="goods-sub-title-home" v-if="goodsValidityDateData[goodsItem.goodsId]">
													{{goodsValidityDateData[goodsItem.goodsId] || ""}}
												</div>
												<!-- 售完标记 -->
												<img class='sold-out-img' src="../../assets/images/ico-soldout.svg" v-if="isShowOverStockTag && goodsItem.stock<=0&& !goodsItem.presaleId" />
												<!-- 价格 -->
												<div class="free-box-goods-price" v-if="isShowPrice">
													<span v-if="goodsItem.priceMember || !goodsItem.score || goodsItem.score == 0">{{$currency.symbol}}{{$utils.filterTrailingZeros(goodsItem.priceMember)}}</span>
													<span v-if="goodsItem.priceMember && goodsItem.priceMember!=0 && goodsItem.score > 0">+</span>
													<span v-if="goodsItem.score>0">{{goodsItem.score}}积分</span>
													<span v-if="showPriceDetail&&goodsItem.priceDetail&&parseFloat(goodsItem.priceDetail)>parseFloat(goodsItem.priceMember)">{{$currency.symbol}}{{ $utils.filterTrailingZeros(goodsItem.priceDetail)}}</span>
												</div>
												<!-- 标签 -->
												<div class="label-content" v-if="goodsItem.labelNameList&&goodsItem.labelNameList.length>0">
													<span class="button-border" v-for="item in goodsItem.labelNameList">
														{{item}}
													</span>
												</div>
											</div>
										</div>
									</div>
									<!-- 子模块-商品分类 -->
									<div v-if="itemModule.type=='category'" class="free-box-cate-list free-box-module-item-content-box" @mouseleave="closeSubCateListBox">
										<div v-for="(firstCate, firstIndex) in itemModule.data.cateList" class="free-box-cate-item" :class="itemModule.data.isShowPic?'':'no-cate-pic'">
											<div class="cate-level-first" @click="gotoLink(2,firstCate.cateId)" @mouseenter="showSubCateListBox" :data-cateid="firstCate.cateId">
												<img v-if="itemModule.data.isShowPic" class="cover-img" :src="$utils.setImg(firstCate.picUrl)">
												<span>{{firstCate.cateName}}</span>
											</div>
											<div v-if="itemModule.data.isShowSubList&&firstCate.subList.length>0" class="cate-level-second">
												<span v-for="(secondCate, secondIndex) in firstCate.subList" @click="gotoLink(2,secondCate.cateId)">{{secondCate.cateName}}</span>
											</div>
										</div>
										<!-- 三级分类弹窗 -->
										<div class="free-box-sub-cate-list-box" v-if="isShowSubCateListBox">
											<div class="sub-cate-list-item" v-for="(secondCate, secondIndex) in subCateList">
												<span class="sub-cate-list-item-second" @click="gotoLink(2,secondCate.cateId)">{{secondCate.cateName}}</span>
												<span v-if="secondCate.subList.length>0">></span>
												<span v-for="(thirdCate, thirdIndex) in secondCate.subList" @click="gotoLink(2,thirdCate.cateId)">{{thirdCate.cateName}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- 富文本 -->
					<div v-if="item.type==9" class="richtext-box modular" :data-index="index" :style="{backgroundColor: !item.bgImg ? item.bgcolor==''?'#ffffff':item.bgcolor : ' ', backgroundImage: item.bgImg ? `url(${item.bgImg})` : ''}">
						<div v-html="$utils.ueStrDecode(item.content)">
						</div>
					</div>

				</div>
			</div>

		</div>

		<!-- 网站备案 -->
		<div class="footer">
			<p class="footer-txt">
				{{copyright||"版权所有(C) 2017-现在 杭州吉客云网络技术有限公司"}}&nbsp;
				<a href="http://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
					{{ICPNNumber||"浙ICP备18019426号-1"}}
				</a>
			</p>
			<p class="footer-txt" v-if="isShowCybersecuritycNumber">
				<img src="../../assets/images/icon-police.png" alt="">
				<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002014798" target="_blank" rel="noopener noreferrer">
					{{cybersecuritycNumber||"浙公网安备 33011002014798 号"}}&nbsp;&nbsp;</a>
				<a data-v-b6ac6d7e="" href="https://new.cnzz.com/v1/login.php?siteid=1279031780" target="_blank" rel="noopener noreferrer"> 站长统计 </a>
			</p>
		</div>

		<!-- 右下角浮动按钮 -->
		<float-btns></float-btns>
	</div>
</template>

<script>
	import vHeader from '../../components/common/Header.vue';
	import GoodsCate from '../../components/goods/GoodsCate.vue';
	import FloatBtns from '../../components/common/FloatBtns.vue';
	import DesignPage from './designpage.vue';
	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import validityDateUtils from '../../utils/api-utils/api-goods-validitydate.js'
	import timedList from '../../components/goods/TimedGoodsList.vue';
	import Search from '../../components/common/Search.vue';
	import {
		getSingleDesignStyle,
		getCustomerStyleListOld
	} from '../../utils/api-utils/api-style.js';
	import {
		getParam,
		getParamByKeysFromCache
	} from '../../utils/api-utils/api-params.js';
	import {
		getCustomerInfo
	} from '../../utils/api-utils/api-customer.js';
	import commonUtil from '../../utils/common-utlis.js';

	export default {
		name: 'home',
		components: {
			vHeader,
			'float-btns': FloatBtns,
			'goods-cate': GoodsCate,
			'timed-list': timedList,
			'search': Search,
			'designpage': DesignPage
		},
		data() {
			return {
				styleList: [], //自定义样式列表
				mallTitle: "", //商城名
				mallLogo: "", //商城Logo
				showGoodsCate: false, //是否显示商品分类
				hotGoodsList: [], //热销商品列表
				newGoodsList: [], //新品商品列表
				goodsSearchValue: "", //商品搜索内容
				styleLoaded: false, //轮播图加载完成
				copyright: "", // 版权
				ICPNNumber: "", // 备案号
				cybersecuritycNumber: "", //网安备案号
				isShowCybersecuritycNumber: true, // 是否显示公安备案
				customerId: 0,
				goodsValidityDateData: {}, // 保质期
				showPriceDetail: false, // 是否展示零售价
				showTimedSale: false,
				timeListkey: false,
				isOpenIndistinctStock: false,
				isOpenZeroStockBuy: false, // 0库存
				showGoodsName: !['795330'].includes(localStorage.getItem("JackyunUserName")),
				goodsListTitle: [],
				categoryList: [],
				categoryRenderList: [],
				isShowOverStockTag: true,
				isShowPrice: false,
				carouselImgHeight: 0,
				bgColorOfDesignPage: "",
				bgImgOfDesignPage: "",
				isShowSubCateListBox: false, // 是否展示下级分类弹窗
				subCateList: [], // 下级分类弹窗数据
			}
		},
		created: async function() {
			this.getMallTitleAndLoadParam(); //获取商城名称Logo
			await this.getCategoryList() // 获取分类
			this.loadStyle();
		},
		async mounted() {
			this.$eventBus.$on('setCustomerId', msg => {
				this.customerId = msg;
			})

			this.isShowPrice = await this.$utils.getIsShowPrice();
		},
		activated() {
			this.timeListkey = !this.timeListkey;
		},
		watch: {
			async customerId(newName, oldName) {
				let that = this;
				if (!!newName && newName != 0) {
					that.loadStyle();
					this.isShowPrice = await this.$utils.getIsShowPrice();
				}
			}
		},
		methods: {
			//获取商城名称及Logo
			async getMallTitleAndLoadParam() {
				let paramlist = await getParam("pcmall_title,pcmall_logo,isOpenShowDetailPrice,domainFiling,isOpenIndistinctStock,isOpenZeroStockBuy,isShowGoodsStock,isShowOverStockTag,isShowStockOuts");
				this.mallTitle = paramlist.pcmall_title || "JMALL商城";
				this.mallLogo = paramlist.pcmall_logo;
				this.showPriceDetail = paramlist.isOpenShowDetailPrice == "1";
				// this.showTimedSale = param.pcmall_showTimed == "1";
				document.title = this.mallTitle;

				// 自定义备案信息
				var domainFiling = paramlist.domainFiling;
				if (domainFiling) {
					try {
						var domainFilingList = domainFiling.split(',');
						this.copyright = (domainFilingList[0] || "").trim().replace("2017-2020", "2017-现在");
						this.ICPNNumber = (domainFilingList[1] || "").trim();
						this.cybersecuritycNumber = (domainFilingList[2] || "").trim();
						this.isShowCybersecuritycNumber = !!this.cybersecuritycNumber;
					} catch (e) {}
				}

				this.isOpenIndistinctStock = paramlist.isOpenIndistinctStock == "1";
				this.isOpenZeroStockBuy = paramlist.isOpenZeroStockBuy == "1";
				this.isShowOverStockTag = paramlist.isShowOverStockTag != "0"; // 是否展示缺货标签
				this.isShowGoodsStock = paramlist.isShowGoodsStock == "1"; // 是否展示库存
				this.isShowStockOuts = paramlist.isShowStockOuts != "0"; // 是否展示缺货库存
			},

			//加载自定义首页样式
			async loadStyle() {
				let titleList = [];
				// 获取新装修数据
				let designPageInfo = await getSingleDesignStyle();
				let designContent = JSON.parse(designPageInfo.designContent || '{}');
				// 装修数据
				let styleList = designContent.moduleStyleList || [];
				// 背景颜色 背景图片
				this.bgColorOfDesignPage = designContent.globalStyle && designContent.globalStyle.bgColor;
				this.bgImgOfDesignPage = designContent.globalStyle && designContent.globalStyle.bgImg;

				// 不存在新装修数据 补充老装修数据
				styleList = styleList.length == 0 ? await getCustomerStyleListOld() : styleList;

				for (var i = 0; i < styleList.length; i++) {
					try {
						let item = styleList[i];
						if (item.type == 4) {
							let content = JSON.parse(item.content);
							item.titlecolor = content.titlecolor;
							item.goodscount = content.goodscount;
							item.goodsfrom = content.goodsfrom;
							item.goodsIds = content.goodsids == "0" ? "" : content.goodsids;
							item.goodsList = await goodsUtils.listGoods(content.goodsfrom, content.goodscount, content.goodsids, 1);
							item.bgcolor = content.bgcolor;
							item.bgImg = item.bg_img;
							item.goodsList = await this.attchOtherInfo(item.goodsList);
							// this.getGoodsList(content.goodsfrom, content.goodscount,
							// 	content.goodsids, i);
							if (!!item.title) {
								titleList.push({
									title: item.title,
									goodsfrom: item.goodsfrom,
									goodsids: item.goodsIds
								});
							}
						}
						if (item.type == 1) {
							item.carouselInfoDtoList = (item.carouselInfoDtoList || item.carousel_list || []).map(carouselItem => {
								carouselItem.picUrl = carouselItem.picUrl || carouselItem.pic_url;
								return carouselItem
							});
						}
						if (item.type == 6) {
							let tempContent = JSON.parse(styleList[i].content);
							tempContent.catecount = tempContent.catecount / 1;

							// 是否展示全部分类
							let isShowAllCateAndFirstCateIsAll = tempContent.isshowallcate != 0 && this.categoryList[0].categoryName == "全部分类";
							let startIndex = isShowAllCateAndFirstCateIsAll ? 0 : 1;
							let endIndex = isShowAllCateAndFirstCateIsAll ? tempContent.catecount : tempContent.catecount + 1;

							styleList[i].bgcolor = tempContent.bgcolor
							styleList[i].categoryRenderList = this.categoryList.slice(startIndex, endIndex);
						}
						if (item.type == 3 || item.type == 2 || item.type == 4) {
							styleList[i].picUrl = styleList[i].picUrl || styleList[i].pic_url;
						}
						// 自由容器数据处理
						if (item.type == '8') {
							// 反序列化装修数据
							let designDetail = await this._processFreeBoxDesignData(item.content);
							// 赋值渲染数据
							item.style = designDetail.style || {};
							item.itemModuleList = designDetail.itemModuleList || [];
						}
						// 富文本数据处理
						if (item.type == '9') {
							item.bgcolor = item.bgColor;
						}
					} catch (e) {}
				}

				this.styleList = styleList;
				this.goodsListTitle = titleList;

				// 更新自由容器高度
				this._updateFreeBoxContainerHeight();
			},

			/**
			 * 处理自由容器装修数据
			 * @param {Object} jsonStr
			 */
			async _processFreeBoxDesignData(jsonStr) {
				try {
					// 反序列化装修数据
					let designDetail = JSON.parse(jsonStr);

					// 获取容器dom
					let $styleListDom = document.getElementById('styleList');
					// 自由容器宽度
					let freeBoxContainerWidth = $styleListDom.offsetWidth > 1200 ? 1200 : $styleListDom.offsetWidth;

					// 转换生成真实位置信息，并记录容器最大高度
					let freeBoxContainerHeight = 0;
					for (let item of designDetail.itemModuleList) {
						// 转换正式位置
						item.realPosition = this._convertToRealPosition(item.position, freeBoxContainerWidth, item.type);
						// 记录最大高度
						if (item.realPosition.top + item.realPosition.height > freeBoxContainerHeight) {
							freeBoxContainerHeight = item.realPosition.top + item.realPosition.height;
						}

						// 按需处理子项数据
						if (item.type == "hotZone") {
							item.data.areaList.forEach(area => {
								area.realPosition = this._convertToRealPosition(area.position, item.realPosition.width);
							});
						} else if (item.type == "category") {
							item.data.cateList = this.__getFreeBoxCateList(item.data);
						} else if (item.type == "goodsList") {
							item.data.goodsList = await goodsUtils.getGoodsListForFreeBox(item.data.goodsFrom.toString(), item.data.goodsData, item.data.showCount);
							item.data.goodsList = await this.attchOtherInfo(item.data.goodsList);
						}
					}

					// 固定自由容器宽高
					designDetail.style.height = freeBoxContainerHeight;
					designDetail.style.width = freeBoxContainerWidth;

					return designDetail;
				} catch (e) {
					return {
						style: {},
						itemModuleList: []
					};
				}
			},

			/**
			 * 获取自由容器展示分类
			 * @param {Object} setting
			 */
			__getFreeBoxCateList(setting) {
				let newCateList = [];

				// 添加“全部”分类
				if (setting.isShowAllCate) {
					newCateList.push({
						cateId: 0,
						cateName: "全部",
						picUrl: this.categoryList[0].picUrl || "https://jkyun--jmall.oss-cn-hangzhou.aliyuncs.com/jmc/appStatic/ico/home/ico-home-allcate.png",
						subList: []
					});
					setting.showCount--;
				}

				// 按需添加分类
				this.categoryList.filter(t => t.categoryId > 0).slice(0, setting.showCount).forEach((cate, index) => {
					let newCate = {
						cateId: cate.categoryId,
						cateName: cate.categoryName,
						picUrl: cate.picUrl,
						subList: []
					};

					// 按需添加下级分类
					if (setting.isShowSubList && cate.categoryList && cate.categoryList.length > 0) {
						newCate.subList = cate.categoryList.map(t => {
							return {
								cateId: t.categoryId,
								cateName: t.categoryName
							}
						});
					}

					newCateList.push(newCate);
				});

				return newCateList;
			},

			//获取商品分类列表
			async getCategoryList() {
				let cateList = await this.getCategoryListFromOrigin();
				if (cateList[0] && cateList[0].categoryName == "全部分类") {
					cateList[0].categoryId = '';
				}
				cateList.forEach(item => {
					if (item.categoryList && item.categoryList.length > 0) {
						item.isThreeCate = !!item.categoryList.find(f => f.categoryList.length >
							0);
					}
				})

				this.categoryList = cateList;
			},

			// 获取分类数据
			async getCategoryListFromOrigin() {
				// 获取缓存中信息
				let customerInfo = await getCustomerInfo();
				let viplevel = customerInfo && customerInfo.vipLevel ? customerInfo.vipLevel : "";
				return new Promise((resolve, reject) => {
					this.$request.jackyunPost({
						method: "jmall.goods.getgoodscategorylistv2",
						// method: "jmall.goods.getmanagegoodscategorylist", // 展示全部分类
						memberGrade: viplevel
						// channelId:localStorage.getItem("pc_channelId")
					}, (res) => {
						let result = [];
						if (res.code == 200) {
							result = res.result.data;
						}
						resolve(result)
					})
				});
			},

			// 添加其他数据
			async attchOtherInfo(goodslist, i) {
				let newGoodsList = []
				if (goodslist.length > 0) {
					let goodsIds = goodslist.map(item => {
						return item.goodsId
					});
					// 获取保质期
					this._getvaliditydate(goodsIds);

					// 补充完整的货品信息（价格、库存、营销活动信息等）
					newGoodsList = await goodsUtils.completeFullGoodsInfoForList(goodslist, "goodsList");
				}
				return newGoodsList
			},

			// 获取保质期
			async _getvaliditydate(goodsIds) {
				let that = this;
				//门店信息
				let validateList = await validityDateUtils.getValidityDateByMultiCondition(goodsIds);

				// 已有的保质期库存
				let goodsValidityDateData = that.goodsValidityDateData;
				for (let i = 0; i < validateList.length; i++) {
					let item = validateList[i]
					item.validityDate = await this.$utils.getFormatValidate(item, false);
					goodsValidityDateData[item.goodsId] = goodsValidityDateData[item.goodsId] || (!!item.validityDate ? item.validityDate : "");
				}

				that.goodsValidityDateData = goodsValidityDateData;
			},

			//搜索商品
			searchGoods(searchValue) {
				this.searchValue = searchValue
				this.$router.push({
					name: 'goodslist',
					params: {
						search_value: this.searchValue
					},
				});
			},
			//跳转商品列表
			toGoodsList(goodsfrom, goodsids) {
				if (goodsfrom == "2" || goodsfrom == "1") {
					this.$router.push({
						name: 'goodslist',
						params: {
							order_by: goodsfrom == "2" ? "sellCountTotal" : "createTime",
						},
					});
					return;
				}
				if (goodsfrom == "6") {
					this.$router.push({
						name: 'goodslist',
						params: {
							category_id: goodsids,
						}
					});
					return;
				}
				this.$router.push({
					name: 'goodslist',
					params: {
						goodsfrom: goodsfrom,
						goodsids: goodsids
					},
				});
			},
			//打开商品分类
			openGoodsCate() {
				this.showGoodsCate = true;
			},
			//关闭商品分类
			closeGoodsCate() {
				this.showGoodsCate = false;
			},
			//选择商品分类
			selectCategory(res) {
				this.showGoodsCate = false;
				var cateId = res.cateId || res.categoryId || '';
				var pCateId = res.pCateId || res.categoryId || '';
				var cateName = res.cateName || res.categoryName;
				this.$router.push({
					name: 'goodslist',
					params: {
						category_id: cateId + '',
						pcategory_id: pCateId + '',
						category_name: cateName + '',
					}
				});
			},
			//调整轮播图的高度
			carouselImgLoad(e) {
				var index = parseInt(e.currentTarget.dataset.index);
				var styleList = this.styleList;
				var wrapWidth = document.getElementById("styleList") && document.getElementById("styleList").offsetWidth; //轮播图外容器的宽度
				var imgwidth = e.currentTarget.naturalWidth, //图片原始宽度
					imgheight = e.currentTarget.naturalHeight, //图片原始高度
					ratio = imgwidth / imgheight; //宽高比  
				//计算当前的高度值  
				var viewHeight = wrapWidth / ratio;
				viewHeight = viewHeight > imgheight ? imgheight : viewHeight; //最高高度不能超过原图的高度
				// if (styleList[index].height) {
				// 	//取最高的图片高度
				// 	styleList[index].height = viewHeight > styleList[index].height ? viewHeight : styleList[index].height;
				// } else {
				// 	styleList[index].height = viewHeight;
				// }
				this.carouselImgHeight = viewHeight > this.carouselImgHeight ? viewHeight : this.carouselImgHeight;
			},
			//轮播图点击跳转
			async toCarouselLink(e) {
				let index = parseInt(e.currentTarget.dataset.index);
				let subIndex = parseInt(e.currentTarget.dataset.subindex);
				let carousel = this.styleList[index].carouselInfoDtoList[subIndex];
				//判断链接类型，进行跳转
				if (carousel.type == "1" && carousel.goodsId) {
					//商品链接
					let goodsId = carousel.goodsId;
					let encode = window.btoa(window.encodeURIComponent(goodsId));

					// 判断是否是限时抢购商品
					let actInfolist = await goodsUtils.getGoodsExtraInfo(goodsId.split(','), "1");
					// 如果存在活动信息前往限时抢购商品详情
					if (!!actInfolist.find(item => (item.activityType || '').includes('抢购'))) {
						this.$router.push({
							name: 'timedsalegoodsdetail',
							query: {
								saleId: actInfolist[0].activityId,
								goodsId: goodsId
							}
						});
						return
					}

					this.$router.push({
						name: 'goodsdetail',
						query: {
							id: encode
						}
					});
				} else if (carousel.type == "0" && carousel.content.indexOf('https') >= 0) {
					//自定义网页链接
					window.open(carousel.content);
				} else if (carousel.type == "2") {
					//链接为分类
					this.$router.push({
						name: 'goodslist',
						params: {
							category_id: carousel.content,
							category_name: linkname,
						}
					});
				} else if (carousel.type == "3") {
					//链接为品牌

				} else if (carousel.type == "4") {
					//链接为分组
					this.$router.push({
						name: 'goodslist',
						params: {
							groupId: carousel.content,
						}
					});

				} else if (carousel.type == "5") {
					// 链接为自定义页面
					this.goToCustomPageById(carousel.content)
				} else if (carousel.type == "6") {
					// 链接为限时抢购
					this.$router.push({
						name: 'timedsalegoodslist',
					});
				}
			},
			//热区广告点击跳转
			async toZoneLink(e) {
				let index = parseInt(e.currentTarget.dataset.index);
				var content = this.styleList[index].content;
				if (content) {
					//获取点击点相对页面坐标
					var x = e.layerX;
					var y = e.layerY;
					//获取图片的宽高及位置
					//获取图片宽高
					var imgWidth = e.target.clientWidth;
					var imgHeight = e.target.clientHeight;
					var imgLeft = e.target.offsetLeft;
					var imgTop = e.target.offsetTop;
					x = x - imgLeft; // 获取相对图片的X坐标
					y = y - imgTop; // 获取相对图片的Y坐标

					var zoneList = JSON.parse(content).reverse(); //重复区域，已后添加的为主
					var type = "0";
					var linkurl = "";
					var linkname = "";
					for (var i = 0; i < zoneList.length; i++) {
						var zone = zoneList[i];
						//循环判断点击点的位置是否在区域内
						if (x > parseFloat(zone.left) * imgWidth && y > parseFloat(zone.top) * imgHeight &&
							x < (parseFloat(zone.left) + parseFloat(zone.width)) * imgWidth &&
							y < (parseFloat(zone.top) + parseFloat(zone.height)) * imgHeight) {
							type = zone.type;
							linkurl = zone.link;
							linkname = zone.linkname;
							break;
						}
					}

					if (type == "0" && linkurl.indexOf("https://") >= 0) {
						//链接为自定义链接
						window.open(linkurl);
					} else if (type == "1") {
						//链接为商品
						let encode = window.btoa(window.encodeURIComponent(linkurl));

						// 判断是否是限时抢购商品
						let actInfolist = await goodsUtils.getGoodsExtraInfo(linkurl.split(','), "1");
						// 如果存在活动信息前往限时抢购商品详情
						if (!!actInfolist.find(item => (item.activityType || '').includes('抢购'))) {
							this.$router.push({
								name: 'timedsalegoodsdetail',
								query: {
									saleId: actInfolist[0].activityId,
									goodsId: linkurl
								}
							});
							return
						}
						this.$router.push({
							name: 'goodsdetail',
							query: {
								id: encode
							}
						});
					} else if (type == "2") {
						//链接为分类
						this.$router.push({
							name: 'goodslist',
							params: {
								category_id: linkurl,
								category_name: linkname,
							}
						});
					} else if (type == "3") {
						//链接为品牌

					} else if (type == "4") {
						//链接为分组
						this.$router.push({
							name: 'goodslist',
							params: {
								groupId: linkurl,
							}
						});

					} else if (type == "5") {
						// 链接为自定义页面
						this.goToCustomPageById(linkurl)

					} else if (type == "6") {
						// 链接为限时抢购
						this.$router.push({
							name: 'timedsalegoodslist',
						});
					}
				}
			},

			/**
			 * 前往自定义页面
			 */
			async goToCustomPageById(pageId) {
				// 判断是否被删除
				let designPageInfo = await getSingleDesignStyle([202], [pageId]);

				// 自定义页面被删除直接返回
				if (!designPageInfo.designContent) {
					return;
				}

				// 链接为自定义页面
				this.$router.push({
					name: 'designpage',
					query: {
						designPageId: window.btoa(window.encodeURIComponent(pageId))
					}
				});

			},

			//跳转商品详情
			toGoodsDetail(good) {
				let goodsId = good.goodsId;
				// 跳转抢购
				if (good.activityType == "抢购") {
					this.$router.push({
						name: 'timedsalegoodsdetail',
						query: {
							saleId: good.activityId,
							goodsId: good.goodsId
						}
					})
					return
				}
				let encode = window.btoa(window.encodeURIComponent(goodsId));
				this.$router.push({
					name: 'goodsdetail',
					query: {
						id: encode
					}
				});
			},

			/**
			 * 定位数据转换（百分比转为数值，按照容器宽度转换）
			 * @param {any} position
			 * @param {any} containerWidth
			 * @param {any} moduleType
			 */
			_convertToRealPosition(position, containerWidth, moduleType) {
				let realPosition = {};
				if (position.pctWidth > 0) {
					realPosition.width = commonUtil.floatKeepDigit(containerWidth * position.pctWidth);
				}
				// 部分容器需要设置自动高度
				if (["goodsList"].includes(moduleType)) {
					realPosition.height = "auto";
				} else if (position.pctHeight > 0) {
					realPosition.height = commonUtil.floatKeepDigit(containerWidth * position.pctHeight);
				}
				if (position.pctTop > 0) {
					realPosition.top = commonUtil.floatKeepDigit(containerWidth * position.pctTop);
				}
				if (position.pctLeft > 0) {
					realPosition.left = commonUtil.floatKeepDigit(containerWidth * position.pctLeft);
				}
				return realPosition;
			},

			/**
			 * 链接跳转
			 * @param {Object} e
			 */
			// gotoLink(e) {
			// 	// 获取链接信息
			// 	let linkType = e.currentTarget.dataset.linktype;
			// 	let linkData = e.currentTarget.dataset.linkdata;

			// 	// 链接跳转
			// 	this._gotoLink(linkType, linkData);
			// },

			/**
			 * 链接跳转
			 * @param {Object} linkType
			 * @param {Object} linkData
			 */
			gotoLink(linkType, linkData) {
				linkType = linkType.toString();
				linkData = linkData.toString();
				debugger
				if (linkType == "0" && !linkData) {
					return;
				}

				switch (linkType) {
					// 自定义链接
					case "0":
						window.open(linkData, '_blank');
						break;
						// 商品链接
					case "1":
						this.$router.push({
							name: 'goodsdetail',
							query: {
								id: window.btoa(window.encodeURIComponent(linkData))
							}
						});
						break;
						// 商品分类
					case "2":
						this.$router.push({
							name: 'goodslist',
							params: {
								category_id: linkData
							}
						});
						break;
						// 商品品牌
					case "3":
						this.$router.push({
							name: 'goodslist',
							params: {
								brandId: linkData
							}
						});
						break;
						// 商品分组
					case "4":
						this.$router.push({
							name: 'goodslist',
							params: {
								groupId: linkData,
							}
						});
						break;
						// 自定义页面
					case "5":
						this.goToCustomPageById(linkData);
						break;
						// 限时抢购列表
					case "6":
						this.$router.push({
							name: 'timedsalegoodslist',
						});
						break;
				}
			},

			/**
			 * 展示下级分类弹窗
			 * @param {Object} e
			 */
			showSubCateListBox(e) {
				// 获取分类Id（一级分类）
				let firstCateId = parseInt(e.currentTarget.dataset.cateid);
				if (!firstCateId) {
					this.closeSubCateListBox();
					return;
				}

				// 获取一级分类信息
				let cateInfo = this.categoryList.find(t => t.categoryId == firstCateId);
				if (!cateInfo || !cateInfo.categoryList || cateInfo.categoryList.length <= 0) {
					this.closeSubCateListBox();
					return;
				}

				// 生成待展示的分类数据
				let subCateList = cateInfo.categoryList.map(second => {
					let subCate = {
						cateId: second.categoryId,
						cateName: second.categoryName,
						subList: []
					};
					if (second.categoryList && second.categoryList.length > 0) {
						subCate.subList = second.categoryList.map(third => {
							return {
								cateId: third.categoryId,
								cateName: third.categoryName
							};
						});
					}
					return subCate;
				});

				// 渲染数据
				this.subCateList = subCateList;
				this.isShowSubCateListBox = true;
			},

			/**
			 * 关闭下级分类弹窗
			 * @param {Object} e
			 */
			closeSubCateListBox(e) {
				this.isShowSubCateListBox = false;
			},

			/**
			 * 更新自由容器高度
			 */
			_updateFreeBoxContainerHeight() {
				setTimeout(function() {
					// 获取所有自由容器待结算的高度
					let freeBoxModuleListDom = document.getElementsByClassName('free-box-module-list');
					for (let moduleBox of freeBoxModuleListDom) {
						let maxHeight = 0;
						for (let item of moduleBox.children) {
							let itemBottom = item.offsetTop + item.offsetHeight;
							if (itemBottom > maxHeight) {
								maxHeight = itemBottom;
							}
						}
						moduleBox.style.height = `${maxHeight}px`;
					}
				}, 500);
			},

			/**
			 * 跳转到商品列表
			 * @param {Object} goodsFrom
			 * @param {Object} goodsData
			 */
			gotoGoodsListPage(goodsFrom, goodsData) {
				// 来源类型：1 分组，2 分类，3 自选
				goodsFrom = goodsFrom.toString();
				if (goodsFrom == "1") {
					let orderBy = "createTime";
					let groupIds = "";
					// 最新商品
					if (goodsData == "new") {
						orderBy = "createTime";
					} else if (goodsData == "hot") {
						orderBy = "sellCountTotal";
					} else if (goodsData == "dist") {} else {
						groupIds = goodsData;
					}
					this.$router.push({
						name: 'goodslist',
						params: {
							order_by: orderBy,
							groupId: groupIds
						}
					});
				} else if (goodsFrom == "2") {
					this.$router.push({
						name: 'goodslist',
						params: {
							category_id: goodsData
						},
					});
				} else if (goodsFrom == "3") {
					this.$router.push({
						name: 'goodslist',
						params: {
							goodsIds: goodsData
						},
					});
				} else {
					this.$router.push({
						name: 'goodslist',
						params: {},
					});
				}
			},

		},
	}
</script>

<style scoped="scoped">
	#home {
		background-repeat: no-repeat;
		background-position: top center;
		background-size: 100%;
	}

	.top-tabbar {
		line-height: 70px;
		padding: 0 10%;
		align-items: center;
		position: relative;
	}

	.home-container {
		min-height: calc(100vh - 200px);
	}

	.mall-logo {
		height: 50px;
		margin-right: 30px;
		transform: translateY(13px);
	}

	.cate-tabbar {
		position: relative;
		margin: 0 30px;
		align-items: center;
		height: 70px;
	}

	.cate-tabbar .el-icon-menu {
		font-size: 22px;
		margin-right: 5px;
	}

	.cate-tab {
		display: block;
		cursor: pointer;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		min-width: 120px;
	}

	.goods-cate {
		left: -6px;
	}

	.top-tabbar>div {
		margin: 0 30px;
		cursor: pointer;
	}

	.search-wrap {
		position: absolute;
		right: 0;
		height: 50px;
		display: flex;
		align-items: center;
		right: 10%;
		width: 350px;
	}

	.search-wrap .el-input-group__append {
		background-color: #f56c6b;
		border-color: #f56c6b;
		color: #fff;
		font-size: 20px;
	}

	.el-carousel__container {
		height: 300px;
		text-align: center;
	}

	.carousel-img-wrap {
		width: 100%;
		height: 100%;
		cursor: pointer;
		text-align: center;
		/*display: flex;
		align-items: center;
		justify-content: center; */
	}

	.carousel-img {
		max-width: 100%;
		max-height: 100%;
		cursor: pointer;
	}

	.carousel-img.single {
		display: block;
		margin: 0 auto;
	}

	.list-title-wrap {
		max-width: 1200px;
		text-align: center;
		position: relative;
		height: 50px;
		margin: 0 auto;
	}

	.goods-list-title {
		font-weight: bold;
		line-height: 50px;
		font-size: 25px;
		letter-spacing: 5px;
	}

	.more-btn {
		position: absolute;
		right: 10px;
		top: 4px;
		border: solid 1px #333;
		padding: 10px 30px;
		cursor: pointer;
	}

	.goods-list {
		flex-wrap: wrap;
		padding-bottom: 10px;
		max-width: 1200px;
		margin: 0 auto;
	}

	.goods-item {
		width: calc(20% - 20px);
		padding: 30px 0;
		margin: 10px;
		background-color: #FFFFFF;
		text-align: center;
		align-items: center;
		cursor: pointer;
		position: relative;
	}

	.activity-labele {
		background: linear-gradient(92deg, #ECD6AF 0%, #F6E5C6 100%);
	}

	.presale-style {
		color: #FFFFFF;
		padding: 2px 8px;
		border-radius: 4px;
		margin-right: 4px;
		font-size: 12px;
		vertical-align: text-top;
	}

	@media screen and (max-width: 1000px) {
		.top-tabbar {
			padding: 0;
		}

		.search-wrap {
			width: 300px;
			right: 0;
		}

		.goods-item {
			width: calc(33.33% - 20px);
			position: relative;
		}
	}

	.goods-img-wrap {
		max-width: 200px;
		height: 200px;
		display: flex;
		align-items: center;
	}

	.goods-img {
		max-width: 100%;
		max-height: 100%;
		margin: 0 auto;
	}

	.goods-name {
		margin: 10px 0;
		width: calc(100% - 40px);
		padding: 0 20px;
		line-height: 20px;
		text-align: left;
		height: 38px
	}

	.goods-price {
		height: 25px;
		font-size: 19px;
		color: red;
		font-weight: bold;
	}

	.footer-txt {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		color: #333;
		font-size: 13px;
	}

	.button-border {
		border-radius: 3px;
		color: #f63;
		font-size: 14px;
		border: 1px solid #f63;
		height: 20px !important;
		margin-left: 10px;
		word-break: break-all;
		overflow: hidden;
		text-align: center;
		margin-top: 2px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.goods-stock {
		color: #999;
		font-size: 14px;
		font-weight: normal;
		text-align: right;
		width: 90px;
		line-height: 22px;
		height: 22px;
		overflow: hidden;
		word-break: break-all;
		padding-right: 10px;
	}

	.goods-sub-title-home {
		color: #999;
		font-size: 14px;
		width: 230px !important;
		white-space: pre;
		text-overflow: ellipsis;
		overflow: hidden;
		line-height: 22px;
		height: 22px;
		padding: 0 20px;
		box-sizing: border-box;
		align-self: self-start;
		text-align: left;
	}

	.sold-out-img {
		width: 60px;
		height: 60px;
		position: absolute;
		top: 221px;
		right: 25px;
		z-index: 9;
	}

	.width-240 {
		width: calc(20% - 20px);
	}

	.goods-price-detail {
		color: #999;
		font-weight: normal;
		margin-left: 5px;
		font-size: 15px;
		text-decoration: line-through;
	}

	/* .matte {
		width: 100%;
		height: 32px;
		background-color: #000;
		position: absolute;
		bottom: 0vh;
		opacity: 0.5;
	} */


	/deep/ .el-carousel__indicator--horizontal button {
		width: 40px;
		height: 6px;
		background: #333333;
		border: 1px solid #FFFFFF;
		border-radius: 10px;
		opacity: 0.5;
	}

	/deep/ .el-carousel__indicator--horizontal.is-active button {
		width: 40px;
		height: 6px;
		border: 1px solid #333333;
		background: #ffffff;
		opacity: 0.5;
		border-radius: 10px;
	}

	.title-text {
		padding: 0 20px;
	}

	.category-title {
		text-align: center;
	}

	.category-content {
		/* width: 100%; */
		box-sizing: border-box;
		margin-bottom: 20px;
		margin-top: 20px;
		background-size: 100%;
		flex-wrap: wrap;
		background-size: 100%;
	}

	.category-content_item {
		cursor: pointer;
		box-sizing: border-box;
		flex-grow: 0;
		flex-shrink: 0;
		width: calc(16% - 16px);
		margin: 12px;
		padding-bottom: 20px;
	}

	.content-img {
		text-align: center;
		height: unset !important;
		/* width: 300px; */
	}

	.cate-img {
		width: 140px;
		height: 140px;
	}

	.category-text {
		margin-top: 10px;
		/* width: 300px; */
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		/*设置对其模式 */
		-webkit-line-clamp: 2;
	}

	.label-content {
		display: flex;
		flex-wrap: wrap;
		margin-top: 4px;
	}

	.button-border {
		border-radius: 3px;
		color: #f63;
		font-size: 14px;
		border: 1px solid #f63;
		height: 20px !important;
		margin-left: 10px;
		word-break: break-all;
		overflow: hidden;
		text-align: center;
		margin-top: 2px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.free-box-container {
		overflow: hidden;
		background-color: #fff;
		height: auto;
		text-align: center;
		background-size: 100% auto;
		background-repeat: repeat;
		max-width: 1200px;
		margin: 0 auto;
		padding: 10px 0;
	}

	.free-box-title {
		font-size: 25px;
		line-height: 80px;
		color: #333;
		letter-spacing: 2px;
		font-weight: bold;
	}

	.free-box-module-list {
		position: relative;
	}

	.free-box-module-item {
		position: absolute;
	}

	.free-box-el-carousel {
		width: 100%;
		height: 100%;
	}

	.hot-zone-img {
		object-fit: cover;
		width: 100%;
	}

	.hot-zone-box {
		width: 100%;
		height: 100%;
		position: relative;
		overflow: hidden;
	}

	.hot-zone-click {
		position: absolute;
		background: #none;
		z-index: 1;
	}

	.free-box-cate-list {
		width: 100%;
		height: 100%;
		overflow: auto;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		font-size: 15px;
		color: #333333;
		background: #fafafa;
		padding: 10px 0;
		box-sizing: border-box;
		border: dashed 1px #d6d6d6;
	}

	.free-box-cate-item {
		text-align: left;
		padding: 10px 12px 10px 24px;
	}

	.free-box-cate-item .cate-level-first {
		display: flex;
		flex-direction: row;
		cursor: pointer;
	}

	.free-box-cate-item .cate-level-first img {
		width: 44px;
		height: 44px;
		border-radius: 2px;
		vertical-align: top;
		margin-right: 12px;
	}

	.free-box-cate-item .cate-level-first span {
		display: inline-block;
		line-height: 44px;
		height: 44px;
		word-break: break-all;
		letter-spacing: 1px;
		font-weight: bold;
		color: #333;
		font-size: 16px;
		overflow: hidden;
	}

	.free-box-cate-item .cate-level-second {
		display: flex;
		flex-wrap: wrap;
		margin-top: 4px;
	}

	.free-box-cate-item .cate-level-second span {
		padding: 0 6px;
		font-size: 15px;
		word-break: break-all;
		cursor: pointer;
	}

	.free-box-sub-cate-list-box {
		position: absolute;
		top: 0;
		left: 100%;
		width: 250%;
		height: auto;
		min-height: 100%;
		text-align: left;
		border: dashed 1px #d6d6d6;
		padding: 24px 24px;
		overflow: auto;
		font-size: 15px;
		z-index: 99;
		background-color: #fafafa;
		max-width: 700px;
		min-width: 400px;
		box-sizing: border-box;
		border-radius: 0px 4px 4px 0px;
	}

	.sub-cate-list-item {
		line-height: 24px;
		padding-bottom: 15px;
	}

	.sub-cate-list-item:last-child {
		padding-bottom: 0;
	}

	.sub-cate-list-item span {
		padding: 0 7px;
		word-break: break-all;
		cursor: pointer;
	}

	.sub-cate-list-item-second {
		font-weight: bold;
	}

	.no-cate-pic .cate-level-first span {
		line-height: 30px !important;
		height: 30px !important;
	}

	#styleList ::-webkit-scrollbar {
		width: 6px !important;
		height: 6px !important;
	}

	.free-box-list-title span {
		height: 60px;
		line-height: 60px;
		font-weight: bold;
		font-size: 21px;
	}

	.free-box-more-btn {
		position: absolute;
		top: 12px;
		right: 15px;
		width: 72px;
		height: 34px;
		line-height: 36px;
		border: solid 1px #333;
		font-size: 15px;
		font-weight: normal;
		cursor: pointer;
		border-radius: 3px;
	}

	.free-box-goods-list {
		display: flex;
		height: calc(100% - 40px);
		overflow: hidden;
		padding: 0;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-content: flex-start;
	}

	.free-box-goods-item {
		display: flex;
		flex-direction: column;
		width: 170px;
		padding: 25px 22px;
		box-sizing: border-box;
		background: #ffffff;
		border-radius: 4px;
		margin: 12px 22px;
		font-size: 14px;
		cursor: pointer;
		position: relative;
		padding-bottom: 22px;
	}

	.free-box-goods-img {
		width: 124px;
		height: 124px;
		margin: 0 auto;
		border-radius: 3px;
	}

	.free-box-goods-name {
		margin-top: 14px;
		margin-bottom: 4px;
		text-align: left;
		line-height: 18px;
		height: 36px;
		overflow: hidden;
	}

	.free-box-goods-price {
		margin-top: 12px;
		line-height: 20px;
		height: 20px;
		overflow: hidden;
		color: red;
		font-weight: bold;
		font-size: 17px;
	}

	.free-box-module-item>div {
		background-size: 100% auto;
		background-repeat: repeat;
	}

	.free-box-goods-item .presale-style {
		border-radius: 3px !important;
	}

	.free-box-goods-item .goods-sub-title-home {
		width: calc(100% + 16px) !important;
		padding: 0 !important;
		height: 18px;
		line-height: 18px;
		margin-left: -8px;
		font-size: 13px;
	}

	.free-box-goods-item .sold-out-img {
		width: 56px !important;
		height: 56px !important;
		top: 94px !important;
		right: 25px !important;
	}

	.free-box-goods-item .button-border {
		font-size: 13px !important;
		height: 18px !important;
		margin: 0 2px 3px 2px !important;
		line-height: 20px !important;
	}

	.free-box-goods-item .label-content {
		width: calc(100% + 16px) !important;
		margin-left: -8px;
	}

	.has-goods-title .free-box-goods-item {
		margin: 0 22px 22px 22px !important;
	}

	.free-box-module-goods {
		padding-top: 48px;
	}

	.has-goods-title {
		padding-top: 0px !important;
	}

	.richtext-box {
		min-height: 40px;
		max-width: 1200px;
		margin: 10px auto;
		line-height: 40px;
	}
	
	.modular{
		background-repeat: no-repeat !important;
		background-position: top center !important;
		background-size: 1200px !important;
	}
	 
</style>